/* You caf add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700&display=swap");
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@keyframes slideMe {
  0% {
    transform: translateX(100%);
  }

  25% {
    transform: translateX(75%);
  }

  50% {
    transform: translateX(50%);
  }

  75% {
    transform: translateX(25%);
  }

  100% {
    transform: translateX(0%);
  }
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.max-fit-width {
  max-width: fit-content !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.roboto {
  font-family: "Roboto" !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.mb-17 {
  margin-bottom: 17px;
}

.clear-both {
  clear: both !important;
}

.mb-32 {
  margin-bottom: 32px;
}

.mtop-5 {
  margin-top: 5px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.px-45 {
  padding: 0 45px;
}

.px-5 {
  padding: 0 8px;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.px-20 {
  padding: 0 20px !important;
}

.lh-17 {
  line-height: 17px;
}

.lh-30 {
  line-height: 30px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-8{
  margin-right: 8px;
}

.border-none {
  border: none !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.mt-1 {
  margin-top: 10px !important;
}


.mt-16 {
  margin-top: 16px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.px-48 {
  padding: 0 48px;
}

.order-info {
  margin: 7px 0 34px 0;
  line-height: 17px;
  letter-spacing: 0.01rem;
}

.fw-400 {
  font-weight: 400;
}

.cart-animation {
  animation: slideMe .4s linear !important;
}

.nunito {
  font-family: "Nunito", sans-serif;
}

.hover-background-unset {
  &:hover {
    background-color: unset !important;
  }
}

.inter {
  font-family: "Inter";
}

.login-label-mobile {
  margin-bottom: 12px;
}

.w-fit-content {
  min-width: fit-content !important;
}

.w-auto {
  width: auto !important;
}

.h-auto {
  height: auto !important;
}


.h-fit-content {
  height: fit-content !important;
}

.h-auto {
  height: auto !important;
}


.pointer {
  cursor: pointer !important;
}

.flex-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: end !important;
}

.mtb-10 {
  margin: 10px 0 10px 0 !important;
}


.overflow-auto {
  overflow: auto !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: end !important;
}

.display-none {
  display: none !important;
}

.bg-none {
  background-color: none !important;
}

.bg-inherit {
  background: inherit !important;
}

.bg-blue {
  background-color: #001842 !important;
}

.white-color {
  color: white !important;
}

.v-top {
  vertical-align: top !important;
}

.w-65 {
  width: 65% !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-12 {
  margin-left: 12px;
}

.magin-auto {
  margin: auto !important;
}

.ml-11 {
  margin-left: 11px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}


.full-width {
  width: 100% !important;
}

.h-100 {
  height: 100%;
}

.align-self-center {
  align-self: center !important;
}

.align-item-flexEnd {
  align-items: flex-end !important;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #878383;
    width: 2px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c3bfbf;
    border-radius: 10px;
  }
}

.align-item-end {
  align-items: end !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.width100 {
  width: 100% !important;
}

.min-width-content {
  width: min-content !important;
}

.my-10 {
  margin: 10px 0;
}

.pointer {
  cursor: pointer !important;
}

.ml-5 {
  margin-left: 5px;
}

.border-grey {
  border: 1px solid #ececec !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.flex-end {
  justify-content: flex-end;
}

.pl-0 {
  padding-left: 0 !important;
}

.h-0 {
  height: 0px !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.v-middle {
  vertical-align: middle !important;
}

.min-width-120 {
  min-width: 120px;
}

.min-width-150 {
  min-width: 150px;
}


.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-none {
  border: none !important;
}

.align-item-baseline {
  align-items: baseline !important;
}

.d-flex {
  display: flex !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.d-none {
  display: none !important;
}

.gap-4 {
  gap: 4px;
}

.text-underline {
  text-decoration: underline !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}


.dropdown {

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-infix {
    padding: 0;
    border-radius: 4px;
    border: 1px solid #DDD;
    background: #fff;

    .mat-form-field-label-wrapper {
      left: 7px;
      top: -8px;
    }

    .mat-select-trigger,
    .mat-input-element {
      padding: 10px;
    }

    .mat-input-element {
      height: 36px !important;
    }
  }
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.d-block {
  display: block !important;
}

.max-width {
  max-width: 100%;
}

.word-wrap {
  word-wrap: break-word !important;
}

.gray-dark {
  color: #555555;
}

.eye-icon {
  color: #9E9E9E;
}

.gray-light {
  color: #888 !important;
}

.yellow-color {
  color: #e6a934 !important;
}

.login-btn-bg-color {
  background: #fcf9ee !important;
}

.red-color {
  color: red !important;
}

.display-block {
  display: block;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px !important;
}

.font-28 {
  font-size: 28px;
}

.fw-500 {
  font-weight: 500 !important;
}

.black {
  color: #000000 !important;
}

.payment-background {
  background: #FBF8E5 !important;
}

.tip-text {
  color: #018BED;
}

.white {
  color: #ffff;
}

.cursor-pointer {
  cursor: pointer;
}



.mr-b {
  margin-bottom: 4px;
}

// font weight

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500 !important;
}

.medium-600 {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700;
}

.font-bold-large {
  font-weight: 800;
}

.grey-dark {
  color: #535148;
}

.grey-darker {
  color: #515151;
}

.grey-color {
  color: #707070;
}

.grey-light {
  color: #888888;
}

.white-col {
  color: #ffff;
}

.grey-300 {
  color: #d0d0d0;
}

.grey-darker-shade {
  color: #6b6b6b;
}

.green-color {
  color: $green-dark;
}

.inline-block {
  display: inline-block !important;
}

.black-color {
  color: #000000;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
  // width: 100%; //remove
}

.justify-end {
  justify-content: end;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.text-underline {
  text-decoration: underline;
}

.w-40 {
  width: 40px;
}

body {
  padding-bottom: 0 !important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

img {
  max-width: 100%;
}

img {
  max-height: 100%;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

a {
  &:hover {
    text-decoration: none;
  }
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

// Custom styles
html,
body {
  font-size: 14px;
  font-family: "Inter UI", sans-serif;
  background: #fcfcfc;
}

.mb0 {
  margin-bottom: 0 !important;
}

// header styles
.app_header {
  background: #fff;
  @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.075));

  .main_menu {
    border-left: 1px solid $gray-200;
    padding-left: 1rem;

    ul {
      display: flex;

      li {
        line-height: 5rem;
        margin: 0 0.8rem;

        a {
          padding: 2rem 0;
          font-weight: 500;
          text-decoration: none;
          color: $gray-700;
        }

        &.active {
          border-bottom: 1px solid $blue;

          a {
            color: $gray-800;
          }
        }
      }
    }
  }

  .user_profile_container {
    a.profile_btn {
      padding: 2rem 1.2rem;
      cursor: pointer;
      font-weight: 500;
      border-left: 1px solid $gray-200;

      svg {
        margin-left: 1rem;
      }
    }
  }

  .search_container {
    padding: 2rem 1.2rem;
    border-left: 1px solid $gray-200;
    max-width: 200px;

    .mat-form-field-wrapper {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-infix {
        margin: -30px 0 0 30px;
        padding-bottom: 0;
      }
    }
  }

  .rv_calculator_btn {
    padding: 1.2rem;
  }
}

// Buttons styles
.btn-outline-primary {
  &:hover {
    svg {
      path {
        fill: #fff !important;
      }
    }
  }
}

// RV Calculator popup styles
// Profile dropdown style
.mat-menu-panel {
  border-radius: 0 !important;
  max-width: inherit !important;
  @include box-shadow(0px 3px 8px 0px rgba(0, 0, 0, 0.12));

  .mat-menu-content {
    padding: 0 !important;

    .profile_item {
      padding: 2rem !important;
      height: auto !important;
      border-bottom: 1px solid $gray-200;
      font-size: 1rem;
      cursor: pointer;

      .mat-list-item-content {
        padding: 0 !important;

        .user_thumb {
          margin-right: 2rem;

          .thumb_box {
            background: $blue;
            border: 3px solid #fff;
            @include border-radius(4px);
            @include box-shadow(0px 3px 8px 0px rgba(0, 0, 0, 0.12));
          }
        }

        .user_name {
          font-weight: 600;
          font-size: 1.1rem;
          color: $gray-800;
          margin-bottom: 0.5rem;
        }

        .user_email {
          color: $gray-600;
          margin-bottom: 1.2rem;
        }

        strong {
          font-weight: 500;
          display: block;
          margin-bottom: 0.4rem;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      .list_icon {
        margin-right: 1.5rem;
        min-width: 40px;
        text-align: center;
      }
    }
  }
}

// Internal pages styles
// .main_header {
//   box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
//   background: white;
//   float: left;
//   width: 100%;
//   padding: 0 16px;
//   position: fixed;
//   top: 0;
//   z-index: 20;
//   padding: 10px 0px;
//   z-index: 99;

//   .logo {
//     float: left;
//     width: 100%;
//     justify-content: space-between;

//     @media screen and (max-width: 1110px) {
//       float: left;
//       padding-top: 13px;
//       max-width: 116px;
//     }

//     @media screen and (max-width: 600px) {
//       max-width: 100px;
//     }

//     img {
//       max-width: 100%;
//       display: block;
//       height: 20px;
//     }
//   }

// //   .custom_nav {
// //     nav {
// //       background: transparent;
// //       box-shadow: none;
// //       height: auto;
// //       line-height: inherit;

// //       .nav-wrapper {
// //         padding: 10px 0;

// //         a.btn {
// //           margin-right: 0;
// //           font-size: 13px;
// //           padding: 12px 20px;
// //           line-height: 22px;
// //         }

// //         .signup_btn {
// //           border: 0px;
// //           border-radius: 2px;
// //           padding: 15px 15px;
// //           color: white;
// //           background: #2dade4 !important;
// //           text-transform: uppercase;
// //           font-weight: 400;
// //           font-size: 14px;
// //           letter-spacing: 1px;

// //           &:hover {
// //             text-decoration: none;
// //           }
// //         }

// //         .search_btn {
// //           cursor: pointer;
// //           margin: 0px 20px;
// //           position: relative;

// //           i {
// //             font-size: 22px;
// //           }

// //           .search {
// //             position: absolute;
// //             right: 0px;
// //             width: 25px;
// //             margin: auto;
// //             height: 44px;
// //             z-index: 1;
// //             cursor: pointer;
// //             padding: 3px 10px;
// //             top: 0px;
// //             bottom: 0px;
// //             border: 1px solid #ddd;
// //             border-radius: 3px;
// //             opacity: 0;
// //             transition: all 0.3s ease;

// //             &:focus {
// //               width: 250px;
// //               opacity: 1;
// //               min-width: 250px;
// //               background: $white;
// //               text-align: left;
// //             }
// //           }
// //         }

// //         ul {
// //           li {
// //             float: left;
// //             border-bottom: 3px solid rgba(7, 163, 249, 0) !important;

// //             &.menu-select-item-color {
// //               background-color: rgba(7, 163, 249, 0.1) !important;
// //               border-bottom: 3px solid rgba(7, 163, 249, 1) !important;
// //             }

// //             a {
// //               color: $menu-color;
// //               font-size: 15px !important;
// //               padding: 0 16px;
// //               text-transform: uppercase;
// //               font-weight: 300;
// //               letter-spacing: 1px;
// //               display: flex;
// //               align-items: center;

// //               i {
// //                 opacity: 0.7;
// //                 font-size: 20px;
// //                 margin: 0px 0px 0px 5px;
// //                 height: 14px;
// //                 line-height: 14px;
// //               }

// //               &:hover {
// //                 background: transparent;
// //                 text-decoration: none;
// //                 color: $blue-secondary;
// //               }
// //             }

// //             &.acitve {
// //               a {
// //                 color: $blue-secondary;
// //               }
// //             }
// //           }
// //         }
// //       }
// //     }
// //   }
// }

// .responsive_menu {
//   display: none;
// }

// .menu-dropdown {
//   min-width: 199px !important;
//   top: 20px;
//   position: relative;
// }

.form-group {
  position: relative;
  margin: 0px 0px 20px 0px;
  display: block;
  width: 100%;

  .mat-form-field {
    width: 100%;
  }
}

.mat-form-field {
  &.select-bordered {
    min-width: auto;

    &.mat-form-field-should-float {
      .mat-form-field-label {
        display: none !important;
      }
    }

    .mat-select {
      height: 56px;
      border: 2px solid $gray-400;
      border-radius: 4px;
      position: relative;
      top: 5px;
    }

    .mat-select-placeholder {
      line-height: 52px;
      padding: 0px 10px;
      font-size: 16px;
      font-weight: 500;
      opacity: 0.9;
    }

    .mat-form-field-label {
      line-height: 50px;
      padding: 0px 15px;
      font-size: 16px;
      font-weight: 500;
      opacity: 1;
      color: $gray-900;
    }

    .mat-select-value-text {
      line-height: 52px;
      padding: 0px 10px;
      font-size: 16px;
      font-weight: 500;
    }

    .mat-form-field-wrapper {
      padding: 0;

      .mat-form-field-infix {
        width: auto;
        padding: 0;
        border: 0;
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-input-element {
        border: 1px solid $gray-900 !important;
        padding: 0.8rem;

        &:hover {
          border-color: $gray-500;
        }

        &:focus {
          border-color: $blue;
        }
      }
    }

    .mat-select-arrow {
      color: $gray-900;
      margin: 0 10px;
    }
  }
}

.input-bordered.mat-form-field-appearance-outline .mat-form-field-outline-end,
.input-bordered.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-width: 2px !important;
  border-color: $gray-400 !important;
}

body .input-bordered.mat-form-field-appearance-outline .mat-form-field-outline:focus .mat-form-field-outline-end,
.input-bordered.mat-form-field-appearance-outline .mat-form-field-outline:focus .mat-form-field-outline-start {
  border-color: $blue !important;
}

.main-admin-inner {
  padding: 15px 20px;
  margin-top: 60px;
}

.main-admin-heading {
  .page-title {
    font-size: 36px;
    color: $gray-800;
  }
}

.admin-top-tab-link {
  display: block;
  margin: 15px 0px;
  min-height: 36px;

  ul {
    margin: 0px 0px;
    padding: 0px;
    list-style: none;

    li {
      display: inline-block;
      margin: 0px 5px 0px 0px;

      a {
        padding: 10px 20px;
        border-radius: 35px;
        color: $gray-600;

        &.active {
          background: $blue;
          color: $white;
        }

        &.instant-valuation-link.active {
          background: $yellow-dark;
        }
      }
    }
  }
}

.common-inner-box {
  position: relative;
  background: $white;
  padding: 30px;
  width: 100%;
  margin: 0px auto 30px auto;
  border-radius: 2px;
  box-shadow: 0px 3px 4px rgba(47, 53, 158, 0.18);
  display: block;
}

.admin_sub_heading {
  font-size: 20px;
  color: $gray-800;
  margin: 0px 0px 15px 0px;
  font-weight: 700;
}

.table-inner-box {
  margin: 0px 0px 30px 0px;

  .mat-elevation-z8 {
    // box-shadow: 0px 3px 4px rgba(47, 53, 158, 0.18);
    box-shadow: none;

    .mat-table {
      background: none;

      .mat-header-row {
        box-shadow: none;
        border-bottom: 0px;
      }

      .mat-header-cell {
        background: none;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
        color: $gray-500;
      }

      .mat-cell {
        background: $white;
        min-height: 60px;
        font-size: 14px;
        color: $gray-800;
      }

      .mat-row {
        box-shadow: 0px 3px 4px rgba(47, 53, 158, 0.18);
        border-bottom-color: #e8e8f1;

        &:hover .mat-cell {
          background: $gray-100;
        }
      }

      .username {
        color: #2ca9e1;
        font-weight: 500;
      }
    }
  }
}

.usage-instant-graph {
  .top-graph-area {
    h2 {
      color: $yellow-dark;
    }
  }
}

.usage-instant-list {
  .username {
    color: $yellow-dark !important;
  }
}

.user_list_table {
  width: 100%;
  display: block;
  position: relative;

  @media screen and (max-width: 991px) {
    overflow: auto;
  }

  .mat-table {
    box-shadow: none;
    width: 100%;
    background: none;
    border-spacing: 0px 6px;

    .mat-row {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
      margin: 0px 0px 10px 0px;
      border-bottom: 0px solid #fafafc;
      overflow: hidden;
      display: table;
      width: 100%;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;

      &:hover {
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      }

      td {
        border: 0px;
      }
    }

    td {
      vertical-align: middle;
      background: $white;
    }



    .u_action_td {
      max-width: 280px;
      min-width: 280px;
      width: 280px;
    }

    .u_product_td {
      max-width: 300px;
      min-width: 300px;
      width: 300px;
    }
  }
}

.u_list_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 0;

  .u_list_info_pic {
    width: 45px;
    height: 45px;
    text-align: left;
    overflow: hidden;
    border-radius: 2px;
  }

  .c_list_info_pic {
    width: 45px;
    height: 45px;
    text-align: left;
    overflow: hidden;
    border-radius: 2px;
    border-radius: 100%;
  }

  .u_list_info_info {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0px;
    padding-right: 1rem;
    padding-left: 0.7rem;

    .u_list_info_title {
      text-decoration: none;
      font-weight: 600;
      font-size: 16px;
      color: $table-td-color;
      padding-right: 1rem;
      margin-bottom: 0.3rem;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      display: inline-block;
      white-space: nowrap;
    }

    .u_list_info_desc {
      font-weight: 400;
      font-size: 13px;
      color: $table-td-color;
    }
  }

  .c_list_info_desc {
    font-weight: 400;
    font-size: 13px;
    color: #717187;
    display: block;
    margin: 0px 0px 0px;

    span {
      color: $table-td-color;
      margin: 5px 15px 5px 0px;
      display: inline-block;
    }
  }
}

.top_page_right {
  .invite-btn {
    height: 46px;
    line-height: 46px;
    color: $white;
    font-size: 14x;
    font-weight: 400;

    i {
      font-size: 20px;
      margin: 0px 4px 0px 0px;
    }

    .mat-button-wrapper {
      display: flex;
      align-items: center;
    }
  }
}

.u_action_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;

  .u_t_btn {
    margin: 0px 0px 0px 10px;
    border: 1px solid $blue;
    color: $blue;
    width: 100px;
    min-width: 100px;
    padding: 0px;
    text-align: center;
    height: 38px;
    line-height: 36px;
    border-radius: 0px;
    font-size: 13px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 400;
  }

  .activate_btn {
    border: 1px solid $teal;
    color: $teal;
    border-radius: 40px;
    font-size: 13px;
    font-weight: 400;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    min-width: 100px;
    height: 38px;
    line-height: 36px;
    position: relative;

    .mat-button-focus-overlay {
      opacity: 0 !important;
    }

    .mat-button-ripple {
      opacity: 0 !important;
    }

    &:hover {
      background: $white;
    }

    &.activated {
      border-color: transparent;

      &:before {
        content: "check";
        font-family: "Material Icons";
        font-size: 18px;
        position: absolute;
        left: 0;
        top: 0;
        color: $teal;
      }
    }
  }
}

.u_product_show {
  display: inline-flex;
  justify-content: start;
  align-content: center;

  label {
    font-size: 13px;
    font-weight: 400;
    color: $gray-500;
    margin: 0px 10px 0px 0px;
    display: flex;
    align-items: center;
  }

  .u_product_name {
    display: flex;

    .u_product_btn {
      margin: 0px 10px 0px 0px;
      border: 0;
      color: $table-td-color;
      width: auto;
      padding: 0px 10px;
      text-align: center;
      height: 35px;
      line-height: 34px;
      border-radius: 3px;
      font-size: 13px;
      font-weight: 500;
      background: #ebebf2;
      display: inline-block;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
}

.modal_area {
  width: 100%;
  background: #fff;
  border-radius: 0px !important;
  box-shadow: 0 0px 80px rgba(0, 0, 0, 0.08);
  padding: 0px;
  position: relative;

  .close-btn {
    border: 0px;
    background: none;
    position: absolute;
    top: 9px;
    left: 12px;
    cursor: pointer;
    opacity: 0.3;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    i {
      font-size: 28px;
      opacity: 0.4;
    }

    &:hover {
      opacity: 1;
    }
  }

  .popup-title {
    font-size: 16px;
    margin: 0px;
    padding: 16px 30px 16px 60px;
    border-bottom: 1px solid #eee;
    align-items: center;
    display: flex;

    &.active {
      color: $blue;

      span {
        color: $gray-700;
      }
    }

    span {
      color: $gray-500;
      display: inline-flex;
      align-items: center;

      i {
        color: $gray-500;
        margin: 0px 5px;
        height: 16px;
        line-height: 16px;
      }
    }
  }

  .modal_inner {
    padding: 30px 30px 10px 30px;
    display: block;
    position: relative;
  }

  .popup-footer-btn {
    margin: 0px;
    padding: 20px 30px;
    border-top: 1px solid #eee;
  }

  .submit-btn {
    border-radius: 0px;
    font-size: 14px;
    height: 45px;
    padding: 0px 30px;
    font-weight: 400;
  }

  .cancel-btn {
    border: 1px solid $blue;
    color: $blue;
    margin: 0px 10px;
    border-radius: 0px;
    height: 45px;
    font-weight: 400;
    font-size: 14px;
    padding: 0px 30px;
  }

  h4 {
    font-size: 16px;
    margin: 0px 0px 15px 0px;
    color: $popup-text-color;
    font-weight: 500;
  }
}

.invite-user-modal {
  .mat-dialog-container {
    background: none !important;
    box-shadow: none;
  }

  .mat-form-field-flex {
    height: 50px;
    padding-left: 15px;

    .mat-form-field-prefix {
      position: relative;
      left: -14px;
    }
  }

  .dt-custome-check {
    .mat-checkbox {
      margin: 0px 0px 15px 0px;
    }
  }
}

.dt-custome-check {
  .mat-checkbox {
    border: 1px solid #d8d8e6;
    padding: 13px 15px;
    border-radius: 40px;
    display: inline-block;
    margin: 0px 15px 15px 0px;
    width: 100%;

    &.mat-checkbox-checked {
      background: $blue;
      border: 1px solid $blue;

      .mat-checkbox-label {
        color: $white;
      }

      .mat-checkbox-background {
        opacity: 1;
      }
    }

    .mat-checkbox-ripple {
      height: 45px;
      width: 45px;
      top: 0px;
      bottom: 0px;
      margin: auto;
      left: -10px;
    }

    .mat-checkbox-frame {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      border: 1px solid #cecee0;
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
    }

    .mat-checkbox-background {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      border: 1px solid $white;
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
      background: $white;
      opacity: 0;

      svg {
        .mat-checkbox-checkmark-path {
          stroke: $blue !important;
        }
      }
    }
  }

  .mat-checkbox-label {
    font-size: 14px;
    margin: 0px 0px 0px 10px;
    color: $popup-text-color;
    font-weight: 600;
  }
}

.menu-toggle {
  display: none;
  margin: 0px 15px 0px 0px;
  color: $gray-700;
  cursor: pointer;
  border: 0px;
  background: none;

  i {
    font-size: 26px;
  }
}

.header-drawer-backdrop {
  display: none;
}

.profile-menu>a {
  white-space: nowrap;
}

.list_cu_product {
  border: 1px solid $blue;
  padding: 10px 10px 10px 40px;
  position: relative;
  display: inline-block;
  margin: 10px 10px 10px 0px;

  &:before {
    content: "check_circle";
    font-family: "Material Icons";
    font-size: 22px;
    position: absolute;
    left: 10px;
    top: 7px;
    color: $blue;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    color: $table-td-color;
    display: block;
    margin: 0px 0px 14px 0px;
  }

  .cu_info {
    font-size: 13px;
    color: #717187;
    white-space: nowrap;

    strong {
      font-size: 14px;
      font-weight: 600;
      color: $table-td-color;
      margin: 0px 5px 0px 15px;

      &:first-child {
        margin: 0px 5px 0px 0px;
      }
    }
  }
}

.add-company-modal {
  .mat-dialog-container {
    background: $white;

    .company_logo {
      display: flex;
      align-items: center;
      margin: 0px 0px 40px 0px;

      .logo_area {
        display: flex;
        flex-direction: column;
        margin: 0px 15px 0px 0px;
        position: relative;

        .logo-img {
          width: 76px;
          height: 76px;
          overflow: hidden;
          border-radius: 100%;
        }

        span {
          font-size: 12px;
          position: absolute;
          bottom: -20px;
          left: 0px;
          right: 0px;
          margin: auto;
          width: 100%;
          text-align: center;
        }

        input[type="file"] {
          position: absolute;
          bottom: -20px;
          height: 12px;
          width: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }

      .company_name_area {
        width: 100%;

        input {
          border: 1px solid #cecee0;
          font-size: 18px;
          border-radius: 3px;
          width: 100%;
          height: 55px;
          line-height: 50px;
          padding: 0px 10px;
        }
      }
    }
  }

  .modal_area .popup-footer-btn {
    justify-content: center;

    .submit-btn {
      font-size: 16px;
      height: 55px;

      .arrow-right {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        margin: 0px 0px 0px 7px;
      }
    }
  }
}

.rounded-input {
  .mat-form-field {
    height: 44px;
    padding: 0px 10px;
    border: 1px solid #d0d1dd;
    border-radius: 4px;
    background: $white;

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-subscript-wrapper {
      display: none;
    }

    .mat-form-field-label-wrapper {
      top: -18px;
    }

    .mat-form-field-label {
      background: $white;
      padding: 3px 5px;
      width: auto !important;
    }

    input.mat-input-element {
      top: -3px;
      position: relative;
    }
  }
}

.select-product-cs {
  h4 {
    margin-bottom: 30px;
  }

  .mat-accordion {
    .mat-expansion-panel-header {
      position: relative;
      height: 58px !important;

      &.mat-expanded {
        &:before {
          border: 1px solid $blue;
          background: $blue;
          content: "check";
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        border: 1px solid #dadae7;
        background: $white;
        left: -15px;
        top: 15px;
        font-family: "Material Icons";
        font-size: 20px;
        text-align: center;
        line-height: 24px;
        color: $white;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
      }
    }

    .mat-expansion-panel-header-title {
      font-weight: 600;
    }

    .mat-expansion-panel {
      background: $white;
      border: 1px solid #dadae7;
      box-shadow: none;
      border-radius: 4px !important;
      padding-left: 30px;
      margin: 0px 0px 15px 0px;

      &.mat-expanded {
        background: #fafafc;
        border: 1px solid $blue;
      }
    }
  }

  .form-group {
    label {
      font-size: 13px;
      margin: 0px 0px 5px 0px;
    }
  }

  .done-btn {
    width: 100%;
    height: 44px;
    border-radius: 0px;
    position: relative;
    top: 18px;
  }
}

// Global Listing Loader
.CenterGlobalLoader {
  background: rgba(0, 0, 0, 0.474) !important;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  vertical-align: middle;
  z-index: 9999999;

  &.innerPageLoader {
    position: absolute;
    background: rgba(255, 255, 255, 0.6);

    &.ModuleTabingLoader {
      .GlobalPageLoader {
        top: calc(50vh - 172px);
      }
    }
  }

  .GlobalPageLoader {
    top: 45%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    margin: 0 auto;
    z-index: 9999999999999;
    position: absolute;

    div {
      position: absolute;
      border: 2px solid $white;
      border-radius: 100%;
      opacity: 1;
      @include border-radius(50%);
      animation: GlobalPageLoader 1.3s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
}

@keyframes GlobalPageLoader {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

// home-page-style
.secondary-btn {
  padding: 16px 40px;
  align-items: center;
  background: #e3e3e3 !important;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  border-radius: 3px !important;
  border: none !important;
  cursor: pointer;
}

.primary-btn {
  padding: 15px 57px;
  align-items: center;
  background: #f9b420 !important;
  letter-spacing: 0;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px !important;
  cursor: pointer;
}

.section-header {
  h2 {
    font-size: 34px;
    margin-bottom: 10px;
    font-weight: 900;
    float: left;
  }

  button {
    float: right;
    margin-right: 0;
  }
}

.wrapper-list-thumb-manage {
  &.wrapper-list-thumb {
    background: #fff;
    box-shadow: 1px 1.732px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    // overflow: hidden;
    // margin-bottom: 30px;
    height: 100%;
    position: relative;

    .list-thumb-content {
      padding: 15px 18px 15px;

      // min-height: 108px;
      p {
        line-height: 1.5;
        font-size: 13px;

        &.title-info {
          line-height: 22px;
        }
      }

      .secondary-text {
        p {
          font-size: 14px;
          margin-bottom: 4px;
          word-break: break-all;
        }
      }
    }
  }
}

.content-section-outer {
  padding: 60px 0;

  .content-wrapper {
    max-width: 1400px;
    margin: 0 auto;

    .section-header {
      margin-bottom: 20px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .main-content-wrapper {
      .gallery-item-list {
        @media only screen and (max-width: 991px) {
          margin-bottom: 20px;
        }
      }

      .wrapper-list-thumb {
        background: #fff;
        box-shadow: 1px 1.732px 4px 0px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        overflow: hidden;
        // margin-bottom: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .list-thumb-content {
          padding: 15px 18px 15px;

          // min-height: 108px;
          .depart-info {
            p {
              font-size: 16px;
              // text-transform: capitalize;
              line-height: normal;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          p {
            line-height: 1.5;
            font-size: 13px;

            &.type-info {
              font-size: 12px;
              color: #888;
              margin-bottom: 5px;
              vertical-align: middle;
            }

            &.title-info {
              font-size: 16px;
              font-weight: 500;
              color: #000;
              line-height: 22px;
              margin: 5px 0 5px;
              word-break: break-word;
            }

            &.list-desc {
              font-size: 13px;
              color: #333;
              margin: 5px 0 0;
              line-height: 18px;
            }

            .booking-service-text {
              width: 65%;
              font-size: 16px;
              font-weight: 700;
              color: #000000;
              font-family: "Nunito", sans-serif;
            }

            .paid-service-tag {
              background: #FFF8ED;
              border-radius: 20px;
              padding: 2px 26px;
              color: #A66609;
              border: none;
              outline: none;
              font-weight: 700;
              font-size: 12px;
              text-transform: uppercase;
            }

            .unpaid-service-tag {
              background: #F0FFE5;
              border-radius: 20px;
              padding: 2px 26px;
              color: #0A8E2F;
              border: none;
              outline: none;
              font-weight: 700;
              font-size: 12px;
              text-transform: uppercase;
            }


          }

          .secondary-text {
            p {
              font-size: 14px;
              margin-bottom: 4px;
              word-break: break-all;
            }
          }
        }
      }
    }
  }

  &.inner-page-heading {
    // margin-top: 84px;
    // background-image: -moz-linear-gradient(-33deg, rgb(7, 163, 249) 0%, rgb(71, 67, 196) 50%, rgb(87, 44, 183) 69%, rgb(102, 21, 170) 100%);
    // background-image: -webkit-linear-gradient(-33deg, rgb(7, 163, 249) 0%, rgb(71, 67, 196) 50%, rgb(87, 44, 183) 69%, rgb(102, 21, 170) 100%);
    // background-image: -ms-linear-gradient(-33deg, rgb(7, 163, 249) 0%, rgb(71, 67, 196) 50%, rgb(87, 44, 183) 69%, rgb(102, 21, 170) 100%);
    // background-image: linear-gradient(-33deg, rgb(7, 163, 249) 0%, rgb(71, 67, 196) 50%, rgb(87, 44, 183) 69%, rgb(102, 21, 170) 100%);
    background: url(../../assets/images/sm-banner-image_large_icons_grey.jpg) no-repeat center center; // done after the angular 10 update

    h2 {
      font-size: 36px;
      font-weight: 700;
      color: rgb(190, 190, 190);
      margin-bottom: 6px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: rgb(170, 170, 170);
    }

    .page-location {
      font-size: 13px;
      color: rgb(170, 170, 170);
      margin-top: 0px;
      text-transform: uppercase;

      i {
        position: relative;
        top: 7px;
        margin: 0 10px;
      }

      a {
        color: rgb(170, 170, 170);
      }
    }
  }
}

.list-thumb-content {
  p {
    &.type-info {
      font-size: 12px;
      color: #888;
      margin-bottom: 4px;
      vertical-align: middle;
    }

    &.title-info {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      line-height: 22px;
      margin: 5px 0 5px;
      max-height: 100%;
    }

    &.list-desc {
      font-size: 13px;
      color: #333;
      margin: 5px 0 0;
      line-height: 18px;
    }
  }

  .secondary-text {
    p {
      font-size: 14px;
      margin-bottom: 4px;
      word-break: break-all;
    }
  }
}

.main-content-area {
  .content-section-outer {
    .content-wrapper {
      .main-content-wrapper {
        .wrapper-list-thumb {
          .list-thumb-content {
            min-height: 90px;
            max-height: inherit;
            padding: 19px 20px 7px !important;
          }
        }
      }
    }
  }
}

// Profile dropdown style
.mat-menu-panel {
  border-radius: 0 !important;
  min-height: fit-content !important;
  max-width: inherit !important;
  @include box-shadow(0px 2px 49.5px 0.5px rgba(0, 0, 0, 0.28));

  .mat-menu-content {
    padding: 0 !important;
  }

  &.profile-menu {
    min-width: inherit !important;
    width: 263px;
    padding: 30px 30px 20px;
    margin-top: 15px !important;
    position: relative;
    overflow: visible;
    box-shadow: 0px 2px 49.5px 0.5px rgba(0, 0, 0, 0.28);

    &:after {
      width: 15px;
      height: 15px;
      content: "";
      background: #fff;
      position: absolute;
      top: -6px;
      right: 35px;
      transform: rotate(45deg);
    }

    .user-profile-info {
      text-align: center;

      .user-pic {
        width: 94px;
        height: 94px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        margin-bottom: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      p {
        font-size: 16px;
        color: #000;
        margin-bottom: 5px;

        a {
          color: #888888;
          font-size: 13px;
        }
      }
    }

    .profile-action {
      margin-top: 25px;
      padding-top: 25px;
      border-top: 1px solid #ddd;
      overflow: hidden;

      li {
        float: left;
        width: 100%;

        a {
          font-size: 14px;
          color: #49515f;
          float: left;
          width: 100%;
          line-height: 45px;

          i {
            float: left;
            line-height: 45px;
            font-size: 20px;
            margin-right: 20px;
          }
        }
      }
    }
  }

  &.notification-drop {
    margin-top: 3px !important;
    width: 350px;

    &:after {
      right: 19px;
    }

    .profile-action {
      margin: 0;
      padding: 0;
      border: 0;

      li {
        float: left;
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #f1f1f1;

        p {
          font-size: 14px;

          strong {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
            float: left;
            width: 100%;
          }
        }

        &:last-child {
          border-bottom: 0;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

// UI component style start from here
// heading
h1 {
  font-size: 26px;
  font-weight: 600;
  color: $black;
}

h2 {
  font-size: 22px;
  font-weight: 600;
  color: $black;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: $black;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  color: $black;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 10px;
}

// content wrapper style
.block-container {
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.18);
  padding: 30px;
  margin-bottom: 30px;
  width: 100%;

  .toggle-label {
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
  }

  &.half-left {
    float: left;
    width: 49%;
  }

  &.half-right {
    float: right;
    width: 49%;
  }

  hr {
    margin: 20px 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .block-title {
    margin-bottom: 30px;
    font-size: 17px;

    span {
      float: left;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 29px;
      color: #888;
    }

    &.infront-title {
      span {
        float: none;
        width: auto;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .form-wrapper {
    .mat-form-field {
      display: inline-block;
    }

    // slide toggle custom style
  }
}

.mat-slide-toggle {
  &.custom {
    .mat-slide-toggle-bar {
      width: 51px;
      height: 34px;
      border-radius: 40px;

      .mat-slide-toggle-thumb-container {
        width: 30px;
        height: 30px;
        left: 2px;
        top: 2px;

        .mat-slide-toggle-thumb {
          height: 30px;
          width: 30px;
          position: relative;

          &:after,
          &:before {
            content: "";
            position: absolute;
            width: 2px;
            height: 15px;
            background: #eaeaea;
            left: 12px;
            top: 8px;
          }

          &:before {
            left: 18px;
          }
        }
      }
    }
  }

  &.mat-slide-toggle.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-thumb {
      background-color: #fff !important;
    }

    .mat-slide-toggle-bar {
      background-color: #018bec !important;
    }

    .mat-slide-toggle-thumb-container {
      transform: translate3d(17px, 0, 0);
    }
  }
}

.left-menu-wrapper {
  width: 300px;
  float: left;
  min-height: calc(100vh - 70px);
}

.main-content-area {
  width: calc(100% - 330px);
  float: right;
  padding-right: 30px;
}

.page-header-section {
  padding: 20px 0;

  .page-title {
    margin-bottom: 0;
    line-height: 35px;
    float: left;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    color: #0a0936;
  }

  .button-wrapper {
    float: right;

    .btn {
      margin: 0 0 0 15px;
      display: inline-flex;
      align-items: center;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .btn-link {
      color: #a0a0a0;

      &:hover {
        color: #333;
      }
    }
  }
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 73px);
  height: calc(100% - 73px);
  padding-top: 70px;
}

.margin0 {
  margin: 0 !important;
}

.label-info {
  font-size: 15px !important;
  font-weight: 500;
  margin-bottom: 10px;

  strong {
    font-weight: 500;
  }
}

.help-text-info {
  font-size: 13px !important;
  color: #888;
  margin-top: 6px;
}

.btn {
  padding: 12px 35px;
  margin-right: 10px;
  border-radius: 3px !important;
  font-size: 16px;
  box-shadow: none;

  i {
    float: left;
    font-size: 22px;
    margin-right: 10px;
  }

  &.btn-primary {
    background-image: linear-gradient(54deg,
        rgb(12, 144, 217) 0%,
        rgb(42, 126, 247) 50%,
        rgb(55, 117, 245) 69%,
        rgb(67, 108, 243) 100%);
    background-image: -moz-linear-gradient(54deg,
        rgb(12, 144, 217) 0%,
        rgb(42, 126, 247) 50%,
        rgb(55, 117, 245) 69%,
        rgb(67, 108, 243) 100%);
    background-image: -webkit-linear-gradient(54deg,
        rgb(12, 144, 217) 0%,
        rgb(42, 126, 247) 50%,
        rgb(55, 117, 245) 69%,
        rgb(67, 108, 243) 100%);
    background-image: -ms-linear-gradient(54deg,
        rgb(12, 144, 217) 0%,
        rgb(42, 126, 247) 50%,
        rgb(55, 117, 245) 69%,
        rgb(67, 108, 243) 100%);
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-footer {
  padding: 10px 0 40px;

  .button-wrapper {
    text-align: right;

    .btn {
      margin: 0 0 0 15px;

      &.btn-link {
        color: $gray-600;

        &:hover {
          text-decoration: none;
          color: $gray-800;
        }
      }
    }
  }
}

.menu-item-table {
  margin-top: 10px;

  .mat-form-field {
    margin-bottom: 0 !important;
    margin-left: 20px;

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 0 !important;
    display: none;
  }

  .mat-form-field-infix {
    border: 0;
    padding: 7px 9px;
    width: 400px;
    border-radius: 3px;
    height: 32px;

    .mat-form-field-label-wrapper {
      padding: 0;
    }
  }

  tr {
    &:hover {
      background: #f2f5ff;

      .mat-form-field-infix {
        border: 1px solid #c2c4cc;
        background: $white;
        height: 32px;
      }
    }

    .mat-slide-toggle-bar {
      margin-right: 20px;
    }

    input {
      font-size: 14px !important;
      font-weight: 600;
    }
  }
}

.enable-section-checkbox {
  li {
    label {
      span {
        font-weight: 600;
        font-size: 15px;
        font-family: "Nunito", sans-serif;
        color: #000;
      }
    }
  }
}

div.upload-image-outer {
  .profile-image {
    position: static;
  }

  .uload-image {
    position: static;
    width: 100%;
    float: left;
    margin-top: 5px;

    div.uload-image {
      width: 100%;
      float: left;
      margin-top: 5px;
      cursor: pointer;
      z-index: 1;
      border: 0;
      padding: 0;

      .upload {
        height: 40px;
        right: 0 !important;
        background: #eee !important;
        cursor: pointer;

        span {
          right: 0px !important;

          i {
            padding: 0;
            background: transparent !important;
            position: relative;
            top: 3px;
            color: #bbb;
          }
        }
      }
    }
  }
}

.tabing-section {
  .mat-tab-label {
    padding: 0 24px;
    min-width: inherit;
    background: #fff;
    opacity: 1;
    margin-right: 5px;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #7f8fa4;
    font-size: 16px;

    &.mat-tab-label-active {
      background: #018bec;
      color: #fff;
      border-color: #018bec;
    }
  }

  .block-container {
    .row {
      &:last-child {
        .mat-form-field {
          margin-bottom: 0 !important;
        }
      }

      .label-info {}

      .help-text-info {
        margin-top: -10px;
      }

      &.banner-image-uploader-wrapper {
        margin-bottom: 30px;

        .profile-image {
          margin: 0;
          position: static;
        }

        .uload-image {
          position: static;
          width: 100%;
          height: 40px;
          float: left;
          margin-top: 5px;

          .upload {
            right: 0 !important;
            background: #eee !important;
            cursor: pointer;

            span {
              right: 0px !important;

              i {
                padding: 0;
                background: transparent !important;
                position: relative;
                // top: 9px;
                top: 3px;
                color: #bbb;
              }
            }
          }
        }
      }

      &.textarea-field {
        .mat-form-field-underline {
          bottom: 22px !important;
        }

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
            -moz-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
            -webkit-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
            -ms-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
          }
        }

        // .mat-form-field-underline{
        //     bottom: 38px !important;
        // }
        .text-danger {
          position: absolute;
          bottom: 12px;
          left: 12px;
        }
      }
    }

    .mat-form-field-infix {
      textarea {
        height: 120px;
        border: 1px solid #ccc;
        padding: 20px;
        line-height: 26px !important;
      }
    }
  }
}

.pad0 {
  padding: 0;
}

.map-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.add-image {
  width: 550px !important;

  .mat-dialog-container {
    padding: 0;

    .modal-header {
      padding: 15px;
      border-bottom: 1px solid #ddd;

      h3 {
        float: left;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 26px;
      }

      button {
        float: right;
        background: transparent;
        border: 0;
        cursor: pointer;
      }
    }

    .modal-body-content {
      padding: 30px;

      .uload-image {
        width: 100%;
        position: relative;
        height: 170px;
        border: 1px dashed #ccc;
        margin-bottom: 40px;

        span {
          font-weight: 400;

          small {
            color: #888;
            font-size: 13px;
          }
        }
      }

      .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: #999;
      }

      .show-on-homepage {
        color: #000;
        font-size: 16px;
      }
    }

    .modal-footer-content {
      padding: 0 30px 35px;

      .submit-btn {
        background: #31b544;
        color: #fff;
        cursor: pointer;
      }

      .cancel-btn {
        color: #888;
        font-size: 16px;
        margin-left: 30px;
        cursor: pointer;

        i {
          font-size: 23px;
          position: relative;
          top: 5px;
          margin-right: 6px;
        }
      }
    }
  }
}

.shared-with-admin {
  .content-section-outer {
    padding: 0;

    .content-wrapper {
      max-width: inherit;
    }
  }

  .admin-image-action {
    display: flex;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 10px;
    bottom: 10px;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.5s ease;
    visibility: hidden;

    span {
      i {
        padding: 10px;
        background: #fff;
        border-radius: 2px;
        font-size: 24px;
        margin: 0 5px;
        cursor: pointer;
      }
    }

    p {
      color: #fff;
      font-size: 20px;
      margin-top: 30px;
    }
  }

  .gallery-item-list:hover {
    .admin-image-action {
      opacity: 1;
      visibility: visible;
    }
  }
}

.banner-image-uploader-wrapper {
  .profile-image {
    height: auto !important;
    width: 100% !important;
    border-radius: 0 !important;
    float: left !important;
    margin: 10px 0 20px;
  }

  .uload-image {
    .upload {
      span {
        right: -30px !important;

        i {
          border: 0 !important;
          background: #fc6c6d !important;
        }
      }
    }
  }

  img {
    width: 100% !important;
    height: inherit !important;
    float: left;
  }
}

.gallery-component-called {
  .content-section-outer {
    padding: 0;
    background: transparent !important;

    .content-wrapper {
      max-width: 100%;
    }

    .delete-image {
      position: absolute;
      top: 5px;
      left: 10px;
      background: #fc6c6d;
      padding: 0;
      border-radius: 50%;
      height: 36px;
      width: 36px;
      align-items: center;
      justify-content: center;
      z-index: 99;
      display: none;

      i {
        font-size: 20px;
        color: #fff;
      }
    }

    .gallery-item-list:hover {
      .delete-image {
        display: flex;
      }
    }
  }
}

.modal-header {
  .lang-drop-menu {
    float: left;
    line-height: 23px !important;
    font-size: 13px;
    border-left: 1px solid #ddd;
    padding-left: 30px;
    margin-top: 2px !important;
    margin-right: 0 !important;
    margin-left: 30px;
    padding-right: 0 !important;
    border-right: 0 !important;
  }
}

.lang-drop-menu a {
  color: #000;
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
}

.modal-body-content {
  .uploaded-image {
    position: relative;

    i {
      color: #fff !important;
      background: #ff6d6d;
      padding: 10px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      position: absolute;
      top: -10px;
      left: -10px;
      cursor: pointer;
    }
  }
}

.image-large-view {
  position: relative;

  .close-popup {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    background: transparent;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: #fff;
  }
}

.slide-image {
  .mat-dialog-container {
    padding: 10px !important;
  }
}

body {
  padding-bottom: 143px;
  position: relative;
}

html,
body {
  min-height: calc(100vh - 143px);
}

body.hotelClass {
  padding-bottom: 0 !important;
}

footer {
  padding: 30px 0 !important;
  // position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}

.aboutus-description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 30px;
}

#about-info h2 {
  font-size: 34px;
  margin-bottom: 30px;
  font-weight: 900;
}

.form-wrapper {
  .checkin-checkout-wrapper {
    padding-top: 40px;

    .mat-form-field {
      margin-bottom: 0;

      .mat-form-field-label-wrapper {
        top: -2.5em !important;

        i {
          font-size: 19px;
          position: relative;
          top: 4px;
          margin-left: 10px;
          color: #f7b732;
        }
      }
    }
  }
}

.pref-list {
  ul {
    li {
      .mat-form-field-wrapper {
        padding-bottom: 0 !important;
      }

      .mat-form-field-appearance-legacy .mat-form-field-underline {
        bottom: 0 !important;
      }

      .mat-form-field-infix {
        padding: 0;
        border: 1px solid #adadad;

        .mat-select-trigger,
        .mat-input-element {
          height: 30px;
          padding: 10px;
        }

        .mat-input-element {
          height: 36px !important;
        }
      }
    }
  }
}

.content-wrapper,
.container,
.footer-wrapper {
  padding: 0 20px;
}

i.remove-img {
  color: #000 !important;
  background: #ff6d6d;
  padding: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: absolute;
  bottom: -40px;
  left: 35%;
  border: 2px solid #fff;
}

.file-upload-wrapper {
  position: relative;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  min-height: 111px !important;
  max-height: 300px !important;
  overflow: auto;
}

// check list
.inner-list-wrapper {
  width: 100%;

  &.inner-amn-list {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .check-list {
    width: 16.6666%;
    float: left;
    padding: 0 5px;

    &.mat-checkbox-checked {
      label {
        background: #1fa8ef;
        border: 1px solid #1fa8ef;

        .mat-checkbox-label {
          color: #fff;
        }
      }
    }

    &:first-child {
      padding-left: 0;
    }

    label {
      width: 100%;
      border-radius: 3px;
      border: 1px dashed rgb(183, 183, 183);
      line-height: 37px;
      padding: 0 15px;
      display: inline-block;
      color: #888;

      .mat-checkbox-inner-container {
        height: 18px;
        width: 18px;
        float: right;
        margin: 9px 0 0;
      }
    }
  }

  .check-list-btn {
    width: auto;
    line-height: 39px;
    padding: 0 23px;
    background: #ececec;
    color: #888;
    border: 0;
    margin: 0px 5px 10px 5px;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;

    i {
      font-size: 20px;
      color: #999;
      position: relative;
      top: 5px;
      margin-right: 5px;
    }
  }

  .amn-repat-list {
    span {
      color: #888;
    }
  }
}

.overhidden {
  overflow: hidden;
}

.ck-editor {
  .ck-toolbar {
    background: #fff !important;
  }

  .ck.ck-button,
  a.ck.ck-button {
    margin: 0 13px 0 0 !important;
  }
}

.rotating-img-wrapper {
  width: 100%;

  .rotating-images {
    padding: 0 15px;
  }

  img {
    width: auto;
    height: 100%;
    max-width: inherit;
  }

  .inner-image-wrapper {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 10px;
    width: 140px;
    height: 140px;
    margin-right: 14px;
    padding: 13px 12px 0 0;

    img {
      width: 100%;
      object-fit: contain;
    }

    i.remove-img {
      width: 25px;
      height: 25px;
      font-size: 17px;
      padding: 5px;
    }
  }

  .example-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.room-list-view {
  .content-section-outer {
    padding: 0 !important;
  }

  .content-wrapper {
    max-width: inherit;
    padding: 0;

    .gallery-item-list {
      margin-bottom: 15px;

      .wrapper-list-thumb {
        box-shadow: 0.5px 0.866px 3px 0px rgba(0, 0, 0, 0.2);
      }
    }

    .room-img-wrapper {
      width: 100%;
      height: 180px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        // width: 100%;
        min-height: 100%;
      }
    }

    .restock-warning {
      padding: 5px 20px;
      width: 100%;
      background-color: #FFC90C;
      display: block;
      color: #333333;
      font-size: 12px;
      font-weight: 600;
      top: 180px;
    }

    .list-thumb-content {
      padding: 25px 20px 20px 20px !important;
    }

    .button-section {
      padding: 0 20px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        padding: 4px 8px;
        font-size: 13px;
        margin: 0;

        i {
          font-size: 14px;
          position: relative;
          top: 1px;
          line-height: 18px;
          margin-right: 7px;
        }

        &:last-child {
          float: right;
          color: #bbbbbb;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12);
          background: #fff;
        }
      }
    }
  }
}

.add-room-page {
  .page-title {
    i {
      position: relative;
      margin-right: 14px;
      top: 5px;
      font-size: 30px;
    }
  }

}

.room-name-info {
  margin-bottom: 15px;
  padding: 0px 30px !important;

  p {
    margin: 0;
    font-size: 16px;
    color: #888 !important;
    font-family: "Nunito", sans-serif;

    span {
      display: inline-block;
      font-size: 14px;
    }
  }

  h2 {
    font-size: 26px;
    font-weight: 600;
    // color: #000;
    margin: 11px 0 7px;
  }

  .ckeditor-front {
    ol {
      list-style-type: none;
      padding-left: 0;
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 0;

      li {
        list-style-type: none;
      }
    }
  }
}

.editor-content-wrapper {
  margin-bottom: 30px;
  padding: 0 30px !important;

  ol {
    padding-left: 17px !important;

    li {
      width: 100%;
      font-size: 15px;
      color: #4a5260;
      word-break: break-all;
      line-height: 25px;
      font-size: 500;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
    word-break: normal;
    line-height: 25px;
    font-size: 500;
    font-family: "Nunito", sans-serif;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.amenities-list-print {
  // margin-bottom: 30px;

  li {
    float: left;
    width: 200px;
    margin: 0px 10px 10px 0;
    background: #f3f3f3;
    color: #676767;
    padding: 12px 15px;
    box-sizing: border-box;
    border-radius: 3px;

    span {
      i {
        font-size: 15px;
        vertical-align: middle;
        color: #888888;
        margin-right: 8px;
        line-height: 12px;
      }
    }
  }
}

.roomImages {
  li {
    float: left;
    width: 140px;
    height: 140px;
    margin: 0px 10px 10px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    img {
      height: 100%;
      max-width: inherit;
    }
  }
}

.tabing-section {
  .mat-tab-header {
    border: 0px;
  }

  .mat-ink-bar {
    display: none;
  }
}

.admin-gallery-cth {
  #hotel-gallery {
    .content-wrapper {
      padding: 0px;

      .main-content-wrapper {
        .gallery-item-list {
          max-width: 20%;
          max-height: 285px;
          height: 285px;
          padding: 0px 7px;

          .img-info-on-hover {
            left: 7px !important;
            right: 7px !important;
            bottom: 0 !important;
            top: 0 !important;
          }
        }
      }
    }
  }
}

.admin-profile {
  .tab-slider--body {
    .info-left-section {

      .country-code {
        display: flex;
        align-items: end;
        padding: 30px 0 !important;

        .mat-select {
          width: 30%;
          padding: 20px;
          height: 58px;
          border: 1px solid #e9e9e9;
          border-radius: 3px;
          margin: 0 10px 25px 0;
          background: #e9e9e9;
        }

        .phone-number {
          width: fit-content;

          .phone-wrapper {
            border-radius: 3px;
            border: 1px solid #e9e9e9 !important;

            input {
              border: none;
              width: 50%;
              height: 58px;
              margin-top: 0px !important;
              padding: 20px 10px;
            }

            .verified-wrapper {
              position: absolute;
              top: 35%;
              right: 10px;

              .verified-text {
                font-size: 12px;
                color: #38AB7C;
                margin-left: 6px;
                cursor: pointer;
              }
            }

          }

          .change-mail {
            display: inline-block;
            font-size: 13px;
            font-weight: 400;
            color: #1FA8EF;
            margin-top: 10px;
            cursor: pointer;
          }

          .verify-text {
            font-size: 13px;
            color: #888888;

            .verify-btn {
              color: #1FA8EF;
              text-decoration: underline;
              cursor: pointer;
            }

          }
        }
      }

      .mat-error {
        font-size: 12px;
      }
    }
  }
}

.mat-menu-panel.lang-drop-menu {
  padding-right: 0px;
  margin-top: 0px;
}

.lang-drop-menu {
  float: left;
  line-height: 30px;
  font-size: 15px;
  border-right: 1px solid #ddd;
  padding-right: 41px;
  margin-top: 9px;

  i {
    top: 0px;
    position: relative;
  }

  a {
    color: #000;
    margin-left: 5px;
  }
}

aside.admin-left-menu {
  background-color: #fff;
  box-shadow: 0.94px 0.342px 2px 0px rgba(0, 0, 0, 0.2);
  width: 300px;
  display: flex;
  height: 100%;

  li {
    float: left;
    width: 100%;

    a {
      font-size: 14px;
      padding: 20px 20px 20px 35px;
      width: 100%;
      float: left;
      line-height: 25px;
      color: #4a5260;
      display: flex;

      i {
        float: left;
        margin-right: 30px;
      }
    }

    &:first-child {
      margin-top: 2px;
    }

    &.active,
    &:hover {
      a {
        position: relative;
        color: #000;
        font-weight: 600;

        i {
          color: #018bec;
        }

        &:after {
          content: "";
          position: absolute;
          top: 7px;
          left: 0;
          width: 6px;
          height: 50px;
          background-color: #018bec;
        }
      }
    }

    &:hover {
      a {
        &:after {
          opacity: 0.3;
        }
      }
    }

    &.active:hover {
      a {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.ch-guide-listing {
  display: block;
  position: relative;
  margin: 10px 0px;

  .ch-guide-list {
    display: block;
    position: relative;
    padding: 10px 85px 5px 20px;
    margin: 0px 0px 10px 0px;
    box-shadow: 0.5px 0.866px 3px rgba(0, 0, 0, 0.2);
    background-color: $white;
    transition: all 0.3s;
    border-radius: 3px;
    min-height: 60px;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.36);
      padding-left: 55px;

      .ch-guide-action-ic {
        right: 28px;
        opacity: 1;
        display: block;
      }

      .ch-guide-app-ic {
        left: 15px;
        opacity: 1;
        display: block;
      }
    }

    h3 {
      margin: 0px 0px 12px 0px;
      font-size: 18px;
    }

    p {
      font-size: 15px;
      margin: 0px 0px 8px 0px;
    }

    .ch-guide-action-ic {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 65px;
      height: 26px;
      margin: auto;
      z-index: 1;
      opacity: 0;
      transition: all 0.3s;
      display: none;

      a {
        display: inline;
        margin: 0px 0px 0px 10px;

        i {
          color: $action-ic;
          font-size: 22px;
        }
      }
    }

    .ch-guide-app-ic {
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      width: 30px;
      height: 26px;
      margin: auto;
      z-index: 1;
      opacity: 0;
      transition: all 0.3s;
      display: none;

      a {
        display: inline-block;
        width: 30px;
        height: 26px;

        i {
          color: $action-ic;
          font-size: 22px;
        }
      }
    }
  }
}

.textarea-field-cs {
  textarea {
    height: 200px;
    border: 1px solid #999;
    padding: 10px 20px !important;
    line-height: 26px !important;
  }

  .mat-form-field-label {
    font-size: 14px !important;
    transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
    -moz-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
    -webkit-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
    -ms-transform: translateY(-1.28125em) scale(0.96) perspective(100px) translateZ(-2.99899px) !important;
  }

  .mat-form-field-wrapper .mat-form-field-underline {
    bottom: 22px;
  }
}

.amenities-list {
  .check-list {
    margin: 0px 0px 15px 0px;
  }
}

.inner-list-wrapper {
  .amn-repat-list {
    position: relative;
    display: inline-block;
    margin: 0px 5px 10px 0px;

    span.active {
      i {
        color: #fff;

        &.edit-am {
          &.icon_circle {
            color: #1fa8ef;

            &:before {
              background: #fff;
            }
          }
        }
      }
    }

    .edit-am {
      position: absolute;
      left: 6px;
      top: 0;
      font-size: 13px;
      z-index: 1;
      cursor: pointer;
      width: 30px;
      height: 40px;
      line-height: 38px;
      text-align: center;

      &.icon_circle {
        color: #fff;

        &:before {
          background-color: #888;
          border-radius: 25px;
          height: 18px;
          width: 18px;
          align-items: center;
          top: 9px;
          left: 6px;
          content: "";
          position: absolute;
          z-index: -1;
        }
      }
    }

    .check-list {
      width: 100%;

      label {
        padding-left: 35px;

        .mat-checkbox-label {
          margin: 0px 15px 0px 0px;
          min-width: 100px;
          display: inline-block;
        }
      }
    }
  }
}

.room-list-view .main-content-wrapper.row {
  margin-right: 0;
  margin-left: 0px;
  justify-content: left;
}

.gallery-component-called {
  .content-wrapper {
    padding: 0;
  }
}

// hotel Guide
.ch-guide-list {
  &.block-container {
    padding: 20px 20px 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    min-height: 92px;
    box-shadow: 0.5px 0.866px 3px 0px rgba(0, 0, 0, 0.2);

    * {
      transition: all 0.1s ease-out;
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    p {
      font-size: 14px;
      color: #333;
      line-height: 20px;
      margin-top: 10px;
    }

    .guideContent {
      width: 100%;
    }

    ol {
      margin: 0;

      li {
        margin-bottom: 10px;
      }
    }

    ul {
      margin: 0;

      li {
        margin-bottom: 10px;
      }
    }

    .ch-guide-action-ic {
      width: 100px;
      visibility: hidden;
      opacity: 0;

      i {
        font-size: 24px;
        margin-left: 15px;
        color: #888;

        &:hover {
          color: #000;
        }
      }
    }

    .ch-guide-app-ic {
      width: 0;
      visibility: hidden;
      opacity: 0;

      i {
        color: #888;

        &:hover {
          color: #000;
        }
      }
    }

    &:hover {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.36);

      .ch-guide-app-ic {
        width: 48px;
        opacity: 1;
        visibility: visible;
      }

      .ch-guide-action-ic {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.cdk-drag-placeholder {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px dashed #000;
}

.ck-editor__main p {
  font-size: 14px !important;
}

div {
  &.span-image-wrapper-outer {
    overflow: visible;
    background: transparent;

    .span-image-wrapper {
      background: #eee;
      height: 100%;
      width: 100%;
      display: block;
    }
  }
}

.span-image-wrapper {
  height: 100%;
}

.room-img-wrapper {
  width: 100%;
  height: 180px;
  overflow: hidden;
  background: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  // background: #fff;
  .date-time-list {
    &:not(.date-time-list-detail) {
      transform: translateY(130px);
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }

  &:hover {
    .date-time-list {
      &:not(.date-time-list-detail) {
        transform: translateY(0);
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }
    }
  }

  a {
    // display: inline-block;
    // // width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
  }

  img {
    // width: 100%;
    height: 100%;
    width: 100%;
    max-height: 100% !important;
    object-fit: cover;
  }
}

.container.inner-page {
  max-width: 1400px;
  padding: 50px 30px 80px 30px;

  .arrow-container {
    width: 90px !important;
    height: 90px !important;
    background: #000000;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50%;
    top: 50% !important;
    margin-top: -45px !important;
    z-index: 1 !important;

    &.prev {
      left: 30px !important;
    }

    &.next {
      right: 30px !important;
    }

    &:before {
      display: none !important;
    }

    i {
      &.arrow.prev {
        left: 8px !important;
        margin: 0 !important;
      }

      &.arrow.next {
        right: -12px !important;
      }
    }
  }
}

.white-bg {
  background: #fff;
}

.room-info-details {
  margin-top: 50px;

  .review-star {
    i {
      font-size: 14px;
      color: #f1ca17;
    }
  }
}

.custom-block-container {
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.11),
    0px 2px 3px 0px rgba(0, 0, 0, 0.22) !important;
  padding: 0 !important;

  .mat-tab-body-content {
    padding: 30px;

    p {
      word-break: break-all;
    }
  }

  .mat-tab-label {
    height: 60px;
  }
}

/* New Css MS 27 May*/

.edit-about-bottom {
  width: 100%;
  display: inline-block;
  padding-top: 65px;
}

input.mat-input-element {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}

.form-footer {
  padding: 10px 0 40px;
  width: 100%;
  display: inline-block;
}

.lang-drop-menu>span {
  color: #555555;
}

.edit-about-bottom {
  .profile-image {
    width: 100% !important;
    border-radius: 0px !important;
    height: auto !important;

    img {
      height: auto !important;
      width: 100% !important;
      float: left;
    }
  }

  .uload-image {
    i.remove-img {
      top: -29px;
      right: 8px;
      border: 2px solid #fff;
    }
  }
}

.show-room-list {
  .example-viewport {
    width: 98% !important;
    border: none !important;
    margin: 0% 0% 0% 2% !important;

    .ch-guide-list.block-container {
      padding: 10px 15px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      min-height: 59px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      margin-top: 10px;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

      &:last-child {
        margin-bottom: 10px;
      }
    }

    .example-list {
      padding-right: 10px;

      h3 {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 500;
      }
    }
  }
}

.edit-about-bottom {
  .field-sec-right {
    @media screen and (max-width: 575px) {
      margin-top: 45px;
    }
  }
}

@media screen and (max-width: 767px) {
  .caption-col-right {
    padding-top: 30px;
  }
}

.edit-about-bottom .uload-image .uload-image {
  border: 1px dashed #888;
  width: 100%;
  position: relative;
  right: 0;
  padding: 10px;
  height: 75px;
  bottom: auto;
  float: left;
  margin-top: 2px;
}

footer {
  .footer-wrapper {
    .footer-right-info {
      .social-icon {
        padding-top: 15px !important;
      }
    }
  }
}

// button.btn.update-room-type {
//     display: block;
//     padding: 8px 15px;
//     font-size: 14px;
//     margin-top: 6px;
// }
button.update-room-type {
  border: none;
  background-color: transparent;
  color: #018bec;
  position: absolute;
  bottom: 25px;
  right: 15px;
  padding: 0px;
  font-size: 14px;
}

.wrapper-list-thumb {
  .delete-image {
    color: #fff !important;
    background: #ff6d6d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-size: 15px;
    padding: 5px;
    z-index: 99;
    border: 2px solid #fff;

    i.material-icons {
      font-size: 20px;
    }
  }
}

.guide-detail-box {

  .row1,
  .row2 {
    width: 50%;
  }

  .row2 {
    border-left: 1px solid #ddd;
  }

  .ch-guide-list {
    box-shadow: none;
    padding: 20px 25px;
    margin: 0px 0px 30px 0px;

    @media (min-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0px;
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.guide-detail-box {
  border: 1px solid #ddd;
  padding: 20px 15px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.21);

  .ch-guide-list:nth-child(1n) {
    border-right: 1px solid #ddd;
  }

  .ch-guide-list {
    .ckeditor-front {

      ol,
      ul {
        padding-left: 15px;

        li {
          word-break: break-word;
        }
      }
    }
  }
}

.page-location {
  p {
    display: inline-block;
    // color: #dddddd !important;
  }

  a:hover {
    text-decoration: underline;
  }
}

.mat-paginator {
  width: 100%;
  padding-top: 0px;
  margin-bottom: 10px;
  margin-top: 10px;

  .mat-icon-button {
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    background-color: #1e5de8;
    color: #fff !important;
    margin: 0px 5px;

    &[disabled] {
      background-color: rgba(30, 93, 232, 0.568627);
    }
  }

  .mat-paginator-navigation-first,
  .mat-paginator-navigation-last {
    // background-color:#104061;
    background-color: #fff;
    border: 1px solid #c7c7c7;
    color: #000 !important;
  }

  .mat-paginator-range-label {
    // display: none;
  }

  .mat-paginator-navigation-first[ng-reflect-disabled="true"],
  .mat-paginator-navigation-last[ng-reflect-disabled="true"] {
    cursor: no-drop;
  }

  .mat-paginator-navigation-first[ng-reflect-disabled="false"],
  .mat-paginator-navigation-last[ng-reflect-disabled="false"] {
    &:hover {
      background-color: #1e5de8;
      color: #fff !important;
      border-color: #1e5de8;
    }
  }

  .mat-paginator-container {
    justify-content: center;
  }
}

.tabing-section {
  .mat-tab-body-content {
    padding: 0px 1px;
  }
}

.amenities-list-print {
  // margin-bottom: 50px;

  ul {
    &.amenities-ul {
      li {
        background-color: transparent;

        span.secondary-text {
          background-color: transparent;
          padding-left: 0;
          padding-right: 0;

          i {
            color: #fff;
            position: relative;
            font-size: 16px;
            font-weight: 600;

            &:before {
              font-family: "Material Icons";
              content: "done";
              position: absolute;
              z-index: 2;
              color: #fff;
            }

            &:after {
              position: absolute;
              content: "";
              height: 18px;
              width: 18px;
              border-radius: 50%;
              background-color: $green;
              top: -3px;
              left: -1px;
              z-index: 1;
            }
          }
        }
      }

      &.amenities-ul-exclusion {
        li {
          span.secondary-text {
            i {
              &:before {
                content: "minimize";
                top: -5px;
              }

              &:after {
                background-color: $red
              }
            }
          }
        }
      }
    }
  }

  &.list-print {
    h4 {
      margin-bottom: 10px;

      .heading-icon-wrapper {
        .heading-icon {
          margin-right: 5px;
          display: inline-block;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background-color: #33c46d;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          color: white;

          &.exclusion-icon {
            align-items: baseline;
            background-color: $red;
            line-height: 10px;
          }
        }
      }
    }

    ul {
      li {
        span {
          &.secondary-text {
            background-color: #fff;
            font-weight: 500;
            line-height: 18px;
            padding-left: 0;

            .check_circle {
              align-content: center;
              border-radius: 50%;
              border: none;
              display: inline-flex;
              height: 18px;
              justify-content: center;
              width: 18px;
              line-height: 18px;
              color: #fff;
              background-color: #0bd206;
            }

            .font-size-21 {
              font-size: 21px;
            }
          }
        }
      }
    }
  }

  ul {
    margin: 0px -8px;

    li {
      // width: 16.666%;
      width: auto;
      margin: 0px 0px 16px 0;
      color: #888;
      padding: 0px 8px;
      box-sizing: border-box;
      border-radius: 3px;
      background-color: transparent;
      min-width: 220px;

      span.secondary-text {
        color: #888 !important;
        line-height: 16px;
        padding: 12px 15px;
        background-color: #f3f3f3;
        width: 100%;
        display: inline-block;
      }
    }
  }
}

.roomImages {
  li {
    position: relative;

    .admin-image-action {
      display: flex;
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.7);
      text-align: center;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 0.5s ease;
      visibility: hidden;

      i {
        color: #fff;
        font-size: 32px;
      }
    }

    &:hover {
      .admin-image-action {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

/*********************************************************************/

//carousel
/*********************************************************************/

// Wrapper for the slide container and indicators
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  @include transition($carousel-transition);
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

// CSS3 transforms when supported by the browser
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(-100%, 0, 0);
  }
}

a.carousel-control:hover,
a.carousel-control-next:hover {
  background-image: linear-gradient(to right,
      rgba(250, 255, 255, 0) 0px,
      rgba(255, 255, 255, 0.5) 100%);
}

a.carousel-control-prev:hover {
  background-image: linear-gradient(to left,
      rgba(250, 255, 255, 0) 0px,
      rgba(255, 255, 255, 0.5) 100%);
}

//
// Left/right controls for nav
//
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  // Use flex for alignment (1-3)
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  width: $carousel-control-width;
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-opacity;

  // We can't have a transition here because WebKit cancels the carousel
  // animation if you trip this while in the middle of another animation.
  // Hover/focus state
  @include hover-focus {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }
}

.carousel-control-prev {
  left: 0;

  @if $enable-gradients {
    background: linear-gradient(90deg,
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.001));
  }
}

.carousel-control-next {
  right: 0;

  @if $enable-gradients {
    background: linear-gradient(270deg,
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.001));
  }
}

// Icons for with
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}

.carousel-control-prev-icon {
  background-image: $carousel-control-prev-icon-bg;
}

.carousel-control-next-icon {
  background-image: $carousel-control-next-icon-bg;
}

.inner-page {
  .carousel-new {
    height: 487px;
    display: block;
    overflow: hidden;

    .owl-carousel {
      position: relative;
      height: 100%;

      .owl-stage-outer {
        height: 100%;

        owl-stage {
          height: 100%;

          >div {
            height: 100%;

            .owl-stage {
              height: 100%;
            }
          }

          .owl-item {
            height: 100%;

            img {
              object-fit: contain;
            }
          }
        }
      }

      .owl-nav {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .owl-prev {
          height: 69px;
          width: 67px;
          background-color: #CCCCCCC9;
          border-radius: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 121px;
          color: transparent;

          &::after {
            content: "arrow_back";
            font-family: "Material Icons";
            color: white;
            position: relative;
            right: 5px;
            font-size: 24px;

          }
        }

        .owl-next {
          height: 69px;
          width: 67px;
          background-color: #CCCCCCC9;
          border-radius: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 121px;
          color: transparent;

          &::after {
            content: "arrow_forward";
            font-family: "Material Icons";
            color: white;
            position: relative;
            right: 5px;
            font-size: 24px;

          }
        }
      }
    }
  }
}

.inner-page .carousel-control-prev-icon {
  background-image: $carousel-control-prev-icon-black-bg;
}

.inner-page .carousel-control-next-icon {
  background-image: $carousel-control-next-icon-black-bg;
}

// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0; // override <ol> default
  // Use the .carousel-control's width as margin so we don't overlay those
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    position: relative;
    flex: 0 1 auto;
    // width: $carousel-indicator-width;
    width: 18px;
    // height: 6px;
    height: 18px;
    border-radius: 50%;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    text-indent: -999px;
    // background-color: rgba($carousel-indicator-active-bg, .5);
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;

    // Use pseudo classes to increase the hit area by 10px on top and bottom.
    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    // background-color: $carousel-indicator-active-bg;
    background-color: #f9b420;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.45);
  }
}

// Optional captions
//
//
.carousel-caption {
  position: absolute;
  right: ((100% - $carousel-caption-width) / 2);
  bottom: 20px;
  left: ((100% - $carousel-caption-width) / 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;
}

.restuarant-menu-lists,
.mat-tab-body {
  li {
    width: auto;
    float: left;
    padding: 7px;

    img {
      max-height: 200px;
      min-height: 200px;
    }

    @media screen and (max-width: 991px) {
      width: 20%;
    }

    @media screen and (max-width: 767px) {
      width: 25%;
    }

    @media screen and (max-width: 575px) {
      width: 33.333%;
    }

    @media screen and (max-width: 479px) {
      width: 50%;
    }
  }
}

div.cdk-overlay-pane.slide-image {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
}

.slide-image .mat-dialog-container {
  padding: 20px 80px !important;
  background: #000000de !important;
}

.slide-image .image-large-view {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;

  a.carousel-control:hover,
  a.carousel-control-next:hover {
    background-image: none;
  }
}

.slide-image .carousel-item,
.slide-image .carousel-inner {
  height: 100%;
}

.slide-image .image-large-view .close-popup {
  top: -5px;
  right: -63px;
  font-size: 35px;
}

.slide-image .carousel {
  position: relative;
  width: 100%;
  text-align: center;
}

.slide-image .carousel-control-prev,
.slide-image .carousel-control-next {
  width: 45px;
}

.slide-image .carousel-control-prev {
  left: -65px;
}

.slide-image .carousel-control-next {
  right: -65px;
}

.slide-image .carousel-item img {
  max-height: 820px;
}

.inner-page .carousel-inner {
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.inner-page .carousel-inner .carousel-control-prev,
.inner-page .carousel-inner .carousel-control-next {
  top: 50%;
  bottom: 0;
  width: 75px;
  color: #fff;
  text-align: center;
  opacity: 1;
  height: 75px;
  margin-top: -37px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

// .inner-page .carousel-control-prev {
//     left: 40px;
// }
// .inner-page .carousel-control-next {
//     right: 40px;
// }
.single-image .carousel-control-prev,
.single-image .carousel-control-next {
  opacity: 0;
}

.reviews-list {
  width: 100%;
  display: inline-block;

  span {
    color: #fbbc68;
    font-size: 13px;
    float: left;
    width: 18px;

    .material-icons {
      font-size: 18px;
    }
  }
}

.imageWrapper.ng-star-inserted {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-page {
  .carousel {
    .carousel-item {
      img {
        min-height: 614px;
        max-height: 614px;
        width: auto;
      }
    }

    .carousel-indicators {
      bottom: 20px;

      li {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);

        &.active {
          background-color: #f9b420;
          box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.45);
        }
      }
    }

    .carousel-control-prev,
    .carousel-control-next {

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.pagination {
  width: 100%;

  .mat-paginator {
    background: transparent;
  }

  .mat-paginator .mat-icon-button {
    background-color: #1fa8ef;
  }

  .mat-paginator .mat-icon-button[disabled] {
    opacity: 0.6;
  }
}

.remove-amlabel {
  position: absolute;
  top: -6px;
  right: 0;
  font-size: 19px;
  text-shadow: 1px 1px 2px rgb(255, 255, 255), -1px 2px 1px rgb(255, 255, 255);
  color: #ff6d6d;
  cursor: pointer;
}

#hotel-rooms,
#hotel-restaurants {

  app-hotel-rooms,
  app-hotel-restaurants {
    width: 100%;
  }
}

//Loader Inner Loader
.inner-loader {
  .tp-section {
    display: flex;
    align-content: center;
    align-items: center;
    height: 60px;
  }

  .tp-margin {
    margin: 0 10px;
  }
}

// table style
.main-item-table {
  table {
    width: 100%;

    th {
      border-left: 1px solid #efefef;
      border-bottom: 2px solid #dddddd !important;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
      height: 48px;
      color: #555;
      text-align: center;
      vertical-align: middle;
      padding: 10px;
      font-family: "Nunito";


      &:first-child {
        border-left: 0;
      }

      &:nth-child(3) {
        text-align: left;
        padding-left: 20px;
      }

      i {
        position: relative;
        font-size: 20px;
        top: 5px;
        left: 5px;
      }

      &:nth-child(2) {
        text-align: left;
        padding-left: 20px;
      }
    }

    td {
      border-left: 1px solid #d6d6d6;
      line-height: 20px;
      border-color: #eee;
      padding-left: 20px !important;
      font-size: 14px;
      white-space: nowrap;
      vertical-align: middle;
      height: 48px;
      font-family: "Nunito";

      padding: 10px;

      &:first-child {
        border-left: 0;
      }

      &:nth-last-child(1) {
        text-align: center;
      }

      &.user-images {
        span {
          width: 31px;
          height: 31px;
          background: #ccc;
          border-radius: 50%;
          margin-top: 14px;
          overflow: hidden;
          border: 3px solid #fff;
          margin-right: -6px;
          display: inline-block;

          &:last-child {
            margin-right: 20px;
          }

          img {
            max-width: 100%;
            float: left;
          }

          i {
            margin-right: 0;
            color: #fff;
            float: left;
            margin-top: 3px;
            margin-left: 4px;
            top: 0;
          }
        }
      }

      i {
        font-size: 18px;
        margin-right: 15px;
        color: #d8d8dc;
        position: relative;
        top: 4px;
        cursor: pointer;
      }

      .image-wrapper-user {
        height: 60px;
        float: left;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background: #f6f8fb;
        }

        &:hover {
          background: #e7eaf1;

          td {
            i {
              color: #333;
            }

            span {
              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .role-table {
    td {
      span {
        img {
          height: 100%;
        }
      }
    }
  }
}

.inner-loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  bottom: 0;
  right: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.64);

  .mat-progress-spinner {
    margin: 180px auto 0;
  }
}

.main-content-wrapper {
  position: relative;
}

.role-manage-table {
  &.main-item-table {
    table {
      th {
        border-bottom: 2px solid #cacdda !important;
        line-height: 25px;
        text-align: center;
        font-family: "Nunito";

        i {
          color: #f8b833;
          font-size: 17px;
          cursor: pointer;
        }

        &:nth-child(2) {
          text-align: left;
        }
      }

      td {
        line-height: 25px;
        height: 50px;
        text-align: center;
        font-family: "Nunito";

        &:nth-child(2) {
          text-align: left;
        }
      }

      tr.active-list {
        background: #dbdee9;
      }
    }
  }
}

.assignedUserBlock {
  position: relative;
  border: 1px solid #e2e2e2;
  padding: 10px;
  display: flex;
  width: auto;
  float: left;
  min-width: 220px;
  margin: 10px 0;
  border-radius: 3px;

  .remove-amlabel {
    top: -5px;
    right: -5px;
  }

  .userImg {
    width: 32px;
    height: 32px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 50%;

    img {
      max-width: 100%;
    }
  }

  .userNameInc {
    line-height: 32px;
    font-size: 16px;
    font-weight: 600;
  }
}

.staff-list {
  .list-thumb-content {
    .staffImgInfo {
      display: flex;
      position: relative;

      .staffRole {
        // position: absolute;
        position: static;
        display: inline-block;
        margin-bottom: 7px;
        margin-left: -6px;
        padding: 5px 10px;
        font-size: 11px;
        color: #888;
        background-color: #f6f6f6;
        right: 10px;
        top: 10px;
        border-radius: 15px;
      }

      .staffImgWrap {
        min-width: 50px;
        width: 50px;
        height: 50px;
        overflow: hidden;
        margin-right: 15px;
        border-radius: 50%;
        background-color: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .staffInfoWrap {
        p {
          &:first-child {
            margin: 3px 0 6px !important;
          }
        }
      }
    }

    .staffContactInfo {
      padding-top: 15px;
      margin-top: 20px;
      border-top: 1px solid #ddd;
      margin-bottom: 12px;
      line-height: 24px;
      font-size: 13px;
      color: #333;

      span {
        margin-right: 0;

        &:last-child {
          margin-left: 0;
          word-wrap: break-word;
        }
      }

      .contact_head {
        margin-bottom: 3px;
      }

      p {
        line-height: 1.6;
      }
    }
  }

  .button-section {
    display: flex;
    justify-content: space-between;
    font-size: 13px;

    .btn-secondary {
      background: transparent !important;
      color: #888;
    }

    span {
      color: #eca43c;
      margin-top: 3px;

      i {
        font-size: 16px;
        margin-right: 2px;
        position: relative;
        top: 3px;
      }
    }
  }
}

.contactInfowrap {
  h4 {
    margin: 15px 0;
  }

  .country-code {
    display: flex;
    align-items: center;

    mat-select {
      width: 50%;
      padding: 15px;
      border: 1px solid #d0d1dd;
      border-radius: 3px;
      margin-right: 10px;
      background: #e9e9e9;
      height: 48px;
    }

    .user-mobile {
      width: 50%;
      padding: 12px;
      border: 1px solid #d0d1dd;
      border-radius: 3px;
      height: 48px;

      input {
        &::placeholder {
          color: black;
        }
      }
    }

  }
}

.createAcount {
  .mat-checkbox-label {
    font-size: 17px;
    font-weight: 500;
  }
}

.room-list-view.staff-list .content-wrapper .button-section .btn:last-child {
  color: #888;
  border-color: #8a8b99;
}

.pl0 {
  padding-left: 0 !important;
}

div.mat-tooltip {
  padding: 12px 20px;
  font-size: 13px;
  position: relative;
}

.mat-checkbox-checked,
.mat-checkbox-indeterminate {

  &.mat-accent,
  &.mat-accent {

    .mat-checkbox-background,
    .mat-checkbox-background {
      background-color: #018bec;
    }
  }
}

.star {
  line-height: 1.6;
}

.star_rating_outer {
  div {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
  }
}

.radio_outer {
  .mat-radio-button {
    min-width: 140px;

    .mat-radio-label-content {
      font-weight: 500;
      font-size: 17px;
    }
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #2e7af7;
}

.mat-radio-button.mat-accent.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: #2e7af7;
  }
}

.block-container {
  div.form-wrapper-block {
    .mat-form-field {
      display: block;
    }
  }
}

.add_more_style {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.uploaded-image {
  position: relative;

  .remove-img {
    z-index: 1;
    width: 18px;
    height: 18px;
    font-size: 13px;
    top: 0;
    right: 0;
    border: 1px solid #fff;
  }
}

.uload-image.custom-upload {
  position: static;
  height: 140px;
  width: 140px;
  right: 43px;
  bottom: 31px;
  cursor: pointer;
  padding: 15px 15px 0 0 !important;
  cursor: pointer;
  z-index: 1;

  .upload {
    border: 1px dashed #888;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 3px;
    line-height: 22px;

    span {
      position: static;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: 14px;
      font-weight: 500;
      text-align: center;

      i {
        background: #fff;
        color: #999;
        padding: 0;
        border-radius: 0;
        border: 3px solid #fff;
        font-size: 24px;
      }
    }

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      display: block !important;
      width: 100% !important;
      height: 100% !important;
      opacity: 0 !important;
    }
  }
}

.mat-primary {
  .mat-option {
    &.mat-selected:not(.mat-option-disabled) {
      color: #000;
    }
  }
}

.checkbox-style {
  //justify-content: space-between;
  display: flex;
  flex-wrap: wrap;

  .mat-checkbox {
    min-width: 140px;
  }

  .mat-checkbox-layout {
    .mat-checkbox-label {
      font-weight: 500;
      font-size: 17px;
    }
  }
}

.title-tab {
  width: 100%;
  background-color: #f2f2f2;
  margin: 20px 0 55px;
  border-radius: 5px;
  min-height: 93px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    background-color: #018bec;

    h4 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  h4 {
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }
}

.mat-calendar {

  .mat-button,
  .mat-icon-button {
    background-color: transparent;
  }
}

.custom-date-calendar {
  .mat-icon-button {
    background-color: transparent;
    font-size: 24px !important;
    color: #888;
  }
}

// .mat-tab-wrapper-custom {
//     .block-container {
//         padding: 50px 0;
//     }
// }
.custom_pdf_name_icon {
  .rotate_icon_attach {
    font-size: 21px;
    opacity: 0.7;
    margin-right: 5px;
  }

  .file-type-img {
    a {
      display: inline-block;
      margin-right: 5px;

      img {
        max-width: 20px;
      }
    }
  }
}

.uploaded-file-pdf {
  border: 1px solid #d9d9d9;
  padding: 10px;
  width: 280px;

  .file-type-img {
    margin-right: 15px;
    max-width: 23px;
    vertical-align: middle;
    display: inline-block;
  }

  span.file-name {
    vertical-align: middle;
    line-height: 1.7;
  }

  i {
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.add-image {
  .mat-dialog-container {
    .modal-body-content {
      .uload-image {
        &.custom-upload-div {
          position: static;
          height: 70px;

          div.upload {
            span {
              i {
                font-size: 24px;
                background: transparent;
                color: #3f4046;
              }
            }
          }
        }
      }
    }
  }
}

.pdf_iframe_dialog {
  width: 100%;
  max-width: 100% !important;

  .material-icons {
    position: absolute;
    right: 31px;
    top: 12px;
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
    height: 39px;
    width: 39px;
    border-radius: 35px;
  }

  app-pdf-upload-modal {
    width: 100%;
    height: 100%;
    display: block;
  }

  .mat-dialog-container {
    transform: none;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  // .add-image {
  //     width: 100% !important;
  //     max-width: 100%;
  //     height: 100vh;
  //     pointer-events: auto;
  // }
}

.top-image-pdf {
  height: 99vh;

  iframe {
    height: 100%;
  }
}

.guide_list_inner {
  .file-type-img {
    display: inline-block;
    margin-top: 10px;

    a {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
      max-width: 22px;
    }
  }
}

.edit_staff {
  .staff_image_wrapper {
    min-width: 160px;
    margin-bottom: 4rem;
  }

  .profile-image {
    width: 100% !important;
  }
}

// app hotel profile
.edit-profile-outer {
  .edit_profile_image {
    display: inline-block;
    width: 220px;
    height: 220px;
    position: relative;

    .profile-image {
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      margin: 0;
      position: static;
      width: 220px;
      border-radius: 50%;
      height: 220px;

      .remove-img {
        display: none;
      }
    }

    .uload-image {
      width: 45px;
      height: 45px;
      float: left;
      margin-top: 5px;
      border: 3px solid #fff;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      overflow: hidden;

      .upload {
        right: 0 !important;
        background: rgba(0, 0, 0, 0.4) !important;
        cursor: pointer;

        span {
          right: 0px !important;

          i {
            padding: 0;
            background: transparent !important;
            position: relative;
            top: 1px;
            color: #fff;
          }
        }
      }
    }
  }
}

.form-wrapper {
  .modal-body-content {
    .label-info {
      margin-top: 25px;
    }
  }
}

.add-staff {
  .staff_image_wrapper {
    div.upload {
      span {
        position: static;
      }
    }
  }
}

.staff_image_wrapper {
  div.upload {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 3px;
    line-height: 22px;

    span {
      position: absolute;
      right: 8px;
      bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;

      i {
        // padding: 15px;
        background: rgba(255, 123, 123, 0.8);
        border-radius: 50%;
        color: #fff;
        border: 0;
        font-size: 20px;
        padding: 8px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    input {
      display: block !important;
      width: 100% !important;
      height: 100% !important;
      opacity: 0 !important;
    }
  }
}

.form-wrapper {
  .mat-form-field-infix small.text-danger+.text-danger {
    bottom: -34px;
  }
}

.detail-room-page {
  .date-time-list {
    span {
      margin-right: 10px;
    }
  }
}

.section-review {
  .section-review-inner {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  ul {
    li {
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/**********************Ckeditor*********************************************/

.ckeditor-custom {
  .ck.ck-content> :first-child {
    margin-top: 15px;
  }

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  i {
    font-style: italic;
  }
}

.ckeditor-front {

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  i {
    font-style: italic;
  }
}

/***Department Css***/

.mat-option-text .staffImgWrap {
  width: 28px;
  height: 25px;
  overflow: hidden;
  display: inline-flex;
  border-radius: 50%;
  background-color: #636262;
  align-items: center;
  justify-content: center;
  margin: 18px 15px 0px 16px;
}

/******Popup Staff css******/

.add-staff-popup {

  // width: 550px !important;
  .mat-dialog-container {
    padding: 0;

    .modal-header {
      padding: 15px;
      border-bottom: 1px solid #ddd;

      h3 {
        float: left;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 26px;
      }

      button {
        float: right;
        background: transparent;
        border: 0;
        cursor: pointer;
      }
    }

    .modal-body-content {
      padding: 30px;

      .uload-image {
        width: 100%;
        position: relative;
        height: 170px;
        border: 1px dashed #ccc;
        margin-bottom: 40px;

        span {
          font-weight: 400;

          small {
            color: #888;
            font-size: 13px;
          }
        }
      }

      .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: #999;
      }

      .show-on-homepage {
        color: #000;
        font-size: 16px;
      }
    }

    .modal-footer-content {
      padding: 0 30px 35px;

      .submit-btn {
        background: #31b544;
        color: #fff;
        cursor: pointer;
      }

      .cancel-btn {
        color: #888;
        font-size: 16px;
        margin-left: 30px;
        cursor: pointer;

        i {
          font-size: 23px;
          position: relative;
          top: 5px;
          margin-right: 6px;
        }
      }
    }
  }
}

// service css
.input-custom-outer {
  &.style-2 {
    .input-custom {
      padding: 0.7rem 0.8rem 0.7rem 0.8rem;
    }
  }

  .input-custom {
    border: 1px solid $gray-300 !important;
    padding-left: 3rem;
    border-radius: 3px;
    padding: 0.7rem 0.8rem 0.7rem 3rem;
    width: 100%;
    max-width: 420px;
    display: inline-block;

    &:hover {
      border-color: $gray-400;
    }

    &:focus {
      border-color: $blue;
    }
  }

  i {
    left: 9px;
    top: 9px;
  }
}

// Edit service
.radio-custom {
  display: flex;
  margin-bottom: 0rem !important;
  width: 100%;

  .mat-radio-button {
    .mat-radio-label {
      height: 100%;
    }

    .mat-radio-container {
      display: none;
    }

    &.mat-radio-checked {
      .mat-radio-label-content {
        background-color: rgb(12, 144, 217);
        background-image: linear-gradient(54deg,
            rgb(12, 144, 217) 0%,
            rgb(42, 126, 247) 50%,
            rgb(55, 117, 245) 69%,
            rgb(67, 108, 243) 100%);
        background-image: -moz-linear-gradient(54deg,
            rgb(12, 144, 217) 0%,
            rgb(42, 126, 247) 50%,
            rgb(55, 117, 245) 69%,
            rgb(67, 108, 243) 100%);
        background-image: -webkit-linear-gradient(54deg,
            rgb(12, 144, 217) 0%,
            rgb(42, 126, 247) 50%,
            rgb(55, 117, 245) 69%,
            rgb(67, 108, 243) 100%);
        background-image: -ms-linear-gradient(54deg,
            rgb(12, 144, 217) 0%,
            rgb(42, 126, 247) 50%,
            rgb(55, 117, 245) 69%,
            rgb(67, 108, 243) 100%);

        h4 {
          color: #fff;
        }

        p {
          color: #fff;
        }
      }
    }

    .mat-radio-label-content {
      // max-width: 500px;
      width: 100%;
      padding: 15px 4rem;
      background-color: #f1f1f1;
      border-radius: 3px;
      min-height: 94px;
      height: 100%;

      p {
        font-size: 13px;
        word-break: break-word;
        white-space: normal;
        line-height: 1.4;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
  }
}

hr {
  border-top: 1px solid $gray-300;
  background-color: $gray-300;
  color: $gray-300;
}

.custom-time {
  mat-label {
    display: inline-block;
    width: 100%;
    color: #171937;
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 0;
    padding: 0.4375em 0;
  }

  .time-input-outer {
    position: relative;
    border-top: 0.54375em solid transparent;

    &:after {
      position: absolute;
      content: "access_time";
      font-family: "Material Icons";
      top: 17px;
      right: 0;
      color: #000;
      font-size: 15px;
    }
  }

  input {
    border-width: 0 0 1px 0;
    width: 100%;
    padding: 0.8em 2rem 0.8rem 0;
    border-color: #c4c4c4;
  }

  .remove-class {
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 29px;
  }
}

.mat-datepicker-toggle {
  .mat-icon-button {
    background: transparent;

    &:hover,
    &:focus,
    &:active:focus {
      background: transparent;
    }
  }
}

.list-thumb-content {
  .secondary-text {
    ol {
      padding-left: 15px !important;
      margin-top: 0;

      li {
        font-size: 13px;
      }
    }
  }
}

.mat-tab-body-content {
  .secondary-text {
    display: inline-block;
    width: 100%;

    ol {
      width: 100%;
      padding-left: 0;

      li {
        display: inline-block;
        width: 100%;
        word-break: break-all;
        line-height: 1.5;
      }
    }
  }
}

.btn-outline-custom {
  border-width: 2px;
}

.add-department-form {
  .mat-form-field {
    min-width: 320px;
    max-width: 580px;
    width: 100%;
    padding-right: 12px;
  }
}

// scroll bar
// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: #e5e5e5;
// }

body::-webkit-scrollbar {
  // width: 6px;
  // height: 6px;
  // background-color: #e5e5e5;
  display: none;
}

// ::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.3);
//   border-radius: 3px;
// }

.message-input .resize-textarea {
  resize: none;
  min-height: auto !important;
  // min-height:18px !important;
}

.row-mr {
  margin: auto !important;
  flex-wrap: nowrap !important;
}

.w-54 {
  width: 54px !important;
}

.msg-width {
  width: calc(100% - 108px);
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.max-width {
  max-width: 100%;
}

.gray-dark {
  color: #555555;
}

.gray-light {
  color: #888 !important;
}

.yellow-color {
  color: #e6a934 !important;
}

.login-btn-bg-color {
  background: #fcf9ee !important;
}

.red-color {
  color: red !important;
}

.red-background {
  background: red !important;

}

.greish-color {
  color: #6b6b6b;
}

.display-block {
  display: block;
}

// padding-bootom0
.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.width-100 {
  width: 100% !important;
}



.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-28 {
  font-size: 28px;
}

.black {
  color: #000000;
}

.cursor-pointer {
  cursor: pointer;
}

.mr-b {
  margin-bottom: 4px;
}

// font weight

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.medium-600 {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-bold-large {
  font-weight: 800;
}

.grey-dark {
  color: #535148;
}

.grey-darker {
  color: #515151;
}

.grey-color {
  color: #707070;
}

.grey-light {
  color: #888888;
}

.grey-300 {
  color: #d0d0d0;
}

.grey-darker-shade {
  color: #6b6b6b;
  white-space: nowrap;
}

.green-color {
  color: $green-dark;
}

.black-color {
  color: #000000;
}

.align-item {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
  // width: 100%; //remove
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.text-underline {
  text-decoration: underline;
}

.contact-left {
  position: relative;

  &:after {
    height: 100%;
    width: 1px;
    content: "";
    background-color: #c8c8c8;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:before {
    background-color: #f9b420;
    width: 3px;
    height: 192px;
    content: "";
    position: absolute;
    left: 0;
    top: 25%;
  }

  .secondary-text {
    line-height: 1.6;
  }

  ul {
    li {
      position: relative;
      padding-left: 50px;
      margin-bottom: 30px;

      i {
        font-size: 35px;
        color: #8d91a4;
        position: absolute;
        left: 0;
        top: 0;
      }

      span {
        font-size: 16px;
        color: #333333;
        font-family: "Nunito";
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 10px;
        display: inline-block;
      }

      a {
        color: #666;
      }
    }
  }
}

// confirm-modal
.confirm-dialog-action {
  .mat-button {
    background-color: transparent;
    border-radius: 0;
    padding-top: 10px;

    &:nth-child(1) {
      border-right: 1px solid $gray-200;
    }
  }
}

// dropdown search and link
.mat-select-panel {
  .mat-option {
    &.search-option {
      display: inline-block;
      height: 6rem;

      .mat-option-pseudo-checkbox {
        display: none;
      }

      .mat-option-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        box-sizing: border-box;
        width: 100%;

        .mat-select-search-inside-mat-option {
          display: inline-block;
          width: 100%;

          .mat-select-search-clear {
            top: 21px;
            right: 165px;
            width: 24px;
            height: 24px;

            .mat-icon {
              font-size: 15px;
              position: relative;
              top: -7px;
            }
          }

          .mat-select-search-input {
            padding: 0;
          }

          .mat-select-search-inner {
            width: 100%;
            display: inline-block;
            left: 0;
            padding: 8px 157px 8px 8px;

            input.mat-select-search-input {
              border: 1px solid #ddd;
              border-radius: 3px;
              padding: 0 37px 0 10px;
            }
          }
        }
      }
    }
  }
}

.btn {
  &.btn-option {
    padding: 0 12px;
    font-size: 14px;
    margin: 0;
    height: 3.2rem;
    width: 130px;
    justify-content: center;
    display: flex;
    position: absolute;
    z-index: 999;
    top: 9px;
    right: -50px;

    .mat-pseudo-checkbox {
      display: none;
    }

    .mat-option-text {
      display: flex;
      align-items: center;
    }

    i.material-icons {
      margin-right: 5px;
      font-size: 20px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

// .gallery-item-list {
//     &:hover {
//         .date-time-list {
//             bottom: -7px;
//             opacity: 1;
//         }
//     }
// }
.date-time-list {
  position: absolute;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  left: 0;
  bottom: -7px;
  // bottom: -75px;
  // opacity: 0;
  padding: 10px;

  &.date-time-list-detail {
    position: static;
    background-color: transparent;
    padding-left: 0;

    span {
      display: block;
      color: inherit;

      &.time-from,
      &.date-from {
        &:before {
          top: -2px;
        }
      }

      &.time-from {
        &:before {
          font-size: 17px;
        }
      }
    }
  }


  span {
    font-size: 13px;
    display: block;
    line-height: 1.6;
    padding-left: 25px;
    position: relative;
    margin-bottom: 5px;
    color: #fff;

    &:before {
      font-family: "Material icons";
      position: absolute;
      color: inherit;
      left: 0;
      top: 0;
      font-size: 15px;
    }

    &.date-from {
      &:before {
        content: "calendar_today";
      }
    }

    &.time-from {
      margin-bottom: 0;

      &:before {
        content: "access_time";
      }
    }
  }
}

.view-list {
  display: flex;

  ul {
    display: flex;

    li {
      a {
        cursor: pointer;
      }

      &:nth-child(1) {
        a {
          i {
            font-size: 26px;
          }
        }
      }

      a {
        color: $gray-600;

        &.active {
          color: $primary;
        }
      }
    }
  }
}

.DeleteService,
.delete-dialog {
  .mat-dialog-container {
    padding-left: 0;
    padding-right: 0;
    width: 470px;

    h2 {
      text-align: center;
    }
  }
}

small.text-danger {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  font-size: 75%;
}

.owl-dt-timer {
  padding: 20px;
  height: 11em;
}

.owl-dt-container-buttons {
  border-top: 1px solid $gray-200;
  border-radius: 0;
  padding: 0;
  height: 3.7em;

  .owl-dt-control {
    color: #000;

    &:nth-child(1) {
      border-right: 1px solid $gray-200;
    }
  }
}

.form-wrapper {
  .mat-form-field-infix {
    .text-danger {
      top: auto;
    }
  }
}

.review-ul {
  li {
    color: #888;
    padding-right: 1rem;
    margin-right: 1rem;
    position: relative;

    &:not(:last-child) {
      &:after {
        content: "";
        height: 17px;
        width: 1px;
        background-color: #888;
        position: absolute;
        top: 3px;
        right: 0;
      }
    }

    p {
      font-size: 14px;
    }
  }
}

.amenities-class,
.dialog-custom {
  .mat-dialog-container {
    padding: 0;

    .modal-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .modal-close {
        border: 0;
        background-color: transparent;
      }
    }

    .modal-footer-content {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.edit-staff-upload {
  .remove-img {
    bottom: 4px !important;
  }

}

.custom-time {
  .custom-date {
    .text-danger {
      bottom: auto !important;
      font-size: 75%;
      top: calc(100% - 0.333337em) !important;
    }

    .mat-form-field-infix {
      border-top: 0;
      padding-top: 3px;

      .mat-input-element {
        font-size: 14px;
        position: relative;
        top: 11px;
      }
    }
  }
}

.description-heading-list {
  position: relative;

  span {
    &:after {
      display: inline-block;
      content: "...";
      position: relative;
    }
  }

  p {
    display: inline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline;
  }
}

.list-view-area-n {
  flex-wrap: wrap !important;

  p.title-info {
    white-space: initial !important;
    text-overflow: inherit !important;
  }
}

.cancel-btn {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
  display: block !important;
  position: relative !important;
}

.w-50 {
  width: 50% !important;
}

.on-off-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;

  .check-slide {
    max-width: 100px;
  }

  h5 {
    font-size: 1.4rem;
    margin: 0 0 0.4rem 0;
  }

  .mat-slide-toggle {
    padding: 0;
  }

  .info {
    width: 100%;
    margin: 0 4rem 0 0;
  }
}

.booking-detail-section {
  position: relative;
  margin: -30px 0 0;
}

.booking-detail {
  background: #fff;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px;
  overflow: hidden;

  .title-area {
    background: #f4f5f9;
    padding: 8px 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 1px #0000001a;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-area {
        width: 34px;
        height: 34px;
        border-radius: 100%;
        margin: 0 10px 0 0;
        background: $blue;
        display: flex;
        justify-content: center;
        align-items: center;

        .material-icons {
          color: $white;
          font-size: 1.285rem;
        }
      }

      h3 {
        color: $black;
        font-size: 1.714rem;
        margin: 0;
        font-weight: 700;
      }
    }

    .right {
      .link {
        text-decoration: underline;
      }
    }
  }

  .booking-info {
    @include flexbox;
    @include justify-content(space-between);
    padding: 20px 0px 5px;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    .info {
      // width: 18%;
      margin: 0 0 15px 0;

      @media screen and (max-width: 767px) {
        width: 50%;
        margin: 0 0rem 15px 0;
      }

      label {
        color: #555555;
        margin: 0 0 3px 0;
        font-size: 1rem;
      }

      p {
        color: $black;
        margin: 0;
        font-size: 1rem;
      }
    }
  }

  .booking-detail-inner {
    position: relative;
    width: 100%;
    padding: 15px 20px;
  }

  .status-block {
    background: #fbf8e5;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    padding: 10px 15px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      @include align-items(flex-start);
    }

    p {
      font-weight: 600;

      @media screen and (max-width: 767px) {
        margin: 0 0 1rem 0;
      }
    }
  }
}

.md-container {
  max-width: 1400px;
  margin: 0px auto;
  padding: 0 20px;
}

.billing-detail {
  background: #e5f4ff;
  margin: 1rem 0;
  position: relative;
  width: 100%;
  display: block;

  .mat-expansion-panel {
    background: #e5f4ff;
    border: 0;
    box-shadow: none;

    .mat-expansion-panel-header {
      padding: 0;
      height: auto !important;

      .mat-expansion-indicator {
        margin-right: 20px;
      }
    }
  }

  .mat-expansion-panel-content {
    border-top: 1px solid #c5dff2;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  h4 {
    font-size: 1rem;
    font-weight: 700;
    color: $black;
    // padding: 15px 15px;
    margin: 0;
  }

  .billing-info {
    @include flexbox;
    @include justify-content(space-between);
    width: 100%;
    position: relative;
    padding: 10px 15px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      @include justify-content(flex-start);
    }

    .left {
      h3 {
        margin: 0 0 0.8rem 0;
      }
    }

    .right-info {
      @include flexbox;
      @include align-items(center);

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        margin: 1.5rem 0 0;
      }

      .list {
        margin: 0 0 1rem 2rem;

        @media screen and (max-width: 767px) {
          margin: 0 2rem 1rem 0rem;
        }

        label {
          font-size: 0.928rem;
          font-weight: 500;
          color: $black;
          margin: 0 0 0.7rem 0;
        }

        p {
          font-size: 0.928rem;
          color: $black;
        }
      }
    }
  }
}

.profile-detail-section {
  position: relative;
  width: 100%;
  margin: 3rem 0;
}

.profile-detail {
  @include flexbox;
  // @include justify-content(space-between);
  width: 100%;
  position: relative;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .left-side {
    width: 330px;
    min-height: 301px;
    background: $white;
    box-shadow: 0px 1px 2px #0000001a;
    border-radius: 5px;
    min-width: 330px;
    padding: 20px;
    position: relative;
    margin: 0 1.5rem 0 0;

    @media screen and (max-width: 767px) {
      min-width: 100%;
      max-width: 100%;
      margin: 0 0 2rem 0;
    }

    h3 {
      font-size: 1.285rem;
      color: $black;
      margin: 0 0 1.5rem 0;
      font-weight: 700;
    }

    .mat-list {
      padding: 0;

      .mat-list-item {
        padding: 1rem 1.5rem;
        margin: 0;
        cursor: pointer;
        background: $white;
        transition: all 0.3s;
        border-radius: 4px;
        height: auto;
        margin: 0 0 0.3rem 0;

        &.active {
          background: $blue;

          .info {
            h4 {
              color: $white;
            }

            p {
              color: $white;
            }
          }
        }

        &:hover {
          background: $blue;

          .info {
            h4 {
              color: $white;
            }

            p {
              color: $white;
            }
          }
        }

        .mat-list-item-content {
          padding: 0;
        }
      }

      .icon {
        width: 35px;
        height: 35px;
        background: transparent linear-gradient(48deg, #fc729c 0%, #ffb098 99%, #ffb098 100%) 0% 0% no-repeat padding-box;
        border-radius: 100%;
        margin: 0 1rem 0 0;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);

        &.person {
          background: transparent linear-gradient(48deg, #fe875d 0%, #ffae88 100%) 0% 0% no-repeat padding-box;
        }

        &.sms {
          background: transparent linear-gradient(48deg, #fc729c 0%, #ffb098 99%, #ffb098 100%) 0% 0% no-repeat padding-box;
        }

        &.local_bar {
          background: transparent linear-gradient(30deg, #50d6ff 0%, #1ab3ff 100%) 0% 0% no-repeat padding-box;
        }

        span {
          font-size: 1.285rem;
          color: $white;
        }
      }

      .info {
        h4 {
          font-size: 1rem;
          font-weight: 700;
          margin: 0 0 0.5rem 0;
        }

        p {
          color: #7f8fa4;
          font-size: 0.928rem;
          margin: 0;
        }
      }
    }
  }

  .right-info {
    width: 100%;
    @include flexbox;
    min-height: 545px;
    background: $white;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 5px;
    padding: 1rem;

    @media screen and (max-width: 567px) {
      flex-direction: column;
    }

    .img-area {
      width: 220px;
      min-width: 220px;
      margin: 0 2rem 2rem 0;

      img {
        width: 100%;
      }
    }

    .info-area {
      width: 100%;

      .user-info-wrapper {
        justify-content: space-between !important;
      }


    }

    h3 {
      color: #060023;
      font-size: 16px;
      margin: 0 0 2rem 0;
      font-weight: 700;
    }

    h4 {
      color: #060023;
      font-size: 1.142rem;
      margin: 0 0 2rem 0;
    }

    .list {
      margin: 0 0 2rem 0;

      p {
        font-size: 1rem;
        margin: 0.3rem 0;
      }

      .address {
        color: #5a607d;
        margin: 1rem 0 0;
      }
    }
  }
}

/* opinder code */

.container-fliud {
  // padding: 40px;
  padding-top: 40px;
}

.serach-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mat-form-field-wrapper {
    padding-bottom: 0px !important;

    .mat-form-field-flex {
      padding-right: 34px;
      padding-top: 0;

      .mat-form-field-infix {
        padding: 1px 0 8px 0;
        border-top: none;
      }
    }


  }

  .mat-form-field {
    width: 52%;
    line-height: 1.825;
    margin-right: 25px;

    .mat-icon {
      position: absolute;
      right: -30px;
      top: 0px;
      color: #4c4c4c;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0px !important;

      .mat-form-field-underline {
        bottom: 0px !important;
      }
    }
  }

  button.btn.btn-primary {
    .mat-icon {
      position: relative;
      top: 1px;
      left: -5px;
      font-size: 20px;
    }
  }
}

.fliter-outer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .btn i {
    position: relative;
    top: 3px;
    font-size: 18px;
    margin-right: 5px;
  }
}

.sm-button {
  padding: 5px 24px;
}

.heading-fliter {
  background-color: #f4f8fb;
  margin-top: 10px;
  padding: 10px 10px 10px 25px;



  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0px !important;

      .mat-form-field-underline {
        bottom: 0px !important;
      }
    }
  }

  .reset-filter {
    min-width: fit-content;
    padding: 5px 15px;
  }

  .apply-filter {
    min-width: fit-content;
    padding: 5px 15px;
  }
}

.fliter-heading {
  display: flex;
  align-items: center;
  height: 100%;

  h4 {
    margin: 0px;
  }
}

.fliter-by-out {
  margin-right: 30px;

  span {
    margin-right: 10px;
  }
}

.fliter-by-form {
  margin-right: 30px;

  span {
    margin-right: 10px;
  }
}

.fliter-web-outer {
  margin-right: 30px;
}

.fliter-status-outer {
  margin-right: 30px;

  span {
    margin-right: 10px;
  }
}

.table-outer {
  margin-top: 10px;
  border: 1px solid #e8eaec;

  table {
    width: 100%;

    tr {
      th {
        background-color: #f5f5f5;
        color: #000;
        font-weight: 600;
        padding: 27px 5px;

        i {
          color: #93a0b2;
          position: relative;
          top: 3px;
          font-size: 14px;
        }
      }
    }

    tr {
      td {
        color: #000;
        padding: 18px 5px;
        border-bottom: 1px solid #f9f9f9;

        i {
          color: #93a0b2;
          position: relative;
          top: 7px;
        }
      }
    }
  }
}

.table-img {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  overflow: hidden;
  background: #ddd;
  margin-right: 2px;
}

.pop-up-outer {
  padding: 45px 45px;

  .block-container {
    .login-form {
      .country-code-wrapper {
        display: flex;

        mat-select {
          width: 40% !important;
          cursor: pointer;
          padding: 18px 10px 18px 10px;
          border-radius: 3px;
          border: 1px solid #e9e9e9;
          margin-right: 5px;
          height: 45px;
          background: #e9e9e9;
        }

        input {
          border: 1px solid #e9e9e9;
          border-radius: 5px;
          padding: 18px;
          height: 10px;
        }

        .booking-phone-error {
          color: red;
          margin-top: 5px;
          display: block;
        }
      }
    }
  }
}

.pop-top-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pop-top-left h2 i {
  position: relative;
  top: 4px;
}

.pop-down-foot {
  display: flex;
  justify-content: flex-end;
}

.pop-up-white {
  padding: 40px 40px;
  background: #f5f5f5;
  padding-bottom: 30px;
  border-radius: 5px;
  margin: 40px 0px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.22);
}

.bg-outer {
  background: #fff;
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  padding: 25px;
  margin-top: 20px;
}

.bd-top-block {
  float: left;
  width: 100%;

  ul {
    float: left;
    width: 100%;

    li {
      float: left;
      width: 14%;
    }
  }
}

p {
  line-height: 22px;
}

.bd-name-outer {
  // border-top: 1px solid #ededed;
  margin-top: 35px;
  padding-top: 25px;
}

.bdr-row {
  border-bottom: 1px solid #ededed;
  margin-bottom: 35px;
  padding-bottom: 35px;

  h4 {
    span {
      font-size: 12px;
      color: #888888;
    }
  }
}

.rbi-total {
  background: #fbf8e5;
  text-align: right;
  padding: 35px 40px;
}

.bdr-row.bdr-row-last {
  border: none !important;
  padding-bottom: 0px;
}

.per-row {
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.select-bg {
  background: #f5f5f5;
  padding: 16px 12px;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
}

.prefrences-tabs {
  &.style-2 {
    width: 100%;

    // .block-container {
    //     box-shadow: none;
    //     padding: 20px;
    //     margin-bottom: 0;
    // }
    .before-stay-content {
      .block-container {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .pref-list {
      ul {
        li {
          border-bottom: 1px solid #e5e5e5;
          padding: 20px 0;
          position: relative;
        }
      }
    }

    .services-checkbox {
      margin: 0 0 0.8rem 0;
      width: 100%;
      display: block;
    }
  }
}

.inn-checkin {
  display: flex;
  flex-direction: column;
  width: 100%;

  .m-inn-checkin {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .value-slide {
    width: 100%;
    margin: 10px 0 0;

    .mat-form-field {
      margin-bottom: 0 !important;
    }
  }
}

.main-content-area-inner {
  padding: 20px 0 0;
  clear: both;
  // width: 100%;
  position: relative;
}

.request-area {
  @include flexbox;
  @include align-items(center);
  align-items: initial;

  .create-area {
    min-width: 400px;
    max-width: 400px;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    flex-direction: column;
    border-right: 1px solid #e8e8e8;
    padding: 2rem;

    @media screen and (max-width: 1200px) {
      min-width: 250px;
      max-width: 250px;
    }

    @media screen and (max-width: 767px) {
      max-width: 100%;
      min-width: 100%;
      border-right: 0px;
      border-bottom: 1px solid #e8e8e8;
    }

    .btn {
      margin: 20px 0 0;
    }
  }
}

.p-0 {
  padding: 0 !important;
}

.recommend-request {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  flex-direction: column;
  padding: 2rem 6rem;
  width: 100%;
  // height: 100%;
  background: url(../../assets/images/recommend-bg.jpg) repeat center center;

  @media screen and (max-width: 1200px) {
    padding: 2rem;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    min-width: 100%;
    padding: 4rem 2rem;
  }

  .request-links {
    width: 100%;
    box-shadow: 0px 1px 2px #0000001c;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    margin: 1rem 0;
    padding: 0 1rem;
  }
}

.mat-list.list-style-1 {
  padding: 15px 7px 0 10px;

  &.style-3 {
    .mat-list-item {
      padding: 16px 16px 16px 24px;
      border-radius: 4px;
      background: #F1F5F9;
      min-height: 98px;
      @include flexbox;
      @include align-items(center);

      &.active {
        background: #f5f5f5;
      }

      &:hover {
        background: #f5f5f5;
      }

      .mat-list-item-content {
        // padding-right: 10rem;
        width: 100%;
      }
    }

    .icon {
      width: 46px;
      min-width: 46px;
      height: 46px;
    }

    .info {
      p {
        margin: 0 0 0.3rem 0;
      }
    }
  }

  .mat-list-item {
    padding: 16px 24px;
    border-radius: 4px;
    cursor: pointer;
    background: $white;
    transition: all 0.3s;
    border-radius: 0px;
    height: auto;
    margin: 0;
    border: 1px solid #dfdfdf;
    margin-bottom: 12px;

    &:last-child {
      border: 0;
    }

    .mat-list-item-content {
      padding: 0;
      // padding-right: 7rem;
      position: relative;
      // word-break: break-word;
    }
  }

  .right-info-status {
    position: absolute;
    right: 0rem;
    top: 0px;
    bottom: 0;
    white-space: nowrap;
    height: 2.5rem;
    margin: auto;
    @include flexbox;
    flex-direction: column;
    align-items: flex-end;

    .date {
      color: $gray-600;
      font-size: 0.8rem;
    }

    .status {
      background: $pink;
      border-radius: 3px;
      height: 18px;
      margin: 5px 0 0;
      font-size: 0.9rem;
      padding: 3px 7px;
      color: $white;
    }
  }

  .icon {
    width: 35px;
    min-width: 35px;
    height: 35px;
    background: transparent linear-gradient(48deg, #fc729c 0%, #ffb098 99%, #ffb098 100%) 0% 0% no-repeat padding-box;
    border-radius: 100%;
    margin: 0 24px 0 0;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);

    &.person {
      background: transparent linear-gradient(48deg, #fe875d 0%, #ffae88 100%) 0% 0% no-repeat padding-box;
    }

    &.sms {
      background: transparent linear-gradient(48deg, #fc729c 0%, #ffb098 99%, #ffb098 100%) 0% 0% no-repeat padding-box;
    }

    &.local_bar {
      background: transparent linear-gradient(30deg, #50d6ff 0%, #1ab3ff 100%) 0% 0% no-repeat padding-box;
    }

    &.dark-blue {
      background: transparent linear-gradient(37deg,
          #07a3f9 0%,
          #2e7af7 50%,
          #3973f5 69%,
          #436cf3 100%) 0% 0% no-repeat padding-box;
    }

    &.green {
      background: #43caa9;
    }

    &.food-color {
      background: #9479da;
    }

    &.blue-color {
      background: #3aadd9;
    }

    &.red-color {
      background: #f76c83;
    }

    span {
      font-size: 1.285rem;
      color: $white;
    }
  }

  .info {
    h4 {
      font-size: 1.1rem;
      font-weight: 700;
      margin: 0;

      @media screen and (max-width: 1200px) {
        font-size: 1rem;
      }
    }

    p {
      color: #000;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    .combination {
      .unread-msg {
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 600;
        border-radius: 4px;
        background-color: #F55F7B;
        padding: 1px 6px 1px 6px;
        color: white;
        margin-left: 12px;
      }
    }

    .date {
      font-size: 14px;
      color: #000;
      margin-top: 10px;
      display: inline-block;
    }
  }

  .right-link {
    position: absolute;
    right: -1.5rem;
    top: 0px;
    bottom: 0;
    white-space: nowrap;
    height: 1rem;
    margin: auto;

    a {
      color: $green;
      @include flexbox;
      @include align-items(center);
      font-size: 1rem;

      &:hover {
        text-decoration: underline;
      }

      span {
        margin: 0 0.6rem 0 0;
        font-size: 1.2rem;
      }
    }
  }
}

.profile-detail.style-2 {
  @include align-items(flex-start);
}

.right-info.messaging-area {
  @include flexbox;
  height: 100%;

  .message-user-list {
    min-width: 34rem;
    max-width: 34rem;
    border-right: 1px solid #dfdfdf;
    height: 100%;

    .mat-tab-group {
      height: 100%;

      .mat-tab-header-pagination-controls-enabled {
        .mat-tab-header-pagination {
          display: none;
        }
      }

      .mat-tab-label-container {
        .mat-tab-list {
          .mat-tab-labels {
            .mat-tab-label-content {
              .counting-req {
                width: 22px;
                height: 22px;
                color: white;
                background-color: #AAAAAA80;
                font-size: 12px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;

                &.active {
                  background-color: #5087E3;
                }
              }
            }

            .mat-ripple-element {
              display: none;
            }
          }
        }
      }
    }


    @media screen and (max-width: 767px) {
      min-width: 100%;
      max-width: 100%;
      border-right: 0;
      height: 100vh;
      border-bottom: 1px solid #dfdfdf;
    }
  }
}

.chat-box-area {
  width: 100%;
  @include flexbox;
  flex-direction: column;
  justify-content: space-between;

  .chat-header {
    @include flexbox;
    @include justify-content(space-between);
    padding: 1rem;
    margin: 0;
    cursor: pointer;
    background: $white;
    transition: all 0.3s;
    border-radius: 0px;
    height: auto;
    margin: 0;
    // background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 0px 4px 0px 0px;
    border-bottom: 1px solid #D9D9D9;
    background: #F1F5F9;

    .mat-button {
      background: $pink;
      border-radius: 3px;
      height: 23px;
      margin: 5px 0 0;
      font-size: 0.9rem;
      padding: 5px 13px;
      color: #fff;
      line-height: inherit;
    }

    .icon {
      width: 46px;
      min-width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent linear-gradient(48deg, #fc729c 0%, #ffb098 99%, #ffb098 100%) 0% 0% no-repeat padding-box;
      border-radius: 100%;
      margin: 0 42px 0 0;

      &.person {
        background: transparent linear-gradient(48deg, #fe875d 0%, #ffae88 100%) 0% 0% no-repeat padding-box;
      }

      &.sms {
        background: transparent linear-gradient(48deg, #fc729c 0%, #ffb098 99%, #ffb098 100%) 0% 0% no-repeat padding-box;
      }

      &.local_bar {
        background: transparent linear-gradient(30deg, #50d6ff 0%, #1ab3ff 100%) 0% 0% no-repeat padding-box;
      }

      &.dark-blue {
        background: transparent linear-gradient(37deg,
            #07a3f9 0%,
            #2e7af7 50%,
            #3973f5 69%,
            #436cf3 100%) 0% 0% no-repeat padding-box;
      }

      &.green {
        background: #43caa9;
      }

      &.food-color {
        background: #9479da;
      }

      &.blue-color {
        background: #3aadd9;
      }

      &.red-color {
        background: #f76c83;
      }

      span {
        font-size: 1.285rem;
        color: $white;
      }
    }

    .info {
      width: 100%;
      padding: 0 20px 0 0;
      flex-direction: column;

      .service-info-internal {
        gap: 10px 0;
        justify-content: space-between;
        width: 100%;
      }

      .assigned-chips {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;

        .assignedStaffChip {
          &:nth-child(2) {
            margin-left: 15px !important;
          }
        }

      }

      .dept {
        margin-left: 20px !important;
      }

      .info-text {
        margin: 0 11px 0 0;
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;

        .date-info {
          font-size: 14px;
        }
      }

      .assignedStaffChip {
        margin: 12px 0;

        .internal-chip-wrapper {
          gap: 16px;
          margin-left: 5px;


          .internal-chip {
            font-size: 12px;
            font-weight: 600;
            font-family: "Nunito";
            padding: 4px 8px;
            border-radius: 4px;
            background: rgba(0, 24, 71, 0.10);
          }
        }
      }

    }

    .right-info-status {
      white-space: nowrap;

      button {
        margin: 0;
      }

      .status {
        background: $pink;
        border-radius: 3px;
        height: 18px;
        margin: 5px 0 0;
        font-size: 0.9rem;
        padding: 3px 7px;
        color: $white;
      }

      .view-order-btn {
        padding: 8px;
        margin-top: 10px;
        border-radius: 4px;
        color: #001847;
        border: 0.5px solid #001847;
        background: rgba(193, 201, 213, 0.02);
      }
    }
  }

  .chat-footer {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    padding: 1rem;

    .material-icons {
      color: #555555;
      cursor: pointer;

      &:hover {
        color: $btn-color;
      }
    }

    .input-area {
      position: relative;
      width: 100%;
      margin: 0 1rem;

      input {
        background: #f5f6f8 0% 0% no-repeat padding-box;
        border: 1px solid #d6dae0;
        border-radius: 23px;
        height: 3rem;
        width: 100%;
        padding: 1rem;
      }

      .face-icon {
        position: absolute;
        top: 0;
        right: 1rem;
        bottom: 0;
        height: 1.8rem;
        margin: auto;
      }
    }

    .send-area {
      position: relative;

      &:hover span {
        color: $btn-color;
      }

      input {
        position: absolute;
        left: 0;
        opacity: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
      }
    }

    .send-area-right {
      position: relative;
      text-align: right;

      &:hover span {
        color: $btn-color;
      }

      input {
        position: absolute;
        left: 0;
        opacity: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
      }
    }

    .uploadAttachment {
      padding: 0;

      .image-viewer-wrapper {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        .image-viewer {
          object-fit: cover;
        }
      }
    }

    .message-input {
      .uploadation-wrapper {
        .upload-document {
          .mat-chip-list {
            .mat-chip-list-wrapper {
              .mat-chip {
                width: 170px;
                display: flex;
                align-items: center;

                .upload-text {
                  width: 160px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  font-size: 10px;

                }
              }
            }
          }
        }
      }
    }
  }

  .chat-middle-area {
    overflow: auto;
    height: 100%;
    padding: 1rem;
    background: #FAFAFA;

    .chatmsg {
      @include flexbox;
      align-items: flex-end;
      margin: 2rem 0;

      &.outgoing {
        flex-direction: row-reverse;

        .img {
          margin: 0 0rem 0 0.6rem;
        }

        .msg-info {
          span {
            background: #fff;
            padding: 12px 24px;
            color: $black;
            font-size: 14px;
            font-family: "Nunito";
            margin-bottom: 10px;
            border-radius: 5px;


            .sent-image {
              max-width: 300px;
              max-height: 200px;
              height: 200px;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .date {
            text-align: right;
          }
        }
      }

      .img {
        width: 26px;
        min-width: 26px;
        height: 26px;
        border-radius: 100%;
        overflow: hidden;
        margin: 0 0.6rem 0 0;

        img {
          width: 100%;
          height: 100%;
        }
      }



      .msg-info {
        max-width: 100%;
        position: relative;

// &.pdf-msg-info {
//   span {
//     background: transparent;
//   }
// }

        span {

          line-height: normal;
          @include flexbox;
          @include align-items(center);
          position: relative;
          background: #fff;
          padding: 12px 35px;
          color: $black;
          font-size: 14px;
          font-family: "Nunito";
          margin-bottom: 10px;
          border-radius: 5px;
        }

        .sent-image {
          max-width: 300px;
          max-height: 200px;
          height: 200px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .date {
          color: #858585;
          font-size: 0.8rem;
          display: block;
          margin: 0.6rem 0 0;
        }


      }
    }
  }
}

.main-full-img {
  width: 100%;
  position: relative;
  margin: 3rem 0 2rem 0;

  img {
    width: 100%;
    border-radius: 6px;
  }
}

.title-with-rating {
  position: relative;
  width: 100%;
  margin: 1.5rem 0;

  h2 {
    margin: 0.5rem 0;
  }

  .rating-text {
    @include flexbox;
    @include align-items(center);

    .material-icons {
      font-size: 1rem;
      color: $gray-500;

      &.ok {
        color: $yellow;
      }
    }

    .text-secondary {
      font-size: 0.9rem;
      margin: 0 0 0 0.5rem;
    }
  }
}

.booking-detail-info {
  margin: 2rem 0;

  h3 {
    margin: 0 0 1rem 0;
  }

  .table-area {
    border: 1px solid #dadde0;
    padding: 1rem;
    margin: 1rem 0;
    overflow: auto;

    table {
      width: 100%;

      td {
        padding: 1rem;
        border-bottom: 1px solid $gray-300;
      }
    }
  }
}

.full-container {
  // padding: 0 2rem;
  width: 100%;
  position: relative;
}

.info-boxes {
  @include flexbox;
  margin: 0 -15px;
  margin-bottom: 2rem;

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 2rem 0;
  }

  .inner {
    background: $white;
    box-shadow: 0px 1px 2px #00000008;
    border: 1px solid #e9ebef;
    border-radius: 2px;
    padding: 1rem;
    width: calc(25% - 30px);
    margin: 0 15px;

    @media screen and (max-width: 991px) {
      width: calc(50% - 30px);
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0 0 1rem 0;
    }

    p {
      color: #354052;
      font-size: 1.1rem;
      margin: 0 0 0.5rem 0;
    }

    .bottom-info {
      @include flexbox;
      @include justify-content(space-between);

      span {
        font-size: 2rem;
      }
    }

    h2 {
      font-weight: 900;
      margin-bottom: 0 !important;

      img {
        display: inline;
        margin: 0 0 0 0.6rem;
      }
    }
  }
}

.page-main-title {
  font-weight: 400;
  display: block;
  margin: 1rem 0 1rem 0;

  strong {
    font-weight: 700;
  }
}

.text-orange {
  color: $orange !important;
}

.table-outer.table-style-2 {
  border: 0;

  .table-main {
    overflow: auto;
    width: 100%;
    position: relative;
  }

  .mat-paginator {
    background: transparent;

    .mat-paginator-outer-container {
      .mat-paginator-container {
        justify-content: flex-end;

        button {
          background: $white;
          border: 0;

          &:disabled {
            opacity: 0.6;
          }
        }

        .mat-paginator-icon {
          fill: #333;
          width: 20px;
        }
      }
    }
  }

  table.mat-table {
    border: 1px solid #e8eaec;
    margin-bottom: 0.5rem;
    box-shadow: 0px 1px 3px #00000017;

    tr.mat-header-row {
      height: 3rem;
    }

    .mat-header-cell {
      background: #f8fbff;
      padding-top: 18px;
      padding-bottom: 18px;
      white-space: nowrap;
    }

    .mat-cell {
      vertical-align: middle;

      @media screen and (max-width: 991px) {
        white-space: nowrap;
      }
    }

    .mat-header-cell[aria-sort="descending"] {
      .mat-sort-header-arrow {
        &:before {
          transform: rotate(-180deg);
        }
      }
    }

    .mat-sort-header-arrow {
      opacity: 1 !important;
      transform: inherit !important;
      height: 6px;

      &:before {
        content: "arrow_drop_down";
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        // display: none;
      }

      .mat-sort-header-stem {
        display: none;
      }

      .mat-sort-header-indicator {
        display: none;
      }
    }
  }
}

.td-user-info {
  @include flexbox;
  @include align-items(center);

  .img-area {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 0.8rem 0 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.action-td {
  .mat-button {
    padding: 0;
    width: auto;
    background: none;
    min-width: inherit;

    span {
      color: #7f8fa4;
      font-size: 1.6rem;
    }
  }
}

.td-status {
  @include flexbox;
  @include align-items(center);
  white-space: nowrap;

  .material-icons {
    font-size: 0.8rem;
    margin: 0 0.5rem 0 0;
  }
}

.calender-with-title {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;
  position: relative;
  margin: 0 0 0.5rem 0;

  .date-info {
    background: $white;
    border: 1px solid $primary;
    border-radius: 2px;
    padding: 0.5rem 1rem;
    height: auto;
    @include flexbox;
    @include align-items(center);

    span {
      color: $primary;
      font-size: 1rem;
    }

    .material-icons {
      font-size: 1.6rem;
      margin: 0 0 0 0.5rem;
    }
  }
}

.search-filter-title {
  h4 {
    margin-bottom: 0 !important;
  }

  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;
  position: relative;
  margin: 0 0 0.5rem 0;

  .search-right {
    @include flexbox;
    @include align-items(flex-end);

    .search-input {
      position: relative;

      span {
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        font-size: 1.5rem;
        color: #7f8fa4;
      }

      input {
        background: $white;
        border: 1px solid #e4e0e0;
        border-radius: 2px;
        padding: 3px 10px 3px 35px;
        height: 36px;
      }
    }

    .filter-btn {
      margin: 0 0 0 1rem;

      button {
        height: 36px;
        padding: 5px 20px;

        span {
          font-size: 1.6rem;
        }
      }
    }
  }
}

.dashboard-filter {
  position: fixed !important;
  right: 0;
  top: 0;
  bottom: 0;

  .popup-header {
    background-image: -moz-linear-gradient(11deg,
        rgb(7, 163, 249) 0%,
        rgb(46, 122, 247) 50%,
        rgb(57, 115, 245) 69%,
        rgb(67, 108, 243) 100%);
    background-image: -webkit-linear-gradient(11deg,
        rgb(7, 163, 249) 0%,
        rgb(46, 122, 247) 50%,
        rgb(57, 115, 245) 69%,
        rgb(67, 108, 243) 100%);
    background-image: -ms-linear-gradient(11deg,
        rgb(7, 163, 249) 0%,
        rgb(46, 122, 247) 50%,
        rgb(57, 115, 245) 69%,
        rgb(67, 108, 243) 100%);
    background-image: linear-gradient(11deg,
        rgb(7, 163, 249) 0%,
        rgb(46, 122, 247) 50%,
        rgb(57, 115, 245) 69%,
        rgb(67, 108, 243) 100%);
    display: flex;
    color: #fff;
    padding: 22px 25px;

    h1 {
      width: 100%;
      color: #fff;
      margin: 0;
    }

    button {
      color: $white;
      border: 0;
      background: transparent;
    }
  }

  .main-content-area-form {
    padding: 30px 20px;

    .col-sm-12 {
      margin-bottom: 20px;
    }

    label {
      width: 100%;
      font-weight: 600;
      margin-bottom: 8px;
    }

    select,
    input {
      width: 100%;
      padding: 10px;
    }

    h4 {
      margin: 40px 0 20px;
    }
  }

  .pop-up-footer {
    padding: 0 30px;
  }
}

.manage-request-wrapper {
  padding: 80px 40px 0;

  .page-title {
    i {
      margin-right: 10px;
      font-size: 33px;
      position: relative;
      top: 6px;
    }
  }

  .block-title {
    position: relative;

    span {
      position: absolute;
      padding: 7px 20px;
      background: #f5bc50;
      color: #fff;
      right: 0;
      top: 0;
      width: 112px;
      height: 26px;
      line-height: 14px;
      border-radius: 3px;
    }

    ul {
      margin-top: 10px;
      flex-wrap: wrap;
    }
  }

  .activity-item {
    padding-left: 46px;
    border-left: 2px solid #ddd;
    padding-bottom: 30px;
    position: relative;

    &:before {
      display: inline-block;
      content: "";
      width: 23px;
      height: 23px;
      background: #ddd;
      border: 3px solid #fff;
      border-radius: 20px;
      position: absolute;
      top: 0;
      left: -11px;
    }

    &:last-child {
      border-left: 0;
    }
  }

  .send-message-block {
    border-top: 1px solid #ddd;
    margin-top: 30px;
    padding-top: 30px;

    p {
      font-weight: 700;
      font-size: 16px;
    }

    textarea {
      width: 100%;
      height: 100px;
      padding: 20px;
      border-color: #959595;
      margin: 15px 0;
    }

    .btn-link {
      color: #888888;
    }
  }
}

.dashboard-item-control {
  i {
    font-size: 20px;
    margin-right: 14px;
    position: relative;
    top: 6px;
  }
}

.mian-container-wrapper {
  padding: 50px 20px 70px;
  max-width: 1400px;
  margin: 0 auto;

  .uploadedimg-wrapper {
    background: #000;
    border: 5px solid #d0d1dd;
    width: 135px;
    height: 135px;
    border-radius: 200px;

    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;

    .upload-img-wrapper {
      width: 50px;
      height: 50px;
      background-image: linear-gradient(54deg,
          #0c90d9 0%,
          #2a7ef7 50%,
          #3775f5 69%,
          #436cf3 100%);
      background-image: -ms-linear-gradient(54deg,
          #0c90d9 0%,
          #2a7ef7 50%,
          #3775f5 69%,
          #436cf3 100%);
      border-radius: 50px;
      position: absolute;
      right: 0;
      bottom: -4px;

      .uload-image {
        right: inherit;
        bottom: inherit;
        color: #fff;
        left: 4px;
        top: 5px;
      }
    }

  }
}

.profileInfoWrapper {
  align-items: center;

  .profileName {
    margin: 0 40px;
    width: 70%;
  }
}

.contactGridItems {
  display: flex;
  align-items: center;

  i {
    margin-right: 15px;
  }
}

.booking-info-wrapper {
  background-color: $white;
  padding: 25px;

  h4 {
    margin-bottom: 20px !important;
  }

  .booking-info {

    mat-accordion {
      margin-bottom: 8px;
      display: block;
      border: 1px solid #FFF;

      mat-expansion-panel {
        background: #F6F6F6;
        box-shadow: none;

        mat-expansion-panel-header {
          padding: 16px 18px;

          mat-panel-title {
            font-size: 16px;
            font-weight: 400;
            color: $black;
            display: flex;
            align-items: center;

            &::before {
              content: "";
              width: 6px;
              height: 6px;
              display: inline-block;
              margin-right: 8px;
              background: $black;
              border-radius: 50%;
            }
          }
        }

        .mat-expansion-panel-body {
          overflow: auto;

          .booking-expansion-table {
            width: 100%;
            box-shadow: none;

            thead {
              th {
                padding: 10px 32px;
                font-size: 16px;
                font-weight: 600;
                font-family: "Nunito";
                color: black;
                background: #F1F0F0;
                vertical-align: middle;

                &:nth-child(1) {
                  text-align: left;
                  padding-left: 50px;
                }
              }
            }


            .example-element-row {
              width: 100%;
              height: fit-content;
              margin-bottom: 8px;


              td {
                padding: 10px 32px;
                vertical-align: middle;

                .details-button {
                  color: #008BEC;
                  width: auto;
                  height: auto;
                  line-height: 0;
                  font-size: 14px;
                  font-weight: 400;
                  font-family: "Nunito";

                }

                .paid {
                  color: #49CA6D;
                  display: flex;
                  align-items: center;

                  &::before {
                    content: "";
                    height: 9px;
                    display: inline-block;
                    width: 9px;
                    margin-right: 5px;
                    border-radius: 50%;
                    overflow: hidden;
                    background: #49CA6D;
                  }
                }

                .unpaid {
                  color: red;
                  display: flex;
                  align-items: center;

                  &::before {
                    content: "";
                    height: 9px;
                    display: inline-block;
                    width: 9px;
                    margin-right: 5px;
                    border-radius: 50%;
                    overflow: hidden;
                    background: red;
                  }
                }

                &:nth-child(1) {
                  text-align: left;
                  padding-left: 53px;
                }
              }
            }


            .internal-booking-table {
              th {
                padding: 10px 32px;
                background: #F6F6F6;
                text-align: center;
              }

              td {
                height: 48px;
                padding: 0 32px;
                vertical-align: middle;
                text-align: center;
              }
            }
          }


        }


      }
    }
  }
}

.booking-mobile-design {
  display: none !important;
}

.contactGridItems {
  .mat-radio-button {
    width: 100%;
    padding: 20px 0;

    .mat-radio-label {
      white-space: inherit;
    }

    small {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px !important;
      display: inherit;
    }

    .mat-radio-label-content {
      padding-left: 18px;
    }
  }
}

.custom-time {
  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field {
    margin-bottom: 0px !important;
  }

  .alert {
    margin-bottom: 20px;
  }

  .time-wrapper .alert {
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

.uploadAttachment {
  .profile-image {
    width: 50px !important;
  }

  i.remove-img {
    right: -3px !important;
  }
}

.admin-message-container {
  padding: 20px 0;
  height: calc(100vh - 67px);
}

.all-messages {
  margin: 0 auto;
  border: 1px solid #ddd;
  background: #fff;
  height: 100%;


  .mat-add {
    font-size: 24px;
    color: #7f7b7b;
    margin: 0 10px;
  }
}

.searchSection {
  padding: 10px 15px;
  position: relative;

  input {
    padding: 10px;
    border: 1px solid #ddd;
    width: 100%;
  }

  i {
    position: absolute;
    top: 18px;
    right: 25px;
    color: #ccc;
  }
}

.search-result-section {
  padding: 0 15px 20px;
  margin-top: -12px;
  position: absolute;
  z-index: 99;

  ul {
    box-shadow: 0 5px 18px #cacaca;
    background: #fff;
    height: 400px;
    overflow: auto;

    li {
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid #f5f5f5;

      &:hover {
        background: #eee;
      }
    }
  }
}

// <----------Customize_is Css--------->
.custom-is-ch_a img {
  object-fit: cover;
}

.custom-is-ch_b {
  margin-top: 45px;
}

.custom-is-ch_c {
  font-size: 10.5px !important;
}

.custom-is-ch_d {
  position: absolute;
  height: 100% !important;
}

.custom-is-ch_d img {
  width: 100% !important;
  height: 100% !important;
}

.edit-profile-outer .edit_profile_image .custom-is-ch_e .remove-img {
  display: inherit;
  right: 7px;
  bottom: 0px;
  top: initial;
}

// <-------Overlay Effect------->
.open .overlay-bg-sidebar {
  display: block;
}

.overlay-bg-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  z-index: -2;
  display: none;
}

.mat-list-item {
  cursor: pointer;
}



.message_is {
  width: 100%;
}

.mat-list.list-style-1 .message_is .right-info-status {
  position: relative;
  right: auto;
  top: auto;
  height: 0;
  margin: inherit;
  height: 100%;
}

.mat-list.list-style-1 .message_is .info h4 {
  margin: 0 6px 7px 0px !important;
  font-size: 1rem !important;
}

// <------\\Customize_is Css--------->

// payment-card

.payment-card-section {
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  .mat-card {
    padding: 27px 30px;
  }

  .payment-card {
    width: 300px;
    margin: 0 25px 25px 0;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }

    .payment-setting {
      padding-top: 10px 0;

      button {
        outline: none;
        border: none;
        background: none;
        min-width: 20px;
        padding: 0;

        .mat-button-focus-overlay {
          background: none;
        }
      }
    }

    .payment-images {
      margin: 25px 0;

      .payment-image-wrapper {
        width: 50px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .mat-button-ripple .mat-ripple {
        display: none;
      }

      .connect-btn {
        outline: none;
        border: none;
        background: none;

        .check-icon {
          background: $green-dark;
          border-radius: 50%;
          color: #fff;
          padding: 2px;
        }

        .mat-button-focus-overlay {
          background: none;
        }
      }

      //button for connected account
      button.connect-btn.connected.mat-button {
        border: 1px solid grey !important;
        border-radius: 5px;

        .check-icon {
          display: none;
        }

        .green-color {
          color: grey;
        }
      }
    }

    .payment-detail {
      p {
        padding-bottom: 6px;
      }
    }
  }
}

// stripe-payment-card-poppup
.payment-poppup {
  mat-card {
    padding: 0px;
  }

  .payment-card {
    width: 595px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .payment-header-stripe {
      position: relative;
      background: #4e26e0;
      border-radius: 4px;
      padding: 5px 20px 30px 20px;

      button {
        background: #4e26e0;
        position: absolute;
        right: 0px;
        top: 10px;

        .mat-button-focus-overlay {
          background: none;
        }

        i {
          color: #fff;
        }
      }

      .payment-heading {
        font-size: 28px;
        color: #fff;
        margin: 20px 0 9px;
      }

      .payment-desc {
        font-size: 15px;
        color: #fff;
      }
    }

    .payment-header-paypal {
      position: relative;
      background: #3796db;
      border-radius: 4px;
      padding: 5px 20px 30px 20px;

      button {
        background: #3796db;
        position: absolute;
        right: 0px;
        top: 10px;

        .mat-button-focus-overlay {
          background: none;
        }

        i {
          color: #fff;
        }
      }

      .payment-heading {
        font-size: 28px;
        color: #fff;
        margin: 20px 0 9px;
      }

      .payment-desc {
        font-size: 15px;
        color: #fff;
      }
    }
  }

  .payment-body {
    padding: 30px 50px;

    .payment-button {
      .mat-button-focus-overlay {
        background: none;
      }

      .close-btn {
        background: #74c8db;
        padding: 12px 35px;

        .mat-button-wrapper {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

// -----billing--table-------
.billing-data-table {
  width: 100% !important;
}

.billing-detail {
  .bill-info {
    padding: 15px 15px;
    border-bottom: 1px solid #bfd7e9;
  }
}

// billing history
.billing-history-header {
  width: 100%;
  background: #fff8e3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  padding: 18px 36px;

  .billing-header-logo {
    background: transparent;
    border-radius: 5px;
    padding: 8px;
    height: 80px;
    width: auto;
  }

  .right-contents {
    button {
      background: #535148;

      .mat-button-focus-overlay {
        background: none;
      }

      i {
        color: #e4dbdb;
      }
    }
  }
}

.billing-history-data {
  span.mat-expansion-indicator.ng-trigger.ng-trigger-indicatorRotate.ng-star-inserted {
    display: none;
  }

  mat-expansion-panel {
    margin-bottom: 16px;

    &.mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing {
      margin-bottom: 20px;
    }

    mat-panel-description.mat-svg.d-flex.mat-expansion-panel-header-description {
      justify-content: end;
    }
  }

  table {
    width: 100%;

    td,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type {
      padding: 15px 31px 19px 23px;
    }

    th {
      border-style: none;
      color: $black;
    }

    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type {
      padding-right: 24px;
      text-align: end;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background: #f3f3f3;
    }
  }
}

.billing-amounts {
  // max-width:1036px;
  width: 100%;
  background: #fff8e3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 22px 26px;
  color: $black;
}

.pay-buttons {
  justify-content: flex-end;
  margin-top: 54px;

  .mat-button-focus-overlay {
    background: none;
  }

  .btn-1 {
    background: #535148;
    border-radius: 5px;
    color: $white;
    padding: 9px 26px;
  }

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
}

// biiling-by-poppup
.billing-pay-methods {
  max-width: 653px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  padding: 25px;
  margin-top: 30px; //remove

  .mat-accordion {

    .billing-by-expansion {
      background: #F6F6F6;
      margin-bottom: 8px;
      box-shadow: none;

      mat-expansion-panel-header {
        mat-panel-title.mat-expansion-panel-header-title {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }

      .mat-expansion-panel-content {
        padding: 0 17px;

        .mat-expansion-panel-body {
          padding: 0;

          .billing-by-table {
            border-spacing: 0 8px;
            box-shadow: none;
            border-collapse: separate;
            border-spacing: 0 8px;
            background: inherit;

            thead {
              tr {
                height: 31px;
                background: #ECEBEB;

                th {
                  vertical-align: middle;
                  border-bottom-width: 0;
                  color: $black;
                  font-weight: 500;

                  &:nth-child(1) {
                    width: 10%;
                  }

                  &:nth-child(2) {
                    width: 35%;
                  }

                  &:nth-child(3) {
                    width: 30%;
                  }

                  &:nth-child(4) {
                    text-align: center;
                    width: 25%;
                  }
                }
              }
            }

            tbody {
              tr {
                height: 31px;
                background: $white;

                td {
                  vertical-align: middle;
                  border-bottom-width: 0;


                  &:nth-child(4) {
                    text-align: center;
                    width: 30%;
                    height: 31px;
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  .select-item-text {
    font-weight: 800;
    margin: 16px 0 6px;
  }

  .selected-items {
    padding: 15px 17px 14px;
    border-radius: 3px;
    border: 1px solid #E9E9E9;
    background: #FFF;
    width: 100%;
    justify-content: space-between;

    .item-listing-wrapper {
      .item-text {
        margin-right: 6px;
      }

      ul {
        .selected-list-item {
          margin-right: 10px;
          list-style: disc;
          margin-left: 20px;
        }
      }
    }

    .listing-price-wrapper {
      .list-price {
        margin-right: 29px;
      }
    }

  }

  textarea {
    width: 100%;
    border: 1px solid #d7d7d7;
    min-height: 201px;
    padding: 13px;
    color: $black;
    opacity: 0.5;
  }
}

.payment-option {
  max-width: 385px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  margin-top: 30px; //remove
  padding: 26px;
  background: #f8f8f8;

  .option-text {
    padding: 13px 0;
    border-bottom: 1px solid #D9D9D9;
  }

  .payment-optionssss {
    width: 100%;
    border-radius: 5px;
    margin-top: 13px;
    cursor: pointer;
    align-items: center;

    &:hover {
      box-shadow: 0px 2px 8px #d9d9d9;
      padding: 6px 10px;
      transform: scaleX(1.04);
      transition: 0.3s ease-in-out;
    }

    .pay-images {
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }

    .pay-images-disable {
      width: 28px;
      height: 28px;
      margin-right: 12px;
      opacity: 0.5;
    }
  }
}

// .booking-detail

.booking-detail {
  padding: 25px;

  .services {
    // border-bottom: 1px solid #e5e5e5;
    padding: 20px 0;
    position: relative;

    .desc {
      color: $black;
    }

    .delivered-button {
      background: $white;
      color: $green-dark;
      width: 138px;

      &:hover {
        background: $white;
      }

      .mat-button-focus-overlay {
        background: none;
      }
    }

    .paid-button {
      background: rgba($green-dark, 0.1);
      color: $green-dark;
      border: none;
      padding: 3px 15px;
      width: 150px;
      // opacity:0.1;

      &:hover {
        background: rgba($green-dark, 0.1);
      }

      .mat-button-focus-overlay {
        background: none;
      }
    }

    .pending-button {
      background: #fde7be;
      color: $yellow-dark;
      border: none;
      padding: 3px 15px;
      width: 150px;
      // opacity:0.1;

      &:hover {
        background: #fde7be;
      }

      .mat-button-focus-overlay {
        background: none;
      }
    }
  }

  .delivered-button {
    background: $white;
    color: $green-dark;

    &:hover {
      background: $white;
    }

    .mat-button-focus-overlay {
      background: none;
    }
  }

  .paid-button {
    background: rgba($green-dark, 0.1);
    color: $green-dark;
    // opacity:0.1;

    &:hover {
      background: rgba($green-dark, 0.1);
    }

    .mat-button-focus-overlay {
      background: none;
    }
  }
}

.billing-amount {
  width: 100%;
  background: #fff8e3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 22px 26px;
  color: $black;
  flex-direction: column;
  align-items: flex-end;
}

.guest-checkout-info {
  width: 100%;
  padding: 0 18px 0 0;
}

// Add-room-individual-poppup
.individual {
  .remove {
    background: $red;
    border-radius: 50%;
    color: $white;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .add {
    background: $green;
    border-radius: 50%;
    color: $white;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.submit-poppup {
  flex-wrap: wrap;

  .check-list-btn {
    width: auto;
    line-height: 39px;
    padding: 0 23px;
    background: #ececec;
    color: #888;
    border: 0;
    margin: 9px 2px 8px 11px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;

    i {
      font-size: 20px;
      color: $white;
      position: relative;
      top: 5px;
      margin-right: 5px;
      background: #707070;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .submt-buton {
    border: 2px dotted #b7b7b7;
    border-radius: 5px;
    padding: 5px 5px 5px 17px;
    margin: 10px;
  }
}

// Login-popup
.validation-error {
  display: block;
  margin: 15px 0;
}

.validation-error-cart {
  display: block;
  margin-bottom: 15px;
}


.Login-popup {
  max-width: 931px;
  width: 100%;
  max-height: calc(100vh - 29px);
  overflow-y: hidden;
  background: $white;
  box-shadow: 0px 2px 8px #d9d9d9;
  align-items: stretch;

  .left-contents {
    padding: 45px 70px;
    width: calc(100% - 435px);
    background-color: white;
    overflow: auto;

    .top-div {
      display: none;
      justify-content: center;
      position: relative;
      border-radius: 8px;

      mat-icon {
        position: absolute;
        top: -34px;
        right: -29px;
        background: #ebebeb;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        color: #535148;
        cursor: pointer;
        font-size: 36px;
        align-items: center;
        display: flex;
        justify-content: center;
        display: none;
      }
    }

    .poppup-logo {
      display: none;

      .logo {
        margin: 0 auto 65px auto;
        width: 147px;
      }
    }

    .login-text {
      display: none;

      p {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        margin-top: 8px;
      }
    }

    .heading {
      font-size: 30px;
    }

    .mat-form-field {
      width: 100%;

      .mat-form-field-wrapper {
        padding-bottom: 0 !important;
      }
    }

    .country-code {
      display: flex;
      align-items: center;
      justify-content: space-between;


      mat-select {
        width: 40% !important;
        cursor: pointer;
        padding: 18px 10px 18px 10px;
        border-radius: 3px;
        border: 1px solid #e9e9e9;
        margin-right: 5px;
        height: 54px;
        background: #e9e9e9;
      }

      input {
        width: 60%;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        padding: 18px;
        height: 54px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }


        &::placeholder {
          color: black;
          font-family: "Helvetica Neue", sans-serif, ;
          opacity: 0.9;
        }

        &:hover {
          border: 2px solid black;
        }

        &:focus {
          border: 2px solid #018BEC;
        }

      }
    }

    //   .mat-form-field-appearance-outline .mat-form-field-wrapper {
    //     margin: -0.75em 0;
    // }

    .login-button {
      .mat-button-focus-overlay {
        background: none;
      }

      .mat-raised-button {
        box-shadow: none;
      }

      button {
        width: 100%;
        height: 48px;
        background: #535042;
        border-radius: 8px;
        color: #ffff;
      }
    }

    .create-account-btn {
      cursor: pointer;

      .mat-button-focus-overlay {
        background: none;
      }

      button {
        background: $white;
      }
    }

    .horizontal-lines {
      justify-content: space-evenly;
      margin-top: 25px;

      hr {
        width: 126px;
        color: #d0d0d0;
        background-color: #d0d0d0 !important;
        height: 1px;
        border: none;
      }

      span {
        color: #d0d0d0;
        padding: 10px 35px;
        font-size: 14px !important;
      }
    }

    .checkbox {
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #535148;
      letter-spacing: 0.0125em;
      margin-top: 25px;

      .mat-checkbox-label {
        padding-top: 2px;
      }

      .border-btm {
        border-bottom: 2px solid #d0d0d0;
      }
    }

    .social-media {
      display: none;

      .social-icon {
        display: none;
        width: 100%;
        height: 47px;
        border: 1px solid #d0d0d0;
        border-radius: 4px;

        a {
          color: #505050;
        }
      }
    }

    .logged-in {
      display: none;

      .login-account {
        color: #515151;
      }
    }
  }

  div#cdk-overlay-1 {
    //outer div of dialog box
    max-height: calc(100vh - 29px);
  }

  .right-contents {
    // height: 100%;
    background: #535042;
    width: 435px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // width:100%;
    // border-radius: 0 14px 14px 0;

    .top-div {
      justify-content: center;
      position: relative;
      border-radius: 8px;

      mat-icon {
        position: absolute;
        top: 12px;
        right: 14px;
        background: #b5b4b0;
        border-radius: 50%;
        color: #535148;
        cursor: pointer;
      }
    }

    p {
      font-weight: 700;
      font-size: 26px;
      letter-spacing: 0.0125em;
      color: $white;
      width: 345px;
      line-height: 140%;
      padding: 45px 0 8px 0;
    }

    .bottom-div {
      height: 400px;
      margin: 0 auto;

      img {
        height: 100%;
      }
    }
  }
}

// new screen which will open after clicking on book now button

.search-page-header {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  background: #fff;
  min-height: 121px;
  height: 100%;
  margin-bottom: 30px; //remove

  span {
    display: block;
  }

  .mat-form-field {
    width: 380px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 49px;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0px;
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 10px;
  }

  .search-btn {
    button {
      background: #34a853;
      margin-top: 17px;
      min-width: 204px;
      width: 100%;
      height: 49px;
      font-weight: 500;
      font-size: 20px;
    }
  }
}

.search-results {
  .left {
    font-weight: 700;
    font-size: 24px;
    color: #535148;
  }

  .right {
    .mat-button-focus-overlay {
      background: none;
    }

    button {
      background: $white;
      color: #535148;
    }
  }
}

// Rooms section
.available-rooms {
  // width:100%;
  // height:100%;
  border: 1px solid #ededed;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px; //remove
  background: #ffff;

  .room-img-left {
    width: 70%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .room-img-right {
    width: 30%;
    width: calc(30% - 2px);
    margin-left: 2px;

    .img-right-top {
      height: 50%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .img-right-bottom {
      height: calc(50% - 1px);
      margin-top: 2px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}

.short-view {
  flex-direction: column;

  img {
    height: 100%;
  }
}

.room-desc {
  padding: 35px 35px 35px 30px;

  .star-icon {
    color: #fbbc05;
  }

  .entire-appartment {
    padding: 5px 10px 5px 0;
    border-right: 1px solid #c4c4c4;
  }

  .star {
    padding: 5px 10px;
  }

  button {
    background: #f3f3f3;
    border-radius: 50px;
    color: #000000;
    max-width: fit-content;
    width: 100%;
    line-height: 30px;
    margin-top: 14px;
  }

  .place-offer {
    margin-top: 16px;
    width: 50%;
  }

  .about-room-img {
    display: none;
    max-width: 87px;
    min-width: 80px;

    img {
      height: 117px;
    }
  }
}

.room-prize {
  padding: 18px 38px;
  border-left: 1px solid #eeeeee;

  .cancellation-button {
    justify-content: flex-end;

    mat-icon {
      color: #fbbc05;
    }

    button {
      background: #fff8ed;
      color: #a66609;
      height: 25px;
      min-width: 198px;
      border-radius: 30px;
      border: none;
      outline: none;
    }
  }

  .end-section {
    width: 100%;
    margin-bottom: 20px;
  }
}

.rooms-prize-deatil {
  align-items: flex-end;

  .amount {
    font-size: 28px;
    color: #535148;
  }

  .taxes {
    color: #6b6b6b;
    margin-top: 11px;
  }

  .fees {
    color: #6b6b6b;
    margin-top: 3px;
  }

  .cancellation {
    // margin-top: 58px;
    text-decoration: line-through;
    text-decoration-color: #6b6b6b;
  }
}

.room-prize-buttons {
  align-items: flex-end;
  flex-direction: column;
  // margin-bottom: 22px;

  .free-breakfast {
    button {
      min-width: 216px;
      background: #e8ffee;
      color: #000000;
      height: 29px;
      margin-top: 39px;
      justify-content: center;
    }
  }

  .book-now {
    button {
      min-width: 216px;
      height: 36px;
      background: #34a853;
      color: #ffff;
      margin-top: 19px;
    }
  }
}

// Hotel-rooms-header-section
.hotel-rooms {
  mat-form-field {
    // svg {
    //   visibility: hidden;
    // }

    .mat-button-ripple-round {
      visibility: hidden;
    }

    .mat-button-focus-overlay {
      background: #ffff;
    }
  }
}

// Guest popup
.Guest-popup {
  background: $white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px 28px;
  max-width: 389px;
  width: 100%;

  mat-icon {
    font-size: 24px;
    cursor: pointer;
    // height: 40px;
    // width: 40px;
  }

  // .count {
  //   margin-left: 15px;
  // }

  .save-button {
    // margin-top: 50px;

    .mat-button-focus-overlay {
      background: none;
    }

    button {
      min-width: 100px;
      height: 47px;
      background: #535148;
      color: $white;
    }
  }

  .cancel-button {
    // margin-top: 50px;
    // margin-left:36px;

    button {
      background: #ffff;
      color: #6b6b6b;

      .mat-button-focus-overlay {
        background: none;
      }
    }
  }
}

//  --------Confirm booking section--------
.confirm-booking {

  .trip-section {
    width: 100%;
    // flex-wrap: wrap;
    justify-content: space-between;

    .left-section {
      position: relative;

      .Guest-popup {
        position: absolute;
        right: 0;
        top: 167px;

        .remove {
          color: #c9c1c1;
        }
      }

      .trip-detail {
        max-width: 807px;
        width: 100%;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        background: #ffff;

        .dates {
          padding: 16px 30px;
          border-bottom: 1px solid #f1ecec;
        }
      }

      .cancel-policy {
        max-width: 807px;
      }
    }

    .right-section {
      max-width: 400px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      // padding: 40px;
      padding: 25px;
      height: fit-content;

      .boking-room-info {
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 35px;

        .img1 {
          width: 100%;
          margin-right: 10px;
        }

        .img-content {
          width: 344px;
          // align-items: flex-end;
        }
      }
    }
  }

  .price-detail {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 35px;
    flex-wrap: wrap;

    .left-content {
      font-weight: 600;
      font-size: 16px;
    }

    .right-content {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

// Thank you page
.thank-you-page {
  min-height: calc(100vh - 144px);

  .thank-you-img {
    height: 240px;
  }

  .booking-id {
    width: 611px;
    height: 76px;
    background: $white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 28px;
  }

  .transaction-status {
    width: 611px;
    height: 156px;
    background: #f3f3f3;
    border-radius: 10px;
    padding: 30px;
    position: relative;
  }

  .back-service-btn {
    background-color: $primary;
    color: white;
    padding: 20px;
    border: none;
    outline: none;
    width: 611px;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    font-weight: 600;
    margin: 20px auto;
  }

  .dot-design {
    position: absolute;
    bottom: -7px;
    left: 0;
    justify-content: space-around;
    width: 100%;

    .dot {
      height: 15px;
      width: 15px;
      background-color: $white;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

// Room details
.room-details {
  .room-details-heading {
    font-size: 28px;
  }

  .rating {
    color: #fbbc05;
  }

  .right-img {
    width: 70%;

    img {
      width: 100%;
    }
  }

  .left-img {
    width: 30%;
    width: calc(30% - 12px);
    margin-left: 12px;

    flex-direction: column;

    .top-img {
      height: 50%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .bottom-img {
      height: 50%;
      height: calc(50% - 10px);
      margin-top: 10px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .room-detail-desc {
    .hotel-offers {
      width: 70%;
      flex-wrap: wrap;

      .place-offer {
        mat-icon {
          color: $black;
        }
      }
    }
  }

  .book-now-section {
    width: 100%;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .booking-info {
      padding: 24px;

      .Guest-popup {
        // position: absolute;
        // z-index: 2;
        // left:0;
        // top:208px;
      }

      .search-btn {
        button {
          background: #34a853;
          // max-width: 381px;
          width: 100%;
          height: 50px;
          color: #ffff;
        }
      }

      .guest {
        color: $black;
      }
    }
  }

  .recommended-room {
    width: 100%;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 28px 35px;
    margin-top: 20px;

    .left-side {
      position: relative;

      .room-image {
        // min-width: 138px;
        // min-height: 138px;
        height: 122px;
        width: 138px;
      }

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 5px;
      }

      .room-rent {
        background: #fbbc05;
        padding: 4px 8px;
        border-radius: 5px;
        position: absolute;
        bottom: 6px;
        left: 6px;
      }
    }

    .right-side {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;

      .left-section {
        .trip-detail {
          max-width: 807px;
          width: 100%;
          border: 1px solid #e9e9e9;
          border-radius: 10px;

          .dates {
            padding: 16px 30px;
            border-bottom: 1px solid #f1ecec;
          }
        }
      }

      .right-section {
        max-width: 400px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        padding: 40px;

        .boking-room-info {
          border-bottom: 1px solid #eaeaea;
          padding-bottom: 35px;

          .img1 {
            width: 100%;
          }
        }
      }
    }

    .price-detail {
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 35px;
      flex-wrap: wrap;

      .left-content {
        font-weight: 600;
        font-size: 16px;
      }

      .right-content {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

// Terms and condition poppup
.terms-conditions-popup {
  width: 400;
  background: #ffff;
  height: 300px;

  .header {
    height: 40px;
    background: #535042;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .close-icon {
      position: absolute;
      right: 8px;
      color: #ffff;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      color: #ffff;
    }
  }

  .content {
    padding: 20px;
  }
}

// active class for messaging component
.active-service {
  background-color: #e1eeff !important;
}

// overlay-effects for Our rooms
.img-overlay {
  display: none;
  position: absolute;
  height: 180px;
  // max-width: 312px;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  transform: translate(0, -180px);

  .overlay-items {
    display: flex;
    align-items: flex-end;
    height: 100%;
    justify-content: center;
    padding-bottom: 20px;

    button {
      outline: none;
      border: none;
    }

    .overlay-btn {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 20px;

      button {
        width: 99px;
        height: 47px;
        background: #34a853;
        color: #fff;
        border-radius: 5px;
      }
    }

    .icon1 {
      background: #4285f4;
      padding: 10px;
      width: 50px;
      height: 47px;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }

    .icon2 {
      background: #f25959;
      padding: 10px;
      width: 50px;
      height: 47px;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.img-info-on-hover {
  position: relative;
}

.img-info-on-hover:hover .img-overlay {
  display: block;
}

// user-header-profile-description after login and signup
.user-profile-desc {
  width: 70px;
  height: 38px;
  background: $white;
  border-radius: 80px;
  cursor: pointer;

  .profile-icon {
    mat-icon {
      font-size: 19px;
      display: flex;
      align-items: center;
    }
  }

  .user-name {
    background: #018bed;
    padding: 8px;
    border-radius: 50%;
    color: #fff;
    font-weight: 900;
    font-size: 14px;
  }
}

.main-outer {
  width: calc(100% - 300px);
  margin-left: 300px;
  padding: 0 20px;
  background: #f6f6f6;
}

.header-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

//CHECK-IN/LOGIN SIDENAV FRONTVIEW OF PAGES
.hotel-master {
  width: calc(100% - 76px);
  margin-left: 76px;
  margin-top: 0;
  height: 100%;
  overflow: auto;
  transition: 0.3s ease-in-out;
}

.uper-header-responsive {
  width: 0px !important;
  transition: 0.3s ease-in-out;
}

.user-upper-header-mobile {
  width: 323px;
  transition: 0.3s ease-in-out;
}

.hotel-master-responsive {
  width: calc(100% - 323px);
  margin-left: 323px;
  height: 100%;
  overflow: auto;
  transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 520px) {
  .hotel-master-responsive {
    width: 100%;
    margin-left: 0;
    height: 100%;
    overflow: auto;
    transition: 0.3s ease-in-out;
  }
}

.checkin-Checkout {
  margin-top: 32px;
}

.no-result {
  text-align: center;
}

.mtb-2 {
  margin: 10px 0;
}

.depart-staff-info {
  .user-images {
    span {
      img {
        height: 100%;
      }
    }
  }
}

.page-heading {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 25px !important;
  color: black !important;
  margin: 0 0 42px 5px !important;
}

.servicebooking {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 25px !important;
  color: black !important;
  width: 1000px;
  margin: auto;
}

.btn-transparent {
  background: transparent !important;
}

#tryagainbutton {
  background: red;
}

.color-box {
  p {
    margin-bottom: 10px;

    strong {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .color-box-output {
    width: 40px;
    height: 40px;
    float: left;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  input {
    border: 0 !important;
    position: relative;
    left: 16px;
    top: 10px;
    font-weight: 600;
    text-transform: capitalize;
    width: 100px;
  }
}

.padding20 {
  padding: 20px !important;
  background: #f6f6f6;
}

.headertoggle {
  width: 100% !important;
  margin-left: 0 !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  height: 67px;
  display: flex;
  align-items: center;

  .header-content {
    width: 100%;
    padding: 0 30px;
  }
}

//.....................................Offerings dropdown in admin ................................................................................................................

.offering-heading.mat-button {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px !important;
  color: #0a0936;
  background-color: #fcfcfc !important;
  padding: 0 11px 0 16px !important;
  cursor: pointer;

  &:hover {
    .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }
}

.offering-dropdown-options {
  font-family: "Nunito" !important;
  font-style: normal;
  font-weight: 600;
}

//....................................Offerings add new category button..........................................................................................................................................

.offering-category {
  border: 1px dashed #acacac;
  text-align: center;
  width: 100%;
  padding: 20px;
  font-family: "Nunito" !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #000000;
  background-color: white;
  cursor: pointer;

  i {
    font-weight: 500 !important;
    font-size: 24px !important;
    color: #000000;
    line-height: 26px;
    margin-right: 9px;
    display: block;
  }

  .offering-add-row {
    width: 100%;
    padding: 0 20px;

    input {
      border-radius: 5px;
      width: 100%;
      border: 1px solid #8190a5;
      padding: 12px 10px;

      &::placeholder {
        color: #8190a5;
      }
    }

    .new-row-done {
      display: flex;
      align-items: center;
      margin-left: 10px;
      border-radius: 5px;
      background-image: linear-gradient(54deg,
          #0c90d9 0%,
          #2a7ef7 50%,
          #3775f5 69%,
          #436cf3 100%);
      color: white;
      border: none;
      padding: 12px;
      cursor: pointer;
    }

    .new-row-close {
      display: flex;
      align-items: center;
      margin-left: 10px;
      border-radius: 5px;
      color: #8190a5;
      border: none;
      padding: 12px;
      background-color: white !important;
      cursor: pointer;
    }
  }
}

//.......................................Offerings add new category button..........................................................................................................................................

//..................................Offerings Table for tours/restaurant/services Start..........................................................................................................................................
.offerings-table-wrapper {
  width: 100%;
  overflow: auto;

  .offering-table {
    overflow: auto;

    tr {
      background: $white !important;

      th {
        font-family: "Nunito" !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        padding: 5px 5px 5px 0;
        color: #555555 !important;
        text-align: center;
        background: $white;

        &:nth-child(1) {
          width: 5%;
        }

        &:nth-child(2) {
          width: 25%;
          text-align: left;
        }

        &:nth-child(3) {
          width: 40%;
          text-align: left;
        }

        &:nth-child(4) {
          width: 10%;
        }

        &:nth-child(5) {
          width: 10%;
        }

        &:nth-child(6) {
          width: 10%;
        }
      }

      td {
        font-family: "Nunito" !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        color: #555555 !important;
        padding: 10px 5px 10px 0 !important;
        text-align: center;

        &:nth-child(1) {
          width: 5%;

          p {
            cursor: grabbing;
          }

          mat-icon {
            height: 6px;
            width: 6px;
            font-size: 18px !important;
            cursor: grab;
          }
        }

        &:nth-child(2) {
          width: 25%;
          text-align: left;
        }


        &:nth-child(3) {
          width: 40%;
          text-align: left;

          p {
            width: fit-content;
          }
        }

        &:nth-child(4) {
          width: 15%;


          .items-quantity {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &:nth-child(5) {
          width: 5%;
        }

        &:nth-child(6) {
          width: 10%;
        }
      }

      &:nth-child(even) {
        background-color: #f6f6f6 !important;
      }
    }
  }
}

//..................................Offerings Table for tours/restaurant/services End..........................................................................................................................................

//...........................................Headings For Tables..........................................................................................................................................

.offering-table-heading {
  font-family: "Nunito" !important;
  font-weight: 700 !important;
  font-size: 17px !important;
  color: #000000 !important;
  margin-bottom: 15.58px !important;
  margin-left: 15px;
}

//..........................................Headings For Tables..........................................................................................................................................

//...........................................Accordian Heading..........................................................................................................................................

.accordian-heading {
  font-family: "Nunito" !important;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 25px !important;
  color: #01153d !important;
}

//............................................Accordian Heading..........................................................................................................................................

//............................................Accordian Data.........................................................................................................................................

.billing-history-data {
  mat-expansion-panel {
    box-shadow: none !important;
  }
}

.background-color {
  background-color: #f3f3f3 !important;
}

.offering-table-icons {
  font-size: 16px;
  margin-right: 11px;
  color: #555555;
  cursor: pointer;
}

.padding {
  padding: 2px !important;
}

.offering-table-headings {
  padding: 17px 31px 3px 23px !important;
}

.offering-table-headings:nth-child(2) {
  padding-left: 0 !important;
}

//............................................Accordian Data.........................................................................................................................................

//............................................new-row inside the offering tables.........................................................................................................................................

.new-row {
  font-family: "Nunito" !important;
  width: 100%;
  color: black;
  justify-content: center;
  background: #f6f6f6;
  padding: 18px 0 17px 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  color: #7b7b7b;
  border: none;

  span {
    display: flex;

    i {
      margin-right: 9px;
      display: block;
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;
    }
  }

  .offering-add-row {
    width: 100%;
    padding: 0 20px;

    input {
      border-radius: 5px;
      width: 100%;
      border: 1px solid #8190a5;
      padding: 5px 10px;

      &::placeholder {
        color: #8190a5;
      }
    }

    .new-row-done {
      display: flex;
      align-items: center;
      margin-left: 10px;
      border-radius: 5px;
      background-image: linear-gradient(54deg,
          #0c90d9 0%,
          #2a7ef7 50%,
          #3775f5 69%,
          #436cf3 100%);
      color: white;
      border: none;
      padding: 5px;
      cursor: pointer;
    }

    .new-row-close {
      display: flex;
      align-items: center;
      margin-left: 10px;
      border-radius: 5px;
      color: #8190a5;
      border: none;
      padding: 5px;
      background-color: white !important;
      cursor: pointer;
      box-shadow: #00000029;
    }
  }
}

//............................................-----------------------------new-row inside the offering tables ENDS!!.........................................................................................................................................

//..........................--------------------------------new sidenavbar with toggle effect in guest mode after login and checkin.........................................................................................................................................

.navigation-heading-hidden {
  display: none !important;
  transition: 0.3s ease-in-out;
}

.user-image-mobile {
  width: 100% !important;
}

//..........................----------------------------------new sidenavbar with toggle effect in guest mode after login and checkin  ENDS!!!.........................................................................................................................................

// ------------------------------------------------------------------User Navigation For Sidenavigation View before login/checkin Start-----------------------------------------------------------------------------------------------------

.main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Nunito", sans-serif;
  // padding: 20px;

  .sidenav-logo-wrapper {
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 167px;
      height: 43px;

      #sidenav-logo {
        width: 100%;
        height: 43px;
        padding-left: 20px;
      }
    }
  }

  .profile-dropdown {
    width: 177px;
    border-radius: 10px;
    border-radius: 8px !important;
    height: fit-content;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    padding: 18px 0 18px 17px;

    .profile-items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      a {
        color: #cabf8a;
        margin-left: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }

      li {
        list-style: none;
        width: 100%;
        display: flex;
        align-items: center;

        &.active {
          background-color: #f6f6fc;
        }
      }
    }
  }

  .custom-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;

    .custom_nav {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          float: left;
          border-bottom: 3px solid rgba(7, 163, 249, 0) !important;
          // margin-left: 16px;



          a {
            color: #cabf8a;
            font-size: 15px;
            padding: 20px 10px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            font-family: "Nunito", sans-serif;
            font-weight: 700 !important;

            i {
              opacity: 0.7;
              font-size: 20px;
              margin: 0px 0px 0px 5px;
              height: 14px;
              line-height: 14px;
            }

            &:hover {
              background: transparent;
              text-decoration: none;
              color: $blue-secondary;
            }
          }

          &.acitve {
            a {
              color: $blue-secondary;
            }
          }


        }
      }
    }

    .lang-drop {
      margin-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: #cabf8a;
        }

        span {
          color: #cabf8a;
          margin: 0 5px;
        }
      }
    }
  }
}

// ------------------------------------------------------------------Main Header before login/checkin Ends-----------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------User Navigation For Sidenavigation View  after login/checkin Start-----------------------------------------------------------------------------------------------------

.user-sidenav {
  width: 323px;
  position: fixed;
  top: 0;
  height: 100vh;
  overflow: auto;
  transition: 0.3s ease-in-out;
  flex-direction: column;
  justify-content: unset;
  z-index: 9999;
  padding: 0;

  .sidenav-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 23px 0 17px;
    width: 100%;

    .logo {
      a {
        padding-left: 0px !important;
      }
    }

    .toggle-icons {
      padding: 5px;
      background: rgba(235, 235, 235, 0.2);
      border-radius: 50%;

      i {
        color: #cabf8a;
      }
    }
  }

  .user-profile-desc {
    padding: 7px 13px 6px 10px;
    width: calc(100% - 40px);
    height: fit-content;
    background-color: rgba(217, 217, 217, 0.11);
    border-radius: 10px;
    margin: 16px auto 25px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-image {
      width: 42px;
      height: 42px;
      overflow: hidden;
      border-radius: 50%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .user-name-icon {
      display: flex;
      flex-direction: row-reverse;

      .user-name {
        padding: 0px 28px 0 10px;
        border-radius: 0;
        background-color: transparent;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 123px;
        color: #cabf8a;
      }
    }
  }

  .custom-nav-wrapper {
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 0;

    .custom_nav {
      width: 100%;
      display: block;

      ul {
        flex-direction: column;

        li {
          width: 100%;
          padding: 15px 20px 15px 29px;
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            width: 100%;

            .user-navigation-icons {
              display: block;
              cursor: pointer;
            }

            .menu-item-text-color {
              font-family: "Nunito", sans-serif;
              font-weight: 700 !important;
              color: #cabf8a;
              font-size: 16px !important;
              padding: 0 0 0 16px;
            }
          }
        }
      }
    }

    .checkin-lang-wrapper {
      width: 100%;
      padding: 0 20px;

      .sidenav-checkin-btn {
        a {
          margin: auto;
          width: 100%;
          border: 1px solid rgba(202, 191, 138, 0.7);
          border-radius: 6px !important;
          line-height: 24.55px !important;
          padding: 12px 24px !important;
          margin-bottom: 22px;
          background: transparent !important;
          color: #cabf8a !important;

          i {
            margin: 0 0 0 30px;
          }
        }
      }

      .lang-drop {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 1px solid rgba(202, 191, 138, 0.7);
        border-radius: 6px !important;
        line-height: 24.55px !important;
        padding: 12px 24px !important;
        margin: 0 auto 22px;

        a {
          color: #cabf8a;

          span {
            margin: 0 52px;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: green;
  }

  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: gray;
  }

  &::-webkit-scrollbar-track {
    background-color: black;
  }
}

// ------------------------------------------------------------------User Navigation For Sidenavigation View   after login/checkin End-----------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------User Navigation For Mobile View Start-----------------------------------------------------------------------------------------------------

.new-mobile {
  width: 76px;
  transition: 0.3s ease-in-out;
  height: 100vh;
  z-index: 9999;
  padding: 0;

  .sidenav-logo-wrapper {
    justify-content: center;
    padding: 44px 24px 21px 24px;
    width: 100%;

    .logo {
      display: none;
    }
  }

  .user-profile-desc {
    width: 51px !important;
    justify-content: center;
    margin: 0 auto 3px auto;

    .user-image {
      width: 38px !important;
      height: 100% !important;
    }
  }

  .custom-nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .custom_nav {
      ul {
        li {
          justify-content: center;
          padding: 15px 28px;
        }
      }
    }

    .checkin-lang-wrapper {
      padding: 0 12px;

      .sidenav-checkin-btn {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent !important;
          color: #cabf8a !important;

          i {
            margin: 0;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: green;
  }

  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: gray;
  }

  &::-webkit-scrollbar-track {
    background-color: black;
  }
}

// ------------------------------------------------------------------User Navigation For Mobile View Ends-----------------------------------------------------------------------------------------------------

.profile-dropdown {
  width: 177px;
  border-radius: 8px !important;
  height: fit-content;
  background: #6c695b;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 18px 0 18px 17px;

  .profile-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    a {
      color: #cabf8a;
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

    li {
      list-style: none;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 5px 10px;

      &.active {
        background-color: #f6f6fc;
      }
    }
  }
}

.mat-menu-before {
  border-radius: 8px !important;
}

.mat-dialog-container {
  height: fit-content;
  border-radius: 12px;
}

.row-inputs {
  width: 100% !important;

  .name-input-wrapper {
    .name-input {
      border-radius: 5px;
      border: 1px solid #8190a5;
      padding: 7px 10px;
      width: -webkit-fill-available;

      &::placeholder {
        color: #8190a5;
      }
    }
  }

  .descripton-input-wrapper {
    .description-input {
      border-radius: 5px;
      border: 1px solid #8190a5;
      padding: 7px 10px;
      width: -webkit-fill-available;

      &::placeholder {
        color: #8190a5;
      }
    }
  }

  .quantity-checkbox {
    .quantity-input {
      border-radius: 5px;
      border: 1px solid #8190a5;
      padding: 7px 8px;
      width: -webkit-fill-available;

      &::placeholder {
        color: #8190a5;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }

      &[readonly] {
        // background-color: #f5f5f5; /* Light gray to indicate it's read-only */
      }

    }

    .hidden-input {
      opacity: .6;
      // position: absolute;
      border-radius: 5px;
      border: 1px solid #8190a5;
      padding: 7px 8px;

      &::placeholder {
        color: #8190a5;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      // left: -9999px; /* Push off-screen */
      pointer-events: none;

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }

      &[readonly] {
        // background-color: #f5f5f5; /* Light gray to indicate it's read-only */
      }
    }


    .quantity-filter {
      position: absolute;
      right: 5px;
      top: 25%;
      cursor: pointer;
    }

  }

  .price-input-wrapper {
    .price-input {
      border-radius: 5px;
      border: 1px solid #8190a5;
      padding: 7px 8px;
      width: -webkit-fill-available;
      // width: 10% !important;

      &::placeholder {
        color: #8190a5;
      }
    }
  }
}

.edit-input {
  padding: 5px !important;
  border-radius: 5px !important;
  border: 1px solid #8190a5 !important;

  &::placeholder {
    color: #8190a5 !important;
  }

  &:nth-child(2) {
    margin: 0 11px !important;
    // direction: rtl;
  }
}

.edit-description {
  padding: 5px !important;
  border-radius: 5px !important;
  border: 1px solid #8190a5 !important;

  &::placeholder {
    color: #8190a5 !important;
  }

  &:nth-child(2) {
    margin: 0 11px !important;
    // direction: rtl;
  }
}

.edit-btn-done {
  display: flex;
  align-items: center !important;
  border-radius: 5px !important;
  background-image: linear-gradient(54deg,
      #0c90d9 0%,
      #2a7ef7 50%,
      #3775f5 69%,
      #436cf3 100%) !important;
  color: white !important;
  border: none !important;
  padding: 5px !important;
  cursor: pointer !important;
}

.edit-btn-close {
  display: flex;
  align-items: center !important;
  border-radius: 5px;
  margin-left: 10px !important;
  color: #8190a5 !important;
  border: none !important;
  padding: 5px !important;
  background-color: white !important;
  cursor: pointer !important;
  margin-right: 10px;
}

// ------------------------------------------------------------------Confirmation Popup For Offerings Table Start-----------------------------------------------------------------------------------------------------

.confirmation-popup {
  width: 422px;
  padding: 20px;
  border-color: transparent;
  outline: none;
  background: #fefefe;
  border-radius: 10px;

  .cancel-btn {
    text-align: end;
  }

  .delete-img {
    text-align: center;
  }

  h1 {
    text-align: center;
    margin: 20px auto 13px auto;
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
  }

  span {
    display: block;
    text-align: center;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  .confirmation-btn-grp {
    margin-top: 30px;
    text-align: center;

    .cancel-confirmation {
      font-family: "Nunito", sans-serif;
      padding: 6px 25px;
      border-radius: 3px;
      background: #fefefe;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #001847;
      margin-right: 20px;
      border: 1px solid #001847;
      outline: none;
    }

    .accept-confirmation {
      font-family: "Nunito", sans-serif;
      padding: 6px 25px;
      border-radius: 3px;
      background: #001847;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $white;
      border: 1px solid transparent;
      outline: none;
    }
  }
}

.confirmation-popup-service {
  padding: 20px;
  border-color: transparent;
  outline: none;
  background: #fefefe;
  border-radius: 10px;

  .cancel-btn {
    text-align: end;
  }

  .delete-img {
    text-align: center;
  }

  h1 {
    text-align: center;
    margin: 20px auto 13px auto;
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
  }

  span {
    display: block;
    text-align: center;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  .confirmation-btn-grp {
    margin-top: 30px;
    text-align: center;

    .cancel-confirmation {
      font-family: "Nunito", sans-serif;
      padding: 6px 25px;
      border-radius: 3px;
      background: #fefefe;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #001847;
      margin-right: 20px;
      border: 1px solid #001847;
      outline: none;
      cursor: pointer;
    }

    .accept-confirmation {
      font-family: "Nunito", sans-serif;
      padding: 6px 25px;
      border-radius: 3px;
      background: #001847;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $white;
      border: 1px solid transparent;
      outline: none;
      cursor: pointer;
    }
  }
}

.confirmation-popup-save {}

// ----------------------------------------------------------------------Confirmation Popup For Offerings Table End--------------------------------------------------------------------------------

.delete-service {
  margin-right: 40px !important;
}

// ----------------------------------------------------------Booking table for Restaurant,Services and Tours/Event Bookings Start-------------------------------------------------------------------------------

.booking-table {
  tr {
    background: $white !important;

    th {
      font-family: "Nunito" !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      padding: 11px 24px 11px 23px !important;
      color: $black !important;
      text-align: left;
      background: $white;

      &:nth-child(1) {
        padding: 0 0 0 23px !important;
        text-align: center;
      }

      &:nth-child(2) {
        // width: 65%;
        text-align: left;
        padding-left: 23px !important;
      }

      &:nth-child(4) {
        // width: 65%;
        text-align: center;
      }

      &:nth-child(5) {
        // width: 65%;
        text-align: center;
      }
    }

    td {
      font-family: "Nunito" !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      color: $black !important;
      padding: 13px 24px 13px 23px !important;
      text-align: center;
      vertical-align: middle;

      p {
        font-family: "Nunito" !important;
        font-weight: 400;
        font-size: 14px;
      }

      &:nth-child(1) {
        padding: 0 0 0 23px !important;
        width: 5%;
      }

      &:nth-child(2) {
        // width: 221px;
        text-align: left;
        padding-left: 23px !important;
        width: 25%;
      }

      &:nth-child(3) {
        text-align: left;
        width: 30%;

        p {
          font-weight: 400 !important;
          font-size: 14px !important;
        }
      }

      &:nth-child(4) {
        // width: 81px;
        text-align: center;
        width: 20%;

        .add-item {
          min-width: 81px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          padding: 0;

          .add {
            padding: 5px 10px 0 0;
            background: #ffffff;
            border: none;
            font-weight: 600;
            font-size: 14px;
            font-family: "Nunito" !important;
            cursor: pointer;
          }

          .incerment {
            border: none;
            border-left: 2px solid #d9d9d9;
            background: #ffffff;
            padding: 5px 0 5px 10px;
            font-weight: 700;
            font-size: 15px;
            cursor: pointer;
          }
        }

        .iteration-btn {
          border: 1px solid red;
          width: 81px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 3px 0px;
          background: #ffffff;
          border: 1px solid #dcdcdc;
          border-radius: 4px;

          .iterate {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          .disabled {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            opacity: 0.3 !important; // Faded effect
            pointer-events: none !important; // Disable interaction
            cursor: not-allowed !important; // Change cursor
          }

          #quantity-number {
            background-color: #f5f5f5;
            padding: 2px 9px;
          }
        }
      }

      &:nth-child(5) {
        padding: 13px !important;
        width: 10%;

        p {
          font-family: "Nunito" !important;
          text-align: center;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }

    &:nth-child(even) {
      background-color: #f6f6f6 !important;
    }
  }
}

// -----------------------------------Booking table for Restaurant,Services and Tours/Event Bookings End-------------------------------------------------------------------------------

// --------------------------------------------Payment Screen For Restaurant Booking start-------------------------------------------------------------------------------

.booking-method-wrapper {
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 229px);

  .booking-pay-methods {
    padding: 19px 23px;
    max-width: 653px;
    width: 100%;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px 0px 0px 6px;

    .booking-heading {
      font-family: "Nunito", sans-serif !important;
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 12px;
    }

    .selected-cart-headings {
      font-weight: 800;
      font-family: "Nunito";
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 12px;
    }

    .service-feedback {
      width: 100%;
      border: 1px solid #E9E9E9;
      padding: 13px 17px;
    }


    .restaurant-name {
      background: $white;
      margin-bottom: 20px;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        font-family: "Nunito";
        color: #000000;
        display: block;
        padding: 13px 17px;
        border-radius: 3px;
        border: 1px solid #e9e9e9;
      }
    }

    .booking-items {
      align-items: center;
      background: $white;
      border: 1px solid #e9e9e9;
      border-radius: 3px;
      margin-bottom: 4px;

      .item-description {
        font-family: "Nunito" !important;
        width: calc(100% - 245px);
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        padding: 5px 0 5px 17px;
      }

      .booking-inputs {
        width: 245px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 5px 17px;

        input {
          width: 110px;
          background: $white;
          border: 1px solid #c4c4c4;
          border-radius: 3px;
          padding: 8px 15px;
        }

        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          text-align: right;
          color: #000000;
        }

        i {
          color: #f25959;
        }
      }
    }

    mat-expansion-panel {
      border: 1px solid #e9e9e9;
      box-shadow: none !important;
      margin-bottom: 20px;

      mat-expansion-panel-header {
        padding: 13px 17px !important;

        mat-panel-title {
          font-family: "Nunito" !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          color: #000000 !important;
          box-shadow: none !important;
        }
      }
    }

    .tip {
      font-family: "Nunito" !important;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 12px;
    }

    input {
      font-family: "Nunito" !important;
      width: 100%;
      padding: 13px 17px;
      background: $white;
      border: 1px solid #e9e9e9;
      border-radius: 3px;
      margin-bottom: 12px;

      &::placeholder {
        font-family: "Nunito" !important;
        color: #8190a5;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        opacity: 0.5;
      }
    }
  }

  .restaurant-payment-option {
    max-width: 389px;
    overflow: hidden;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    padding: 26px;
    background: #f8f8f8;

    .booking-info-wrapper {
      animation: slideMe .4s linear;

      .restaurant-payment-heading {
        font-family: "Nunito" !important;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        color: #000000;
        margin-bottom: 13px;
      }

      .restaurant-preference {
        margin-bottom: 26px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          font-family: "Inter", sans-serif;
        }
      }

      .booking-service-id {

        width: 100%;

        margin-bottom: 14px;

        border-radius: 5px;

        background: #F3F3F3;

        padding: 16px 21px;

        border: none;

        outline: none;




        &::placeholder {

          font-size: 14px;

          font-family: Inter;

          font-weight: 400;

          color: #001F4D;

        }

        &::-webkit-outer-spin-button,

        &::-webkit-inner-spin-button {

          -webkit-appearance: none;

          margin: 0;

        }

        &[type=number] {

          -moz-appearance: textfield;

        }

      }


      .date-picker,
      .time-picker {
        width: 100%;
        margin-bottom: 14px;

        .date-range {
          width: 47%;
          height: 50px;
          display: flex;
          align-items: center;
          background: #F3F3F3;
          border-radius: 5px;
          padding: 0px 10px;
          position: relative;

          input {
            width: 75%;
            border: none;
            background-color: #F3F3F3;

            &::placeholder {
              color: #001F4D;
              font-weight: 400;
              font-size: 14px;
              font-family: "Inter";
            }
          }

          mat-date-picker {
            button {
              height: none !important;
              line-height: 0 !important;
            }
          }

          .timer-icon {
            position: absolute;
            right: 18px;
            color: rgba(0, 0, 0, 0.5) !important;
            cursor: pointer;
          }


          .mat-form-field-infix {
            display: flex;
            align-items: baseline;
            justify-content: center;
          }
        }
      }

      .booking-service-request {
        .booking-request-text {
          font-size: 16px;
          margin: 12px 0;
        }
      }


      .room-preference {
        margin-bottom: 26px;

        .example-margin {
          margin-right: 23px;
        }

        .selection-dropdown-list {
          margin: 8px auto 20px;

          .mat-form-field-wrapper {
            padding-bottom: 0;
            padding: 15px 20px;
            border-radius: 5px;
            background: #F3F3F3;

            .mat-form-field-flex {
              .mat-form-field-infix {
                padding: 0;
                border-top: 0;



                .mat-chip-list {
                  .mat-chip-list-wrapper {
                    margin: 0;
                    gap: 4px;

                    .mat-chip-input {
                      padding: 0 5px;
                      color: #898989;
                    }

                    .mat-chip {
                      height: 35px;
                      font-size: 14px;
                      font-family: 'Nunito';
                      color: #001F4D;
                      margin: 0;
                      padding: 8px;
                      border-radius: 4px;
                      background: rgba(0, 24, 71, 0.10);

                      .mat-icon {
                        opacity: unset;
                        color: $black;
                      }
                    }
                  }
                }

                .mat-form-field-label-wrapper {
                  .mat-form-field-label:focus {
                    color: #AAA;
                  }

                  .mat-form-field.mat-focused .mat-form-field-label {
                    color: #898989;
                  }
                }
              }
            }

            .mat-form-field-underline {
              display: none;
            }
          }
        }

        .assign-staff {
          border: 1px solid #EEEEEE;
          background-color: #f8f6f6;
          height: 150px;
          overflow: auto;
          border-radius: 5px;
          padding: 10px;

          p {
            cursor: pointer;
            padding: 10px;
            border-radius: 5px;

            &:hover {
              background-color: #e2e2e2;

            }
          }

          &::-webkit-scrollbar {
            width: 2px;
            margin-right: 11px;
          }

          &::-webkit-scrollbar-track {
            border-radius: 2px;
          }

          &::-webkit-scrollbar-thumb {
            background: #d9d9d9;
            border-radius: 2px;
          }
        }

        .service-feedback {
          margin-top: 20px;
          padding: 16px 21px;
          width: 100%;
          background-color: #f3f3f3;
          border-radius: 5px;
          border: none;
        }

        .service-feedback::-webkit-outer-spin-button,
        .service-feedback::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .service-feedback[type="number"] {
          -moz-appearance: textfield;
        }
      }

      .table-number {
        padding: 16px 21px;
        width: 100%;
        background-color: #f3f3f3;
        border-radius: 5px;
        margin-bottom: 30px;
        border: none;

        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #001f4d;
        }
      }

      .room-number {
        padding: 16px 21px;
        width: 100%;
        background-color: #f3f3f3;
        border-radius: 5px;
        margin-bottom: 30px;
        border: none;

        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #001f4d;
        }
      }
    }

    .payment-wrapper {
      animation: slideMe .4s linear;

      .booking-request-text {
        display: block;
        font-size: 16px;
        font-weight: 700;
        margin: 21px 0 16px 0;
      }

      .restaurant-payment-heading {
        font-family: "Nunito" !important;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
      }

      hr {
        margin: 7px auto 16px auto;
      }

      #order-state {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        font-family: "Nunito" !important;
        color: #000000;
        margin-bottom: 12px;
        display: block;
      }

      .table-number-wrapper {
        width: 52%;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        font-family: "Nunito" !important;
        color: #333333;
        display: block;
      }

      strong {
        width: 48%;
        word-break: break-word;
      }

      .items-heading {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: "Nunito" !important;
        line-height: 19px;
        color: #000000;
        margin-bottom: 12px;
      }

      .user-feedback {
        background: $white;
        border: 1px solid #e9e9e9;
        border-radius: 3px;
        width: 100%;
        padding: 13px 17px;
        font-family: "Nunito" !important;
        color: #8190a5;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000000 !important;

        &::placeholder {
          font-family: "Nunito" !important;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
          opacity: 0.5;
        }
      }

      hr {
        margin: 13px auto 24px auto;
      }
    }

    .stripe-option {
      cursor: pointer;
      transition: all 0.2s linear;
      padding: 5px 10px;

      .payment-images {
        width: 28px;
        height: 28px;
        border-radius: 4px;

        &:hover {
          background-blend-mode: hard-light;
        }
      }

      .payment-text {
        font-family: "Nunito" !important;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $black;
        margin-left: 12px;
      }

      &:hover {
        transform: scale(1.1);
        background: white;
        padding: 5px 10px;
        transition: all 0.2s linear;
      }
    }

    .paypal-option {
      margin: 20px 0px;
      cursor: pointer;
      transition: all 0.2s linear;
      padding: 5px 10px;


      .payment-images {
        width: 28px;
        height: 28px;
        border-radius: 4px;
      }

      .payment-text {
        font-family: "Nunito" !important;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $black;
        margin-left: 12px;
      }

      &:hover {
        transform: scale(1.1);
        background: white;
        padding: 5px 10px;
        transition: all 0.2s linear;
      }
    }

    .reception-option {
      cursor: pointer;
      padding: 5px 10px;

      .payment-images {
        width: 28px;
        height: 28px;
        border-radius: 4px;
      }

      .payment-text {
        font-family: "Nunito" !important;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $black;
        margin-left: 12px;
      }

      &:hover {
        transform: scale(1.1);
        background: white;
        padding: 5px 10px;
        transition: all 0.2s linear;
      }
    }
  }

  .pay-button {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    padding: 12px 30px 13px 30px;
    width: 100%;
    font-family: "Nunito" !important;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: $white;
    margin-top: 43px;
    background: #008bec;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s linear;


    mat-icon {
      margin-left: 20px;
    }

    &:hover {
      background: #0780d3;
      transform: scale(1.05);
      transition: all 0.3s linear;
    }
  }
}

.drag-icon-category {
  margin-right: 30px;
}

.drag-item-icon {
  margin-right: 10px;
}

.mat-content {
  align-items: center;
}

.preview {
  background: #f3f3f3 !important;
  color: $black;
  padding: 0 24px;
  height: 43px;
  max-width: 1473px;
  width: 100%;
  display: flex;
  align-items: center;

  .preview-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.cdk-drag-preview {
  // left: -40px;
  width: calc(100% - 384px);
}

// --------------------------------------------Payment Screen For Restaurant Booking End-------------------------------------------------------------------------------

.service-view-details {
  display: flex;
  background-color: #f6f6f6;

  .service-list-wrapper {
    max-width: 280px;
    width: 100%;
    background: $white;
    border-radius: 4px 4px 0px 0px;
    height: 329px;
    overflow: auto;
    padding: 18px 20px;
    margin-right: 25px;

    .service-list {
      .list-item {
        padding: 10px;
        border-bottom: 1px solid #d9d9d9;

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
          font-family: "Nunito" !important;
        }

        &:hover {
          background: #f6f6f6;
        }

        &:active {
          background: #f6f6f6;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 9px;
      margin-right: 11px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 2px;
    }
  }

  .service-list-content-wrapper {
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 4px;
    height: 329px;
    overflow: auto;

    .service-list-content {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      background: $white;
      padding: 10px 45px 10px 25px;

      .service-image {
        width: 66px;
        height: 66px;
        margin-right: 24px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .service-description {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .service-description-text {
          display: flex;
          font-family: "Nunito", sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
          position: relative;
        }

        .service-item-description-text {
          display: inline-block;
          font-family: "Nunito", sans-serif;
          font-weight: 700;
          font-size: 11px;
          line-height: 19px;
          color: #000000;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 9px;
      margin-right: 11px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 2px;
    }
  }
}

.item-preview {
  width: 100%;
  font-family: "Nunito" !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #555555 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: $white !important;
  border: 1px dashed $white !important;
}

.offerings-new-table {
  tbody {
    width: 100%;

    tr {
      width: 100%;

      td {
        .inventory-checkbox {
          span {
            white-space: nowrap;
            margin-left: 10px;
          }
        }

        &:nth-child(1) {
          width: 100%;
          padding: 15px 31px 19px 0px;
        }

        &:nth-child(2) {
          max-width: 466.33px;
          width: 100%;
        }

        &:nth-child(3) {
          max-width: 152.33px;
          width: 100%;
        }

        &:nth-child(4) {
          max-width: 270px;
          width: 100%;
          justify-content: center;
        }
      }
    }

    tr {
      .description-input {
        width: 100% !important;
        border-radius: 5px;
        border: 1px solid #8190a5;
        padding: 12px 10px;

        &::placeholder {
          color: #8190a5;
        }
      }
    }
  }
}

.btn-grp-wrapper {
  .new-row-done {
    display: flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    background-image: linear-gradient(54deg,
        #0c90d9 0%,
        #2a7ef7 50%,
        #3775f5 69%,
        #436cf3 100%);
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
  }

  .new-row-close {
    display: flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    color: #8190a5;
    border: none;
    padding: 5px;
    background-color: white !important;
    cursor: pointer;
  }
}

.primary-tooltip {
  max-width: 700px !important;
}

.user-upper-header {
  background-color: #535148;
  padding: 24px 23px;

  .toggle-menu-icon {
    mat-icon {
      color: #cabf8a;
    }
  }

  .upper-header-content {
    .lang-drop {
      margin-right: 17px;

      a {
        display: flex;
        align-items: center;

        span {
          margin: 0 5px;
        }
      }
    }

    .user-profile-desc {
      width: fit-content;
      height: fit-content;

      .user-image {
        width: 42px;
        height: 42px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 0px !important;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.mat-tooltip.my-tooltip {
  width: 260px;
  padding: 16px;
  color: black;
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
}

// -----------------------------------------------Tool Tip for Menu Item in Restaurant for Mobile View start----------------------------------------------

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  width: auto;
  display: none;
  color: #fff;
  text-align: center;
  padding: 16px 15px;
  position: absolute;
  z-index: 9999;
  top: 25px;
  left: 34px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .tooltip-title {
    background: #f3f3f3;
    border-radius: 4px;
    text-align: left;
    padding: 12px 10px;
    margin-bottom: 8px;

    span {
      color: black;
    }
  }

  p {
    color: black;
    text-align: left;
    word-break: break-word;
  }

  a {
    width: 100%;
    text-align: left;
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
  }
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.tooltip:hover .tooltiptext {
  display: block;
  opacity: 1;
}

// -----------------------------------------------Tool Tip for Menu Item in Restaurant for Mobile View end----------------------------------------------

.menu-table-mobile {
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    font-family: "Nunito";
    margin: 27px 0 21px 0;
  }

  mat-expansion-panel {
    margin-bottom: 10px;
    background: #ffffff !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;

    .menu-table-accordian-heading {
      height: fit-content !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      padding: 10px 19px 10px 15px;

      mat-panel-title {
        h2 {
          font-weight: 500;
          font-size: 16px;
          color: #001f4d;
          margin: 0;
          font-family: "Inter", sans-serif;
        }
      }

      span {
        &::after {
          width: 13px;
          height: 13px;
          display: block;
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 5px 19px 15px 15px;

      .menu-item-mobile-wrapper {
        background: #f6f6f6;
        margin-bottom: 10px;
        padding: 8.5px 17px 8.5px 10px;

        .menu-item-title-mobile {
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #0b1641;
            font-family: "Inter", sans-serif;
            margin-bottom: 0px;
          }

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }

        .mobile-item-price {
          display: block;
          font-weight: 500;
          color: #000000;
        }

        .menu-item-description-mobile {
          margin-bottom: 4px;

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #0b1641;
          }
        }

        .add-item {
          padding: 0px;
          width: 68px;
          height: 25px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 4px;

          .add {
            border: none;
            padding: 0 !important;
          }

          .incerment {
            border: none;
            border-left: 1px solid #dcdcdc;
          }
        }

        .iteration-btn {
          border: 1px solid red;
          width: 68px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 3px 0px;
          background: #ffffff;
          border: 1px solid #dcdcdc;
          border-radius: 4px;

          .iterate {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          .disabled {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            opacity: 0.3 !important; // Faded effect
            pointer-events: none !important; // Disable interaction
            cursor: not-allowed !important; // Change cursor
          }

          #quantity-number {
            background-color: #f5f5f5;
            padding: 2px 9px;
          }
        }

        button {
          padding: 7px 11px;
          background: #ffffff;
          border: 1px solid #dcdcdc;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          font-family: "Inter", sans-serif;
        }
      }
    }
  }

  #continue-button {
    width: 100%;
    padding: 16px 0 16px 0 !important;
    background: #008bec;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    position: sticky;
    bottom: 10px;
    // z-index: 9999999;
    font-family: "Inter", sans-serif;
    cursor: pointer;
  }
}

.desc-expansion {
  color: blue !important;
}

.cart-items-wrapper {
  width: 100%;
  margin-bottom: 11px;
  background: #ffffff;
  padding: 18px 13px 14px 13px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

  table {
    width: 100%;

    thead {
      th {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #000000;

        &:nth-child(1) {
          padding: 5px 5px 5px 15px;
        }

        &:nth-child(2) {
          text-align: center;
          padding: 11px 13px 11px 13px;
        }

        &:nth-child(3) {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        border-radius: 3px;

        td {
          vertical-align: middle;

          &:nth-child(1) {
            padding: 5px 5px 5px 15px;
          }

          &:nth-child(2) {
            text-align: center;
            padding: 11px 13px 11px 13px;

            .qty-iteration-btn {
              margin: auto !important;
              width: 54px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border: 1px solid #dcdcdc;
              border-radius: 3px;

              .iterate {
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
              }

              .disabled {
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                opacity: 0.3 !important; // Faded effect
                pointer-events: none !important; // Disable interaction
                cursor: not-allowed !important; // Change cursor
              }


              #quantity-number {
                background-color: #f5f5f5;
                padding: 2px 5px;
              }
            }
          }

          &:nth-child(3) {
            .booking-inputs {
              display: flex;
              align-items: center;
              justify-content: center;

              i {
                color: #f25959;
              }
            }
          }

          &:nth-child(4) {
            padding: 5px;

            i {
              font-size: 16px !important;
              color: #f25959;
              cursor: pointer;
            }
          }
        }

        &:nth-child(odd) {
          background: #f3f3f3;
        }
      }
    }
  }

  .tip-chips {
    padding: 9px px 14px 0px;

    #tip-heading {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin: 15px 0 8px 0;
      display: block;
      font-family: "Nunito", sans-serif;
    }

    .mat-chip-list-stacked {
      display: block;

      .mat-chip-list-wrapper {
        flex-direction: row !important;

        .mat-chip {
          width: fit-content;
          height: 26px;
          background: #ffffff;
          box-shadow: inset -0.5px -0.5px 2px rgba(0, 0, 0, 0.2),
            inset 0.5px 0.5px 2px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          color: #000000;
          font-size: 14px;
          font-weight: 400;
          font-family: "Nunito", sans-serif;
          cursor: pointer;

          // &:focus {
          //   background-color: #49ca6d;
          //   color: white;
          // }
        }

        .mat-chip.active {
          background-color: #49ca6d;
          color: white;
        }
      }
    }

    // .selected-chip {
    //   background-color: green;
    //   color: white;
    // }

    .custom-tip {
      display: flex;
      justify-content: left;
      align-items: center;
      margin-top: 5px;

      #custom-chip-dollar {
        span {
          font-size: 20px;
          margin-right: 5px;
        }
      }

      #custom-tip-price {
        border-bottom: 1px dotted black;
        border-left: none;
        border-right: none;
        border-top: none;
        padding: 0;
        width: auto;
        box-shadow: none;
        margin-bottom: 0;
      }

      .custom-tip-btn {
        border: none;
        outline: none;
        background-color: inherit;
        color: red;
        font-size: 12px;
      }



      .mat-chip.active {
        background-color: green;
        color: white;
      }

      .custom-tip-btn.added {
        color: green;
      }
    }

    .error-tip-msg {
      margin: 10px 0;
      color: red;
      font-size: 14px;
      font-family: "Nunito", sans-serif;
      display: block;
    }
  }
}

.custom-tip-btnn {
  border: none;
  outline: none;
  background-color: inherit;
  color: red;
  font-size: 12px;
  font-weight: 600;
}

.cart-total-amount {
  padding: 22px 14px;
  margin-bottom: 24px;
  background: #fff8e3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

  .subtotal-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Nunito", sans-serif;
    margin-bottom: 10px;

    h4 {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  }

  .amount-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Nunito", sans-serif;

    h4 {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
    }

    span {
      font-weight: 700;
      font-size: 20px;
    }
  }
}

.final-payment-button {
  padding: 16px !important;
  background: #008bec;
  border-radius: 5px;
  width: 100%;
  color: white;
  border: none;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s linear;
  margin: 10px 0;


  &:hover {
    background: #0780d3;
    transform: scale(1.05);
    transition: all 0.3s linear;
  }
}

//GUEST-CHECKIN-PAGE-START

.checkin-guest-wrapper {
  // width: 430px;
  margin: auto;
  background: $white;

  .cart-checkin-cancel-responsive {
    float: right;
    margin: 10px;
    clear: both;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 100%;

    mat-icon {
      font-size: 25px;
      color: #686666;
    }
  }

  .clip-art {
    display: none;
    margin-bottom: 41px;
    overflow: hidden;
    position: relative;

    .cart-checkin-cancel {
      position: absolute;
      right: 30px;
      top: 20px;
      cursor: pointer;
      padding: 7px 10px;
      border-radius: 100%;

      mat-icon {
        font-size: 25px;
      }
    }


    svg {
      max-width: 820px;
      // min-width: 320px;
      width: 100%;
      max-height: 350px;
      min-height: 173px;
      margin-left: 3px;
    }
  }

  .checkin-page-content {
    padding: 20px 47px 0;

    .checkin-restaurant-Logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 30px auto;
      width: 142px;
    }

    .checkin-welcoming {
      text-align: center;
      margin-bottom: 31px;

      h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        font-family: "Inter";
        color: #001f4d;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        font-family: "Inter";
        line-height: 17px;
        text-align: center;
        color: #001f4d;
      }
    }

    .guest-checkin-id {
      input {
        width: 100%;
        padding: 17px;
        background: #f3f3f3;
        border-radius: 5px;
        margin-bottom: 11px;
        outline: none;
        border: none;

        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          font-family: "Inter";
          line-height: 17px;
          color: #001f4d;
        }
      }
    }

    .existing-guest {
      width: 100%;
      padding: 16px;
      background: #008bec;
      font-family: "Inter";
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      color: white;
      border: none;
      margin-bottom: 11px;
      transition: all 0.3s linear;


      &:hover {
        background: #0780d3;
        transform: scale(1.05);
        transition: all 0.3s linear;
      }
    }

    .guest-confirmation {
      width: 100%;
      font-family: "Inter";
      padding: 16px;
      background: white;
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      color: #008bec;
      border: 1px solid #008bec;
      margin-bottom: 11px;
      transition: all 0.3s linear;


      &:hover {
        background: #ffffff;
        transform: scale(1.05);
        transition: all 0.3s linear;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: green;
  }

  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: gray;
  }

  &::-webkit-scrollbar-track {
    background-color: black;
  }
}

.password-field-cart {
  position: relative;
  margin-bottom: 23px !important;

  input {
    margin-bottom: 0 !important;
  }

  .password-visibility {
    position: absolute;
    top: 25%;
    right: 15px;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: #9e9898;
    }
  }
}

.guest-details {
  // padding: 21px 27px 37px 27px;

  .guest-text {
    margin: 9px 0 13px 0;
    line-height: 19px;
  }

  .guest-info-cart {
    .guest-details-label {
      display: block;
      margin: 23px 0 12px 0;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    .country-code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 3px;
      outline: none;

      mat-select {
        width: 40% !important;
        cursor: pointer;
        margin-right: 5px;
        padding: 13px 5px 13px 10px;
        border: 1px solid #e9e9e9;
        background: white;
        height: 43.33px;
        background: #e9e9e9;
      }

      input {
        border: none;
        padding: 13px;
        border: 1px solid #e9e9e9;
        height: 43.33px;


        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .guest-detail-input {
      padding: 13px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 3px;
      width: 100%;


    }

    .guest-mobile-disable {
      background: #E9E9E9 !important;

    }
  }

  .mobile-otp {
    margin-top: 31px;

    h4 {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
    }

    .otp-sent-text {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 17px;
    }

    .otp-fields {
      display: flex;
      align-items: center;
      margin: 17px 0 9px 0;

      .otp {
        padding: 9px 17px;
        outline: none;
        border: none;
        display: inline;
        margin-right: 15px;
        width: 45px;
        background: white;
        border: 1px solid #e9e9e9;
        border-radius: 3px;
      }

      .otp::-webkit-outer-spin-button,
      .otp::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .otp[type="number"] {
        -moz-appearance: textfield;
      }
    }

    .resend {
      color: #ff0000;
      text-transform: uppercase;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      margin-left: 8px;
      line-height: 16px;
      text-align: center;
      display: block;
      cursor: pointer;
    }

    .timer-wrapper {
      display: flex;
      align-items: center;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #000000;
      margin: 12px 0 15px 0;

      .timer {
        svg {
          display: inline;
        }

        span {
          display: inline;
        }
      }

      .wrong-number {
        margin-left: 84px;
        cursor: pointer;
      }

    }
  }

  button {
    background: #008bec;
    text-align: center;
    padding: 16px;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    outline: none;
    border: none;
    margin-top: 20px;
    border-radius: 5px;
    transition: all 0.3s linear;


    &:hover {
      background: #0780d3;
      transform: scale(1.05);
      transition: all 0.3s linear;
    }
  }
}

.thank-you-page-cart {
  max-width: 720px;
  margin: auto;
  padding: 28px 28px 33px 28px;
}

.guest-cart-payment {
  padding: 23px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 34px;

  .order-id-cart {
    padding: 14px 0 8px 0;
    border-bottom: 1px dashed #d9d9d9;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }


  .text-success {
    color: green;
  }

  .text-danger {
    color: red;
  }
}

.recommendation {
  padding-bottom: 28px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.activities {
  margin-bottom: 25px;

  .activities-code {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #008bec;
    margin-bottom: 8px;
    display: block;
  }
}

.backRestaurant {
  padding: 16px 0px;
  outline: none;
  border: none;
  color: white;
  background: #008bec;
  border-radius: 5px;
  margin: auto;
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.0125em;
}

// -----------------Guest detail error messages-----------
.errormsg-guest {
  margin-top: 6px;
  color: red;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
}

.wrong-number-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  margin: 12px 0 10px 0 !important;
  display: block;
}

//------------------ Validation/Checkin error--------------------------

.validation-checkin {
  margin-bottom: 0 !important;
}

.checkin-error-msg {
  margin: 10px 0 !important;
}

.emoji-mart {
  width: auto !important;
}

.emoji-mart-title-label {
  font-size: 16px !important;
}

.message-input {
  .emoji-mart {
    z-index: 99;
    position: absolute;
    right: -47px;
    bottom: 110%;
    border: none;
    width: 377px !important;
  }
}

.cart-back-btn {
  color: #001f4d;
  padding: 20px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
  margin-bottom: 20px;

  .cart-btn-heading {
    margin-left: 5px;
  }
}

.visit-again {
  text-align: center;
  padding: 49px 42px 49px 42px;

  p {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    line-height: 17px;
    word-spacing: 2px;

    .thankyou-text {
      color: #436cf3;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}


// --------------------OTP for checkin guest start-------------------booking-expansion-table------------------------------------------------
.resend {
  display: inline-block;
  cursor: pointer;
  display: inline-block !important;
}

.resend-otp {
  color: #e79797 !important;
  text-transform: uppercase;
  margin-left: 8px;
  cursor: pointer;
}

.otp-section {
  margin: 15px 0 36px 0;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}

// --------------------OTP for checkin guest end-------------------------------------------------------------------

// .close-icon-checkin{

// }

.mian-container-wrapper {
  .booking-detail {
    .country-code-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.6rem 0;
      margin-bottom: 45px;

      mat-select {
        width: 40%;
        margin-right: 10px;
        padding: 17px 10px;
        height: 50px;
        border-radius: 3px;
        border: 1px solid #e9e9e9;
        background-color: #e9e9e9;
      }

      input {
        width: 60%;
        border-radius: 3px;
        padding: 15px 10px;
        border: 1px solid #e9e9e9;



        &::placeholder {
          color: black;
        }
      }
    }
  }

}

//reverify mail input box 
.verify-mail-input {
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid $gray-1ight-shade;
  font-size: 18px;
  color: #646F79;
  padding: 10px;
  border-radius: 5px;
}

.verify-mail-button {
  background: #535149 !important;
  color: $white !important;
  border-radius: 5px !important;
  cursor: pointer;
  font-weight: 500 !important;
  font-size: 16px !important;
}


// -------------------------------------Service Booking----------------------------------------------------

.booking-service-button {
  font-weight: 600;
}

.booking-service-guest {
  padding: 0 15px;
  margin-bottom: 53px;

  mat-tab-header {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label-content {
            color: #535149;
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            opacity: 1;
          }
        }

        mat-ink-bar {
          background: $black;
        }
      }
    }
  }

  .mat-tab-body-wrapper {
    margin-top: 49px;

    .mat-tab-body-content {
      display: flex;
      flex-wrap: wrap;
    }
  }
}



// Billing History guest


.billing-history-wrapper {
  max-width: 1250px !important;
  margin: 30px auto;
  // padding: 30px;
  border-radius: 4px;
  background: #FFF;
}

.billing-history-guest {
  margin-top: 30px;

  .overview-text {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.60);
  }

  .history-heading {
    margin-top: 24px;
    margin-bottom: 33px;
  }


  .booking-info {

    mat-accordion {
      margin-bottom: 8px;
      display: block;
      border: 1px solid #FFF;

      mat-expansion-panel {
        background: #F6F6F6;
        box-shadow: none;

        mat-expansion-panel-header {
          padding: 16px 18px;

          mat-panel-title {
            font-size: 16px;
            font-weight: 400;
            color: $black;
            display: flex;
            align-items: center;

            &::before {
              content: "";
              width: 6px;
              height: 6px;
              display: inline-block;
              margin-right: 8px;
              background: $black;
              border-radius: 50%;
            }
          }
        }

        .mat-expansion-panel-body {
          overflow: auto;

          .booking-expansion-table {
            width: 100%;
            box-shadow: none;


            thead {
              tr {
                height: 45px;

                th {
                  padding: 24px 32px;
                  font-size: 16px;
                  font-weight: 600;
                  font-family: "Nunito";
                  color: black;
                  white-space: nowrap;

                  &:nth-child(1) {
                    text-align: left;
                  }
                }
              }
            }

            tbody {
              .example-element-row {
                width: 100%;
                height: 45px;
                margin-bottom: 8px;




                td {
                  padding: 12px 32px;
                  font-size: 16px;
                  font-weight: 400;
                  font-family: "Nunito";
                  vertical-align: middle;
                  height: 48px;
                  white-space: nowrap;


                  .details-button {
                    color: #008BEC;
                    width: auto;
                    line-height: 0;
                    height: auto;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: "Nunito";

                  }

                  .paid {
                    color: #49CA6D;
                    display: flex;
                    align-items: center;

                    &::before {
                      content: "";
                      height: 9px;
                      display: inline-block;
                      width: 9px;
                      margin-right: 5px;
                      border-radius: 50%;
                      overflow: hidden;
                      background: #49CA6D;
                    }
                  }

                  .unpaid {
                    color: red;
                    display: flex;
                    align-items: center;

                    &::before {
                      content: "";
                      height: 9px;
                      display: inline-block;
                      width: 9px;
                      margin-right: 5px;
                      border-radius: 50%;
                      overflow: hidden;
                      background: red;
                    }
                  }

                  &:nth-child(1) {
                    text-align: left;
                  }



                }
              }
            }

            .table-without-header {
              thead {
                display: none;
              }
            }

            .internal-booking-table {
              tr {
                th {
                  padding: 10px 0;
                  width: 22%;
                  text-align: left;
                  background: white;
                  white-space: nowrap;

                  &:nth-child(1) {
                    padding: 6px 0 11px 32px;
                  }
                }
              }

              tr {
                td {
                  font-family: "Nunito";
                  font-size: 14px;
                  padding: 10px 0;
                  font-weight: 400;
                  height: 48px;
                  vertical-align: middle;
                  text-align: left;
                  white-space: nowrap;

                  &:nth-child(1) {
                    padding: 0 32px;
                  }
                }

                &:nth-child(2n+1) {
                  background: #F3F3F3;
                }
              }

            }
          }

          &::-webkit-scrollbar {
            width: 1px;
            background-color: green;
          }

          &::-webkit-scrollbar-thumb {
            width: 1px;
            background-color: gray;
          }

          &::-webkit-scrollbar-track {
            background-color: $white;
          }
        }
      }
    }
  }

}

.download-section {
  margin-top: 32px;
  text-align: end;

  .download-btn {
    padding: 12px 18px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.60);
    border: 1.5px solid #C5C5C5;
    background: $white;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }

  .pay-btn {
    padding: 12px 18px;
    border-radius: 4px;
    background: #018BED;
    font-size: 18px;
    font-weight: 700;
    border: none;
    outline: none;
    color: $white;
    cursor: pointer;
  }

  .pay-btn:disabled {
    background: #018BED80;
    color: $white;
    cursor: not-allowed;
  }
}

.view-order {
  .header-wrapper {
    padding: 14px 21px;
    border-bottom: 1px solid #D9D9D9;

    .info-text {
      font-size: 16px;
      font-weight: 400;
      color: $black;
    }
  }

  .order-request {
    padding: 21px;
    color: $black;

    .request-data {
      margin-left: 3px;
      color: $black;
    }
  }

  .assign-chef-dropdown {
    width: 100%;
    margin-top: 10px;

    .manage-order-selection {
      width: 100%;
      padding-bottom: 0;
      border-radius: 5px;

      .mat-form-field-flex {
        .mat-form-field-infix {
          padding: 0;



          .mat-chip-list {
            .mat-chip-list-wrapper {
              padding: 10px;
              border-radius: 5px;
              background: #F3F3F3;
              margin: 0;
              gap: 4px;

              .mat-chip-input {
                padding: 0 5px;
                color: #898989;
              }

              .mat-chip {
                height: 35px;
                font-size: 14px;
                font-family: 'Nunito';
                color: #001F4D;
                margin: 0;
                padding: 8px;
                border-radius: 4px;
                background: rgba(0, 24, 71, 0.10);

                .mat-icon {
                  opacity: unset;
                  color: $black;
                }
              }
            }
          }

          .mat-form-field-label-wrapper {
            top: -3px;
            left: 20px;

            .mat-form-field-label:focus {
              color: #AAA;
            }

            .mat-form-field.mat-focused .mat-form-field-label {
              color: #898989;
            }
          }
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }

  .assign-chef-btn-grp {
    padding: 21px;
    text-align: right;

    .chef-btn {
      height: 40px;
      padding: 8px 29px;
      border-radius: 4px;
      border: 1px solid #EAEAEA;
      background: $white;
      font-size: 14px;
      cursor: pointer;
    }

    .reset-chef {
      border: 1px solid #EAEAEA;
      background: #FFF;
      margin-right: 8px;
      color: #001847;
    }

    .apply-chef {
      border: none;
      background: #001847;
      color: $white;
    }
  }


  .order-table {
    padding: 21px;

    table {
      border-collapse: collapse;
      border-radius: 3px;
      border: 1px solid #D9D9D9;
      width: 100%;

      tr {
        th {
          width: 60%;
          background: #EEEEEE;
          padding: 11px 40px;
          font-family: "Nunito", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;

          &:nth-child(2) {
            text-align: center;
          }
        }
      }

      tr {
        td {
          width: 40;
          padding: 8px 40px;

          &:nth-child(2) {
            text-align: center;
          }
        }

        &:nth-child(2n+1) {
          background-color: #FAFAFA;
        }
      }
    }


  }
}

.manage-service-filter-popup {
  border-radius: 8px;
  background: #FFF;

  .filter-popup-header {
    padding: 10px 21px;
    border: 1px solid #D9D9D9;
  }

  .requested-quantity {
    padding: 16px 21px 12px 16px;

    .quantity-filter-popup-input {
      width: 48%;
      padding: 12px 6px;
      border-radius: 3px;
      border: 1px solid #E9E9E9;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    .quantity-dropdown-popup {
      width: 48%;
      padding: 12px 6px;
      border-radius: 3px;
      border: 1px solid #E9E9E9;
    }
  }

  .purpose {
    padding: 0px 21px 34px 16px;

    .quantity-dropdown-popup {
      padding: 12px 6px;
      border-radius: 3px;
      border: 1px solid #E9E9E9;
    }

  }

  .cancel {
    padding: 8px 21px 12px 21px;
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.15);

    .pop-up-btn-group {
      button {
        padding: 8px 29px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid #D5D5D5;

        &:first-child {
          background: $white;
          color: $black;
        }

        &:nth-last-child(1) {
          background: #001847;
          margin-left: 9px;
          color: $white
        }

        &:nth-last-child(2) {
          background: #001847;
          margin-left: 9px;
          color: $white
        }
      }
    }
  }

}

.item-restock-confirmation {
  padding: 27px;

  svg {
    text-align: center;
    margin-bottom: 25px;
  }

  span {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: $black;
  }
}

.inventory-filter-section {


  .filter-button-wrapper {
    position: relative;
    cursor: pointer;

    button {
      border-radius: 4px;
      border: 1px solid #DDD;
      padding: 11px 9px 12px 40px;
      outline: none;
      color: #888888;
      height: 38px;
      min-width: 95px;
      background-color: $white;
      cursor: pointer;
    }

    .mat-filter-btn-icon {
      position: absolute;
      top: 25%;
      left: 6px;
      color: #888888;
      font-size: 20px;
    }
  }
}



.inventory-table-wrapper {

  .inventory-table {
    width: 100%;
    overflow: auto;

    table {
      box-shadow: none;
      border-radius: 4px;

      thead {
        tr {
          background-color: #F8FBFF;
          border: 1px solid #DDDDDD;

          th {
            height: inherit;
            padding: 10px 10px;
            white-space: nowrap;
            color: black;
            vertical-align: middle;
            font-family: "Nunito";
            font-size: 16px;
            font-weight: 700;
            border-bottom-width: 0;

            &:nth-child(1) {
              padding-left: 20px;
            }

            &:nth-child(8) {
              text-align: center;
            }

            &:nth-child(9) {
              text-align: center;
              padding-right: 20px;
            }

          }
        }
      }

      tbody {
        tr {
          border: 1px solid #DDDDDD;

          td {
            line-height: 25px;
            padding: 12px 10px;
            vertical-align: middle;
            color: black;
            white-space: nowrap;
            font-family: "Nunito";
            font-size: 14px;
            font-weight: 600;

            .declined {
              width: 84px;
              height: 29px;
              line-height: initial;
              color: #D41E1E;
              border-radius: 4px;
              background: rgba(212, 30, 30, 0.20);
              border-radius: 4px;
              border: none;
              outline: none;
              font-size: 14px;
              margin-right: 4px;
              cursor: pointer;
            }

            .approved {
              width: 84px;
              height: 29px;
              line-height: initial;
              color: #49CA6D;
              border-radius: 4px;
              background: rgba(73, 202, 109, 0.20);
              border: none;
              outline: none;
              font-size: 14px;
              margin-right: 4px;
              cursor: pointer;
            }

            .pending {
              width: 84px;
              height: 29px;
              line-height: initial;
              color: #F79E1C;
              border-radius: 4px;
              background: rgba(247, 158, 28, 0.20);
              border: none;
              outline: none;
              font-size: 14px;
              margin-right: 4px;
              cursor: pointer;
            }

            .info {
              color: #D41E1E;
              font-size: 14px;
              background: none;
              height: 14px;
              width: 14px;
              cursor: pointer;

            }

            .print-wrapper {
              gap: 17px;

              .print-status {
                width: 24px !important;
                height: 24px !important;

                svg {
                  width: 100% !important;
                  height: 100% !important;

                  circle {
                    stroke: $black;
                  }
                }
              }
            }

            .print-visibility {
              font-size: 24px;
            }

            .paid {
              color: #49CA6D;
              display: flex;
              align-items: center;

              &::before {
                content: "";
                height: 9px;
                display: inline-block;
                width: 9px;
                margin-right: 5px;
                border-radius: 50%;
                overflow: hidden;
                background: #49CA6D;
              }
            }

            .unpaid {
              color: red;
              display: flex;
              align-items: center;

              &::before {
                content: "";
                height: 9px;
                display: inline-block;
                width: 9px;
                margin-right: 5px;
                border-radius: 50%;
                overflow: hidden;
                background: red;
              }
            }

            &:nth-child(1) {
              padding-left: 20px;
            }

            &:nth-child(9) {
              text-align: center;
              padding-right: 20px;
            }


          }

          &.example-detail-row {
            height: auto;
          }
        }
      }
    }

    .inventory-pagination {
      margin-top: 10px;
      background: #ffffff;
    }
  }
}

// Inventory-filter start

.inventory-filter-wrappers {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0.7;
  background: black;
  cursor: pointer;
}

.filter-wrapper {
  width: 380px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transition: left 0.3s ease;
  background: #FFF;
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.04);

  .filter-header {
    padding: 16px;
  }

  .filter-date-wrapper {
    padding: 16px;

    .filter-date-text {
      display: block;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .date-input {
      margin-bottom: 28px;

      .calnedar-input-wrapper {
        position: relative;

        .input-calendar {
          padding: 16px;
          border-radius: 4px;
          width: 100%;
          border: 1px solid #E0E0E0;

          &::placeholder {
            font-family: "Nunito";
            color: #898989;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .input-calendar-icon {
          position: absolute;
          top: 25%;
          right: 16px;
          cursor: pointer;
        }
      }

    }

    .request-status {
      width: 100%;
      margin-bottom: 28px;

      mat-form-field {
        width: 100%;

        .mat-form-field-wrapper {
          padding-bottom: 0;

          .mat-form-field-flex {
            .mat-form-field-infix {
              padding: 0;
              border-top: 0;

              mat-select {
                width: 100%;
                padding: 16px;
                background: #ffffff;
                border-radius: 4px;
                border: 1px solid #E0E0E0;
                height: 48px;

                &::placeholder {
                  color: #898989 !important;
                  font-size: "Nunito";
                }
              }

              .manage-order-filter-input {
                width: 100%;
                padding: 16px;
                background: #ffffff;
                border-radius: 4px;
                border: 1px solid #E0E0E0;
                height: 48px;
                caret-color: unset;
              }

              .mat-form-field-label-wrapper {
                top: 0;
                left: 16px;
                padding-top: 0;
              }
            }
          }

          .mat-form-field-underline {
            display: none;
          }
        }
      }

      .order-type-field {
        padding-bottom: 0;
        padding: 12px 20px;
        border-radius: 4px;
        border: 1px solid #E0E0E0;
        background: #FFF;

        .mat-form-field-flex {
          .mat-form-field-infix {
            padding: 0;
            border-top: 0;

            .mat-chip-list {
              .mat-chip-list-wrapper {
                margin: 0;
                gap: 4px;

                .mat-chip-input {
                  padding: 0 5px;
                  color: #898989;
                }

                .mat-chip {
                  font-size: 14px;
                  font-family: 'Nunito';
                  color: #001F4D;
                  margin: 0;
                  padding: 8px;
                  border-radius: 4px;
                  background: #FFC90C;

                  .mat-icon {
                    opacity: unset;
                    color: $black;
                  }
                }
              }
            }

            .mat-form-field-label-wrapper {
              top: -13px !important;
              left: -6px !important;
              padding-top: 0.84375em !important;

              .mat-form-field-label:focus {
                color: #AAA;
              }

              .mat-form-field.mat-focused .mat-form-field-label {
                color: #898989;
              }
            }
          }
        }

        .mat-form-field-underline {
          display: none;
        }
      }
    }

    .filter-btn-wrapper {
      .reset {
        padding: 8px 29px;
        border-radius: 4px;
        border: 1px solid #EAEAEA;
        background: #FFF;
        margin-right: 9px;
      }

      .apply {
        border-radius: 4px;
        padding: 8px 29px;
        border-radius: 4px;
        border: 1px solid #EAEAEA;
        background: #001847;
        color: #FFFFFF;
      }
    }
  }
}


// Manage-inventory-start

.inventory-request-wrapper {
  .mat-tab-header {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            color: #001847;
            font-family: "Nunito";
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .request-table-wrapper {
    margin-top: 24px;

    .request-table {
      box-shadow: none;

      thead {
        tr {
          th {
            vertical-align: middle;
            font-size: 16px;
            font-weight: 700;
            background: #F8FBFF;
            font-family: "Nunito";
            padding: 0 10px;
            line-height: 25px;
            color: $black;
            border-radius: 4px;
            white-space: nowrap;
            border: 1px solid #DDD;
            border-width: 1px 0 1px 0px;

            &:nth-child(6) {
              text-align: center;
            }

            &:nth-child(7) {
              text-align: center;
            }

          }
        }
      }

      tbody {
        tr {
          height: 75px !important;

          td {
            vertical-align: middle;
            font-size: 14px;
            font-weight: 600;
            font-family: "Nunito";
            color: $black;
            line-height: 25px;
            border-radius: 4px;
            white-space: nowrap;
            padding: 0 10px;
            border: 1px solid #DDD;
            border-width: 1px 0 1px 0px;

            .approved {
              color: #49CA6D;
              font-size: 14px;
              font-weight: 600;
              font-family: "Nunito";
              margin-right: 5px;
              display: inline-flex;
              cursor: pointer;
              position: relative;

              &::before {
                content: '';
                background-color: #49CA6D;
                overflow: hidden;
                border-radius: 100%;
                height: 6px;
                width: 6px;
                position: absolute;
                top: 25%;
                left: -10px;
                display: block;
                margin-right: 7px;
              }
            }

            mat-form-field {
              .mat-form-field-wrapper {
                padding-bottom: 0;

                .mat-form-field-flex {
                  .mat-form-field-infix {
                    padding: 0;
                    border-top: 0;

                    mat-select {
                      width: 100%;
                      padding: 4px 6px;
                      background: #ffffff;
                      border-radius: 4px;
                      border: 1px solid #E0E0E0;
                      height: 29px;

                      &::placeholder {
                        color: #898989 !important;
                      }
                    }

                    .mat-form-field-label-wrapper {
                      top: -11px;
                      left: 6px;
                      padding-top: 0;
                      overflow: visible;
                    }
                  }
                }

                .mat-form-field-underline {
                  display: none;
                }
              }
            }

            .declined {
              color: #D41E1E;
              font-size: 14px;
              font-weight: 600;
              font-family: "Nunito";
              margin-right: 5px;
              display: inline-flex;
              cursor: pointer;
              position: relative;

              &::before {
                content: '';
                background-color: #D41E1E;
                overflow: hidden;
                border-radius: 100%;
                height: 6px;
                width: 6px;
                position: absolute;
                top: 25%;
                left: -10px;
                display: block;
                margin-right: 7px;
              }
            }

            &:nth-child(7) {
              text-align: center;
            }

          }
        }
      }
    }

    .inventory-pagination {
      margin-top: 10px;
      background: #ffffff;
    }
  }
}

// Manage-inventory

.inventory-request-decline-wrapper {
  .popup-header {
    padding: 13px 20px;
    border-bottom: 1px solid #D9D9D9;
  }

  .reason-wrapper {
    padding: 20px;

    .reason {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: $black;
      margin-bottom: 9px;
    }

    .dropdown-declined {
      width: 100%;
      border-radius: 3px;
      border: 1px solid #E9E9E9;
      padding: 13px;

      .mat-form-field-wrapper {
        padding: 0;


        .mat-form-field-flex {
          .mat-form-field-infix {
            background: $white;
            padding: 0;
            border-top: 0;

            .mat-form-field-label-wrapper {
              padding: 0;

              .mat-form-field-label {
                mat-label {
                  display: none;
                }
              }
            }
          }
        }


        .mat-form-field-underline {
          display: none;
        }


      }
    }

    .textbox {
      width: 100%;
      padding: 13px;
      border-radius: 3px;
      border: 1px solid #E9E9E9;
      background: rgba(217, 217, 217, 0.10);
      margin-bottom: 35px;
      background: $white;
    }


  }

  .buttons {
    padding: 10px 20px;
    border-top: 1p solid rgba(0, 0, 0, 0.25);

    .decline-button {
      padding: 8px 29px;
      cursor: pointer;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
    }

    .cancel {
      background: $white;
      color: $black;
      border: 1px solid #D5D5D5;
      margin-right: 9px;
    }

    .submit {
      background: #001847;
      color: $white;
      border: none;
    }
  }
}



.restocking-tooltip {
  color: $black !important;
  background-color: $white;
  font-family: "Nunito";
  font-size: 16px;
  padding: 5px;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.14);
  outline: none;
}


.status-tooltip {
  background-color: black !important;
  color: white !important;
  border-radius: 5px;
  padding: 6px 24px !important;
  font-size: 14px !important;
  line-height: 22px;
}

// Inventory Filter admin

.listing-filter {
  width: 340px;
  height: 389px;
  box-shadow: none;
  border-radius: unset !important;

  .tab-panel {
    .mat-tab-group {
      .mat-tab-body-wrapper {
        .mat-tab-body {
          .mat-tab-body-content {

            .cutom-date-range {
              .range-wrapper {
                padding: 10px 20px;
                gap: 9px;

                .filter-input {
                  .filter-date-text {
                    display: block;
                    margin-bottom: 8px;
                  }

                  .input-range {
                    border-radius: 3px;
                    border: 0.7px solid #E0E0E0;
                    background: #FFF;
                    max-width: 136px;
                    padding: 7px 12px;
                  }
                }
              }

              .custom-calender {
                .mat-calendar-header {
                  .mat-calendar-controls {
                    justify-content: space-between;

                    .mat-calendar-period-button {
                      order: 2;
                    }

                    .mat-calendar-spacer {
                      display: none;
                    }

                    .mat-calendar-previous-button {
                      order: 1;
                    }

                    .mat-calendar-next-button {
                      order: 3;
                    }


                  }
                }

                .mat-calendar-content {
                  padding: 10px 20px;
                }
              }



            }

            .mat-list-item-content {
              font-family: "Nunito", sans-serif;

              &:hover,
              :focus {
                background-color: #bfbfbf1a;
                color: $black
              }
            }
          }
        }
      }
    }

    .filter-btn-wrapper {
      padding: 10px 20px;

      .cal-btns {
        max-width: 67px;
        height: 32px;
        border-radius: 2px;
        cursor: pointer;
      }

      .cancel {
        color: black;
        background-color: white;
        border: 1px solid #001847;
        font-size: 13px;
        font-family: "Roboto";
      }

      .apply {
        color: white;
        background-color: #001847;
        margin-left: 9px;
        border: none;
        font-size: 13px;
        font-family: "Roboto";
      }
    }
  }
}


//Booking Table In mobile in Admin Booking Request


.booking-request-mobile {
  display: flex;
  flex-direction: column;
  height: 100%;


  .popup-header {
    padding: 13px 21px;
    border-bottom: 1px solid #D9D9D9;
  }

  .service-name-wrapper {
    padding: 10px 21px;
  }

  .internal-booking-table-wrapper {
    padding: 0px 21px 10px;

    .internal-booking-table-mobile {
      width: 100%;
      box-shadow: none;

      thead {
        tr {
          height: 34px;
          background-color: #E6E8ED;
          padding: 8px 7px;
          border-radius: 5px;

          th {
            padding: 0 5px;
            font-size: 14px;
            vertical-align: middle;
            font-weight: 600;
            color: $black;
            font-family: "Nunito";

            &:first-child {
              padding-left: 12px !important;
            }

            &:nth-child(2) {
              text-align: center;
            }

            &:nth-child(3) {
              text-align: center;
            }

            &:last-child {
              padding-right: 12px !important;
              text-align: center;
            }

          }
        }
      }

      tbody {
        tr {
          height: 38px;

          td {
            padding: 0;
            padding: 0 5px;
            font-size: 14px;
            font-weight: 400;
            color: $black;
            vertical-align: middle;
            font-family: "Nunito";
            letter-spacing: 0.28px;


            &:first-child {
              padding-left: 12px !important;
            }

            &:nth-child(2) {
              text-align: center;
            }

            &:nth-child(3) {
              text-align: center;
            }

            &:last-child {
              padding-right: 12px !important;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .popup-footer {
    padding: 10px 21px;
    margin-top: auto;
    border-radius: 0px 0px 4px 4px;
    background: #FFF;
    text-align: right;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);


    .dismiss-button {
      border-radius: 4px;
      border: none;
      background-color: #001847;
      color: $white;
      padding: 8px 29px;
    }
  }
}


// Manage order wrapper start

.manage-wrapper {

  .manage-dropdown-headline {
    .mat-form-field {
      .mat-form-field-wrapper {
        .mat-form-field-infix {
          padding: 8px 5px;

        }
      }
    }
  }

  .order-searchbar-wrapper {
    position: relative;
    margin-left: 12px;

    .order-search {
      border-radius: 4px;
      border: 1px solid #DDD;
      padding: 9px 8px 9px 28px;
      outline: none;
      min-width: 300px;
      margin-right: 12px;

      &::placeholder {
        font-family: "Nunito";
        font-size: 14px;
        font-weight: 600;
      }
    }

    .filter-search-icon {
      position: absolute;
      left: 9px;
      top: 26%;
      font-size: 16px;
      color: #888888;
    }
  }

  .filter-button-wrapper {
    position: relative;
    cursor: pointer;

    button {
      border-radius: 4px;
      border: 1px solid #DDD;
      padding: 11px 9px 12px 28px;
      outline: none;
      color: #888888;
      height: 38px;
      min-width: 95px;
      background-color: $white;
      cursor: pointer;
    }

    .mat-filter-btn-icon {
      position: absolute;
      top: 25%;
      left: 6px;
      color: #888888;
      font-size: 20px;
    }
  }

  .filter-print-button {
    margin-left: 20px;

    .mat-slide-toggle-label {
      flex-direction: row-reverse;
      gap: 10px;

      .mat-slide-toggle-bar {
        width: 48px;
        height: 24px;
        background: #D9D9D9;

        .mat-slide-toggle-thumb-container {
          .mat-slide-toggle-thumb {
            height: 20px;
            width: 20px;

            &::after {
              display: none;
            }

            &::before {
              display: none;
            }
          }

          .mat-slide-toggle-ripple {
            display: none;
          }
        }
      }

      .mat-slide-toggle-content {
        font-size: 16px;
        font-weight: 400;
        font-family: "Nunito", sans-serif;
        color: $black;
        overflow: visible;
        display: flex;
        align-items: center;

        .restro-toggle-text {
          font-size: 16px;
          color: $black;

        }

        .tooltip {
          margin-left: 10px;


          .tooltiptext {
            width: 198px;
            font-size: 12px;
            padding: 10px;
            color: #000;
            top: 30px;
            right: -56px;
            left: unset;
            text-align: left;
            overflow: hidden;
            white-space: pre-wrap;
          }
        }
      }
    }

    &.mat-slide-toggle.mat-checked:not(.mat-disabled) {
      .mat-slide-toggle-thumb-container {
        transform: translate3d(23px, 0, 0);
      }
    }
  }

  .tiles-section {
    margin-top: 27px;

    .tiles-wrapper {
      border-radius: 10px 10px 0px 0px;
      background: #E7E7E7;
      height: 100%;

      .order-content {
        .order-header {
          border-radius: 10px 10px 0px 0px;
          border-bottom: 1px solid #D9D9D9;
          padding: 16px 0px 16px 24px;
          background: #FFF;

          h4 {
            margin: 0 22px 0 0;
            text-transform: uppercase;
            line-height: 26px;
          }

          .order-left {
            border-radius: 10px;
            background: #F1F1F1;
            padding: 8px 10px;
            color: #000000;
            height: 27px;
            width: 29px;
            display: block;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .order-content {
          padding: 20px 22px 20px 23px;

          .order-card-wrapper {
            padding: 12px;
            border-radius: 8px;
            background: #FFF;
            margin-bottom: 10px;

            .order-card {
              .manage-order-text-wrapper {
                padding-bottom: 12px;

                .staff-chief {
                  color: #436CF3;
                  text-decoration: underline;
                }

                .assigned-staff-button {
                  border: none;
                  border-radius: 4px;
                  background: #436CF3;
                  padding: 4px 6px;
                  font-size: 14px;
                  min-height: 22px;
                  color: $white;
                }

                .order-type-chip {
                  border-radius: 4px;
                  background: #FFC90C;
                  padding: 5px;
                  font-weight: 500;
                  word-wrap: break-word;
                  display: block;
                  font-family: 'Nunito' !important;
                  margin-left: 5px;
                  min-height: 22px;
                  height: fit-content;
                }
              }


              .table-manage-order-wrapper {

                .assigned-chip {
                  border-radius: 6px;
                  background: #001847;
                  font-size: 14px;
                  padding: 7px 8px;
                  display: flex;
                  text-transform: uppercase;
                  color: $white;
                  border: none;
                }
              }

              .manage-order-accordion {
                .manage-order-expansion {
                  box-shadow: none;

                  mat-expansion-panel-header {
                    padding: 0;
                    height: fit-content;

                    .manage-order-title {
                      justify-content: flex-end;
                      color: #436CF3;
                      font-size: 16px;

                    }
                  }

                  .mat-expansion-panel-content {
                    .mat-expansion-panel-body {
                      padding: 6px 0px;

                      .mat-stepper-horizontal {
                        .mat-horizontal-stepper-header-container {

                          .mat-step-header {
                            padding: 0 0px;
                            height: fit-content;

                            .mat-step-icon {
                              display: none;
                            }

                            .check-circle-icon {
                              color: #49CA6D;
                            }

                            .unCheck-circle-icon {
                              color: #E8E8E8;
                            }

                            .timer-input {
                              border: 1px solid #D9D9D9;
                              cursor: pointer;
                              width: 100%;
                              height: 22px;

                              .mat-select-value {
                                position: relative;
                                left: 4px;
                                font-size: unset;
                              }

                              &::placeholder {
                                color: red;
                                text-decoration: underline;

                              }
                            }

                            .mat-step-label {
                              font-size: 12px;
                              font-weight: 600;
                              font-family: 'Nunito' !important;
                              color: $black;

                              .mat-step-text-label {
                                display: flex;
                                align-items: center;
                                background: white;
                                padding: 0 8px;

                                .manage-order-timer {
                                  border-radius: 2px;
                                  background: rgba(0, 139, 236, 0.12);
                                  padding: 3px;
                                  margin-right: 3px;
                                  height: 15px;
                                  width: auto;
                                  line-height: 10px;
                                  color: #00000099;
                                }
                              }
                            }

                            &:hover {
                              background-color: unset !important;
                            }
                          }
                        }

                        .mat-horizontal-content-container {
                          display: none;
                        }
                      }




                      .order-table-wrapper {
                        margin-bottom: 10px;

                        .manage-order-table {
                          thead {
                            tr {
                              height: 38px;

                              th {
                                vertical-align: middle;
                                background: #EEEEEE;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                letter-spacing: 0.2px;
                                color: $black;

                                &:nth-child(2) {
                                  text-align: center;
                                }
                              }
                            }
                          }

                          tbody {
                            tr {
                              height: 38px;

                              td {
                                vertical-align: middle;
                                background: #ffffff;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                letter-spacing: 0.2px;

                                &:nth-child(2) {
                                  text-align: center;
                                }
                              }

                              &:nth-child(even) {
                                background-color: orange !important;
                              }
                            }
                          }
                        }
                      }

                      .additional-request-accordion {
                        margin-bottom: 12px;
                        display: block;

                        .additional-request-panel {
                          box-shadow: none;
                          border: 1px solid #D9D9D9;

                          .additional-request-header {
                            padding: 6px 10px;
                            background-color: #EEEEEE;
                          }

                        }
                      }

                      .timer-warning {
                        margin-bottom: 12px;

                        .order-warning-text {
                          color: #DD1A1A;

                          &.exceed {
                            margin-left: 7px;
                          }
                        }
                      }

                      .order-preparing-button {
                        padding: 6px 0;
                        border: none;
                        outline: none;
                        font-size: 16px;
                        min-height: 38px;
                        border-radius: 3px;
                        margin-bottom: 10px;
                        cursor: pointer;

                        &:nth-last-child(2) {
                          background: #F29D38;
                          color: $white
                        }

                        &:nth-last-child(1) {
                          background: $white;
                          color: black;
                          border: 1px solid #DD1A1A;
                        }
                      }

                      .bg-ready {
                        background-color: #49CA6D !important;
                      }
                    }
                  }
                }
              }
            }



          }

          .order-disabled {
            color: #A0A0A0 !important;
            pointer-events: none;

            .Cancelled {
              border-radius: 13px;
              border: 1px solid #D41E1E;
              background: $white;
              color: #D41E1E;
              padding: 3px 6px;
              font-size: 12px;
              font-weight: 700;
            }

            .Served {
              border-radius: 13px;
              border: 1px solid #228B22;
              background: $white;
              color: #228B22;
              padding: 3px 6px;
              font-size: 12px;
              font-weight: 700;
            }

            .show-more-orders {
              color: #8095D7 !important;
            }

            .order-type-chip {
              color: #A0A0A0 !important;
            }

            .assigned-staff-button {
              background: #0018474D !important;
            }

            .assigned-chip {
              background: #0018474D !important;
            }

          }
        }
      }
    }
  }

  .tiles-section-mobile {
    display: none;
  }
}


// Track your Order Start

.order-tracking-wrapper {
  max-width: 816px;
  width: 100%;
  padding: 20px;
  margin: 40px auto;
  background: #FFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);


  .guest-edit-profile-icon {
    color: #BBBBBB;
    font-size: 24px;
  }


  .order-tracking-title {
    .track-order-icon {
      width: 56px;
      height: 56px;
      background: #0DAC50;
      border-radius: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 8px auto;
    }

    .track-order-title {
      font-size: 20px;
    }
  }


  .track-back-btn {
    padding: 20px 0;
  }

  .order-tracking-progressbar {
    .stepper-wrapper {
      margin-top: 24px;
      padding: 27px 76px 27px 48px;
      border-radius: 4px;
      background: #F9FCFF;
    }

    .mat-stepper-horizontal {
      .mat-horizontal-stepper-header-container {
        background: #F9FCFF;

        .mat-step-header {
          padding: 0 0px;
          height: fit-content;

          .mat-step-icon {
            display: none;
          }

          .check-circle-icon {
            background: #0DAC50;
            color: white;
            overflow: hidden;
            border-radius: 100%;
            width: 32px !important;
            height: 32px !important;
            font-size: 32px !important;
          }

          .mat-step-label {
            font-size: 12px;
            font-weight: 600;
            font-family: 'Nunito' !important;
            color: #9C9C9C;

            .mat-step-text-label {
              display: flex;
              align-items: center;
              flex-direction: column;
              padding: 0 8px;

            }

            .greenText {
              color: #0DAC50;
            }

            .grey {
              width: 32px;
              height: 32px;
              border-radius: 100%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid lightgray;
              color: #9C9C9C;
            }

            .green {
              background-color: #0DAC50 !important;
              color: $white
            }
          }

          &:hover {
            background-color: unset !important;
          }
        }
      }

      .mat-stepper-horizontal-line {
        margin: 0;
        border-top-width: 3px;
      }


      .mat-horizontal-content-container {
        display: none;
      }
    }

    .order-delay-warning {
      color: #F29D38;
      text-align: center;
      width: 100%;
      margin-top: 24px;
    }

    .tracking-order-details {
      margin: 24px 0;

      h1 {
        margin-bottom: 16px;
      }

      .info-wrapper {
        margin-bottom: 12px;
        width: 50%;
        font-size: 16px;
        font-family: "Nunito", sans-serif;
      }
    }

    .tracking-order-details-table-wrapper {
      padding: 14px;
      border: 1px solid #E0E0E0;

      .mat-elevation-z8 {
        box-shadow: none;
      }

      .tracking-order-details-table {
        width: 100%;

        thead {
          th {
            vertical-align: middle;
            border: none;
            font-size: 14px;
            font-family: "Nunito", sans-serif;
            color: $black;

            &:last-child {
              text-align: center;
            }
          }
        }

        tbody {
          tr {

            td {
              vertical-align: middle;
              border: none;
              font-size: 14px;
              font-family: "Nunito", sans-serif;
              color: $black;

              &:last-child {
                text-align: center;
              }
            }

            &:nth-child(odd) {
              background-color: #F3F3F3;
            }
          }
        }
      }
    }
  }
}



.edit-profile-subheading {
  color: #333333;
  display: block;
  margin-top: 7px;
  font-size: 16px;
}

.progressbar-wrapper {
  position: relative;
  margin-bottom: 32px;

  .profile-progressbar {
    margin: 16px auto 10px;
    border-radius: 7px;
    height: 8px !important;
  }

  .mat-progress-bar-fill::after {
    background-color: #008BEC !important;
  }

  .progressbar-value {
    position: absolute;
    font-size: 14px;
    color: #333;
    margin-left: -10px;
  }
}

.complete-profile-form-wrapper {
  .edit-profile-labels {
    font-size: 14px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    margin-bottom: 6px;
  }

  .verification-wrapper {
    margin-top: 10px;

    .verification-text {
      color: #34A853;
      font-size: 16px;
      font-weight: 600;
      margin-left: 6px;
    }
  }

  .visibility-icon-wrapper {
    width: 100%;
    position: relative;

    .visibility-icon {
      position: absolute;
      top: 15px;
      right: 16px;
      color: #828282;
      font-size: 24px;
      cursor: pointer;
    }

  }

  .edit-profile-inputs {
    border-radius: 3px;
    border: 1px solid #E9E9E9;
    background: rgba(217, 217, 217, 0.10);
    padding: 15px 16px;
    width: 100%;

    &::placeholder {
      font-family: "Nunito", sans-serif;
    }
  }


  .country-code-wrapper {
    width: 100%;

    .country-code-dropdown {
      width: 20%;
      margin-right: 17px;
      border: 1px solid #E9E9E9;
      padding: 15px 16px;
      background: rgba(217, 217, 217, 0.1)
    }

    .phone-number {
      width: 80%;

      .edit-profile-inputs {
        width: 100%;
      }
    }
  }


}

.edit-profile-btn-wrapper {

  .edit-btns {
    padding: 11px 31px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;


    &:nth-child(1) {
      background: $white;
      color: #535148;
      border: 1px solid #535148;
      margin-right: 13px;
    }

    &:nth-child(2) {
      background-color: #535148;
      color: $white;
      border: none;
    }
  }
}

// view order list start 

.order-details-guest-wrapper {
  border-radius: 8px;
  background: rgba(191, 191, 191, 0.10);
  margin-top: 38px;
  padding: 24px 45px;

  .guest-amount-detail {
    margin: 25px 0;
  }

  .order-details-guest-link {
    .order-details-guest {
      padding: 0 56px;
      border-right: 1px solid rgba(0, 0, 0, 0.10);

      h3 {
        margin: 0;
      }

      .order-status-guest {
        color: #49CA6D;

        &::before {
          content: "";
          height: 9px;
          display: inline-block;
          width: 9px;
          margin-right: 5px;
          border-radius: 50%;
          overflow: hidden;
          background: #49CA6D;
        }
      }

      .order-status-guest-status {
        color: red;

        &::before {
          content: "";
          height: 9px;
          display: inline-block;
          width: 9px;
          margin-right: 5px;
          border-radius: 50%;
          overflow: hidden;
          background: red;
        }
      }

      &:nth-child(1) {
        padding-left: 0px;
      }

      &:nth-last-child(1) {
        border-right: 0;
      }

    }
  }


  .more-details-option {
    margin-top: 28px;
    text-align: right;
    width: 100%;
    display: block;
  }
}



// view-order start

.view-order-wrapper {
  max-width: 1036px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;

  .view-order-tabs-wrapper {
    position: relative;

    .view-order-date {
      position: absolute;
      right: 20px;
      z-index: 33;
      width: 30%;
    }

    .view-order-tab-wrapper {
      .mat-tab-header {
        border-bottom: none;

        .mat-tab-label-container {
          .mat-tab-labels {
            padding: 4px;

            .mat-tab-label {
              height: auto;
              padding: 8px 16px;
              font-size: 16px;
              font-weight: 700;
              font-family: "Nunito", sans-serif;
              color: #8A8A8A;

            }

            .mat-tab-label-active {
              border-radius: 8px;
              background: #535148;
              color: $white;
            }
          }

          .mat-ink-bar {
            display: none;
          }
        }
      }
    }
  }

}




.manage-restaurant-guest-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 819px;
  height: 100vh;
  background: white;
  overflow: auto;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
  z-index: 9999;

  .restaurant-guest-header {
    background: #dde0e7;
    padding: 24px;
    position: sticky;
    top: 0;
    z-index: 99999;

    .restaurant-guest-detail-wrapper {
      justify-content: space-between;

      .restaurant-guest-detail {
        align-items: center;
        margin-bottom: 15px;

        .guest-phone-number {
          font-size: 16px;
        }

        .guest-name {
          margin: 0 29px 0 0;
        }

        .guest-chip {
          padding: 5px 6px;
          background: #001847;
          color: #fff;
          border-radius: 3px;
          text-transform: capitalize;
          margin-right: 44px;
        }
      }

    }

    .restuarant-assignee-details-wrapper {
      gap: 12px;

      .asigned-detail {
        font-size: 16px;
        color: #333;
      }

    }
  }

  .restaurant-guest-history-wrapper {
    padding: 24px;

    .guest-order-history-wrapper {
      .mat-tab-header {
        margin-bottom: 24px;

        .mat-tab-label-container {
          .mat-tab-list {
            .mat-tab-labels {
              .mat-tab-label {
                height: 40px !important;

                .mat-tab-label-content {
                  font-size: 18px;
                  font-weight: 600;
                  font-family: "Nunito", sans-serif;

                }

              }
            }
          }

        }
      }

      .mat-tab-body-wrapper {
        .inventory-table-wrapper {
          .inventory-table {
            width: 100%;

            table {
              width: 100%;

              thead {
                tr {
                  height: 50px !important;
                  border: none !important;
                }
              }

              tbody {
                tr {
                  height: 57px !important;
                  border: none !important;
                }
              }
            }
          }
        }

        .billing-amount {
          .subtotal {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        .printing-button {
          width: 100%;
          align-items: center;
          justify-content: space-between;
          margin-top: 24px;

          p {
            color: #333;
          }

          .print-button {
            border: none;
            padding: 10px 25px;
            font-size: 14px;
            border-radius: 4px;
            background: #001847;
            font-weight: 600;
            color: white;
          }
        }

        .previous-tab-header-wrapper {

          padding: 5px 22px;
          border-radius: 6px;
          background: #E6E8ED;
          margin-bottom: 24px;

          .tab-text {
            font-size: 16px;
            font-weight: 600;
            color: #000;
          }

          .restaurant-calender {
            width: 30%;

            .input-calendar {
              width: 100%;
            }
          }
        }

        .restro-guest-assignee-wrapper {
          padding: 23px 42px;
          border-radius: 6px;
          background: rgba(230, 232, 237, 0.50);
          margin-bottom: 7px;

          .restro-guest-assignee {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .restro-guest {
              display: flex;
              flex-direction: column;

              .guest-assignee-label {
                font-size: 16px;
                font-weight: 600;
                color: #000;
              }

              .guest-assignee-text {
                font-size: 16px;
                font-weight: 400;
                color: #333;
                margin-top: 5px;
                display: block;
              }
            }
          }

        }

        .restro-guest-accordion {
          margin-top: 12px;

          .restro-guest-expansion {
            box-shadow: none;
            background-color: inherit;

            .restro-guest-expansion-header {
              padding: 0;

              .mat-content {
                justify-content: space-between;
                flex-direction: row-reverse;

                .mat-expansion-panel-header-title {
                  justify-content: end;
                  margin-right: 0;
                }
              }

              .mat-expansion-indicator {
                display: none;
              }


              &:hover {
                background: none;
              }
            }

            .mat-expansion-panel-content {
              .mat-expansion-panel-body {
                padding: 0;

                .guest-restro-label {
                  font-size: 16px;
                  font-weight: 600;
                  margin-bottom: 12px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.manage-restaurant-table-mobile {
  display: none;
}


.offerings-notification::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: none;
  overflow: hidden;
  display: inline-block;
  margin-left: 12px;
  position: relative;
  top: 1px;
  box-shadow: inset 0px 0px 10px 2px rgb(255 255 255 / 30%),
    0px 0px 10px 2px rgb(255 255 255 / 30%);
  animation: pulse 0.6s alternate infinite;

}

.offerings-notification-green::after {
  background: #49CA6D !important;
}

.offerings-notification-red::after {
  background: #FFC90C !important;
}

@keyframes pulse {
  0% {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: inset 0px 0px 10px 2pxrgb(255 201 12),
      0px 0px 5px 2px rgb(255 201 12);
  }

  100% {
    background: rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 10px 2px #dfcc8a00, 0px 0px 15px 2px #fce38af0;
  }
}


//Checkin Guest MOdal

.checkin-wrapper {
  padding: 41px 53px 28px;

  .pop-up-header {
    .page-header-section {
      padding: 0 !important;

      .page-title {
        color: #000000;

        .back-button {
          margin-right: 8px;
          line-height: inherit;
        }
      }

    }
  }

  .booking-text {
    font-size: 14px;
    color: #92929D;
    padding: 8px 32px 32px;
    display: block;
  }

  .booking-details {
    padding: 0 32px;
    gap: 32px 0;

    .booking-label {
      font-size: 12px;
      color: #000000;
    }

    .user-detail {
      font-size: 14px;
      color: #0000008A;
      cursor: not-allowed;
    }
  }

  .booking-dates {
    padding: 32px;
    gap: 32px 0;

    .guest-number {
      font-size: 14px;

    }

    .mat-form-field-label {
      font-size: 12px;
      font-family: "Nunito", sans-serif;
      color: #000000;
    }

    .add-info {
      font-size: 14px;
      color: #000000;
    }

    .add-info-textbox {
      border: 1px solid #D0D1DD;
      padding: 12px 17px;
      border-radius: 8px;
      font-size: 13px;
      color: #000000;
      margin-bottom: 33px;

      &::placeholder {
        font-size: 13px;
        color: #7E8299;
      }
    }

    .info-subheading {
      font-size: 12px;
      margin: 8px 0 24px;
      color: #92929D;
    }
  }
}


.guest-checkin-table-wrapper {
  width: 100%;

  .guest-checkin-table {
    width: 100%;
    overflow: auto;

    thead {
      tr {
        background-color: #F8FBFF;
        border: 1px solid #DDDDDD;

        th {
          padding: 11px 10px !important;
          vertical-align: middle;
          color: black;
          font-family: "Nunito";
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;
          border-bottom-width: 0;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid #DDDDDD;

        td {
          line-height: 25px;
          padding: 10px 10px !important;
          vertical-align: middle;
          color: black;
          white-space: nowrap;
          font-family: "Nunito";
          font-size: 14px;
          font-weight: 600;

          .status-btn {
            padding: 2px 8px;
            border-radius: 6px;
            border: none;
            font-size: 13px;

            .icon-btn {
              display: inline-block;
              margin-left: 5px;
              cursor: pointer;
            }

            &.approved-btn {
              background-color: #DBF4E2B2;
              color: #30A073;
            }

            &.declined-btn {
              background-color: rgba(212, 30, 30, 0.2);
              color: #D41E1E;
            }
          }
        }
      }
    }
  }
}

.personal-info {
  .mat-form-field .mat-form-field-wrapper {
    padding-bottom: 0px !important;

    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 6px 0;
        border-top: none;

        .mat-select {
          .mat-select-value {
            font-size: 14px;
          }
        }

        .mat-form-field-label {
          display: none;
        }
      }

      .mat-form-field-underline {
        bottom: 0px !important;
      }


      .mat-form-field-subscript-wrapper {
        margin-top: 8px;
      }
    }

    .mat-form-field-underline {
      bottom: 0px !important;
    }
  }
}


.checkin-request-mobile {
  width: 100%;
  display: none;

  .chekin-mobile-table {
    width: 100%;
    overflow: auto;

    .request-mobile-table {
      thead {
        tr {
          background-color: #F8FBFF;
          height: 54px;

          th {
            vertical-align: middle;
            padding: 16px 4px 16px 8px;
            font-size: 16px;
            font-weight: 700;
            color: $black;
          }
        }
      }

      tbody {
        .example-element-row {
          background-color: #FFFFFF;

          td {
            vertical-align: middle;
            padding: 2px 4px 3px 8px;
            font-size: 16px;
            font-weight: 400;
            color: $black;
            white-space: nowrap;
          }
        }
      }
    }
  }
}


.reported-table-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  padding: 40px;

  .reported-table {
    width: 100%;
    overflow: auto;

    thead {
      tr {
        height: 60px !important;
        background-color: #F1F5F8;
        padding: 30px;

        th {
          font-size: 18px;
          font-weight: 500;
          padding: 0 15px;
          color: #000000;
          font-family: "Nunito", sans-serif;
          white-space: nowrap;
          vertical-align: middle !important;
          
          &:nth-child(1){
            padding: 0 0 0 24px ;
          }
        }
      }
    }
    tbody {
      tr {
        background-color: white;
        td {
          padding-top: 25px;
          padding-bottom: 25px;

          .report-details{
            padding: 0 15px;
            
            h5{
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 8px;
              white-space: nowrap;
            }
            
            span{
              font-weight: 400;
              white-space: nowrap;
            }

            p{
              .see-more{
                text-decoration: underline;
                color: blue;
                font-size: 14px;
                cursor: pointer;;

              }
            }

          }
          
          .action-btn-wrapper{
            .action-btn{
              border: 1px solid #DFDFDF;
              border-radius: 6px;
              background-color: white;
              padding: 11px 8px;

              svg{
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
    
  }
}


  .add-member-modal .mat-dialog-actions {
    padding: 0px 0 !important;
    margin-top: 40px !important;
    gap: 15px !important;
}
.pdf-icon{
  margin-right: 5px;
}

.custom-login-container{
  max-width: 100% !important;
}