@media(min-width: 1100px) and (max-width: 1320px) {
  .logo {
    img {
      max-width: 80%;
    }
  }

  header {
    .main-menu {
      li {
        a {
          padding: 0 13px !important;
          font-size: 13px !important;
        }
      }

    }

  }

  .main_header .custom_nav nav .nav-wrapper ul li a {
    padding: 0 11px;
  }

  .left-menu-wrapper {
    width: 260px !important;
  }

  aside.admin-left-menu {
    width: 260px !important;

    li {
      a {
        padding: 20px 20px 20px 20px !important;

        i {
          margin-right: 15px !important;
        }
      }
    }
  }

  .main-content-area {
    width: calc(100% - 290px);
  }

  .manage-branding-form-wrapper {
    .block-container {
      .row {
        .col-lg-3.col-md-4.col-sm-6.col-xs-12 {
          width: 50%;
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 25px;
        }
      }
    }
  }

  #hotel-guests {
    .guest-item-list {
      .user-info {
        max-width: 100% !important;
      }
    }
  }

  .btn i {
    margin-right: 4px;
  }
}

@media(min-width: 991px) and (max-width: 1300px) {


  .room-list-view .content-wrapper .gallery-item-list {
    max-width: 33%;
    flex: 0 0 33%;
  }

  .btn i {
    margin-right: 4px;
  }

  .room-manage-page .home-page {
    width: 100%;
  }
}

@media(min-width: 890px) and (max-width: 1102px) {
  .room-list-view .content-wrapper .button-section .btn {
    font-size: 12px;
    padding: 2px 5px;

    i {
      font-size: 15px;
      margin-right: 2px;
    }
  }

  .room-list-view .content-wrapper .room-img-wrapper {
    height: 120px;
  }

  .custom_nav {
    width: 100%;
    flex: 100%;
    max-width: inherit;

    nav {
      a.btn {
        position: absolute;
        top: -42px;
      }

      .nav-wrapper {
        width: 100%;
      }

      ul.first_menu_list {
        width: 100%;

        .lang-drop {
          float: right !important;
        }
      }
    }
  }

  #hotel-guests {
    .guest-item-list {
      .user-info {
        max-width: 100% !important;
      }
    }
  }

  .room-prize-buttons {
    align-items: center;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .available-rooms {
    .room-prize-buttons {
      .free-breakfast {
        margin-right: 15px;
      }

    }

  }
}

@media(min-width: 991px) and (max-width: 1280px) {

  .Login-popup {
    background: #535042;
  }
}

@media only screen and (max-width: 1500px) {
  .booking-info-wrapper .booking-info mat-accordion mat-expansion-panel .mat-expansion-panel-body .booking-expansion-table {
    thead {
      tr {
        th {
          padding: 10px;

          &:nth-child(1) {
            padding-left: 10px !important;
          }
        }
      }
    }

    tbody {
      .example-element-row {
        td {
          padding: 10px !important;
        }
      }
    }
  }

  .profile-detail .right-info .info-area .order-details-guest-wrapper .order-details-guest-link {
    gap: 10px;

    .order-details-guest {
      padding: 0 5px !important;
      border-right: unset !important;
    }
  }

  .order-type-chip {
    font-size: 12px !important;
  }

  .manage-order-title {
    font-size: 14px !important;
  }

  .assigned-staff-button {
    padding: 3px 6px !important;
    font-size: 12px !important;
  }

  .check-circle-icon {
    color: #49CA6D;
    font-size: 15px !important;
    height: 13px !important;
    width: 16px !important;
  }

  .manage-order-wrapper .tiles-section .tiles-wrapper .order-content .order-content .order-card-wrapper .order-card .manage-order-accordion .manage-order-expansion .mat-expansion-panel-content {


    .mat-expansion-panel-body {
      padding: 0;

      .order-table-wrapper .manage-order-table {
        thead {
          tr {
            th {
              font-size: 14px !important;

              &:first-of-type {
                padding-left: 10px;
              }

              &:last-of-type {
                padding-right: 10px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 14px !important;

              &:first-of-type {
                padding-left: 10px;
              }

              &:last-of-type {
                padding-right: 10px;
              }
            }
          }
        }
      }

      .additional-request-accordion {
        .additional-request-panel {
          .additional-request-header {
            .additional-request-description {
              span {
                font-size: 14px !important;
              }
            }
          }
        }

        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            p {
              padding: 6px 10px !important;
            }

            .mat-stepper-horizontal {
              padding: 5px;

              .mat-horizontal-stepper-header-container {
                .mat-step-header {
                  .mat-step-icon {
                    display: none;
                  }


                }
              }
            }
          }
        }
      }

      .order-preparing-button {
        font-size: 14px !important;
      }
    }
  }

  .manage-order-wrapper .tiles-section .tiles-wrapper .order-content .order-content {
    padding: 10px;

    .order-card-wrapper {
      padding: 10px;

      .order-card {
        .manage-order-text-wrapper {
          padding-bottom: 10px;

          .order-text {
            font-size: 14px !important;
            line-height: 20px;
          }

          .assigned-chip {
            font-size: 12px;
            padding: 4px 6px !important;
            display: flex !important;
            align-items: center !important;
          }
        }
      }
    }
  }

  .chat-box-area .chat-header .info .assignedStaffChip {
    flex-direction: column;
    align-items: flex-start !important;

    .internal-chip-wrapper {
      flex-wrap: wrap;
      margin: 10px 0 0 0;
      gap: 10px;
    }
  }

  .room-manage-page .home-page {
    width: 100%;
  }

  body .admin-gallery-cth #hotel-gallery .content-wrapper .main-content-wrapper .gallery-item-list {
    max-width: 25%;
    flex: 0 0 25%;
    max-height: 260px;
    height: 260px;
  }

  .room-list-view .content-wrapper .button-section {
    padding: 20px 12px;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 13px;

    .btn {
      padding: 4px 8px;
      margin-right: 5px;
    }
  }

  .main-content-area {
    .content-section-outer {
      .content-wrapper {
        .main-content-wrapper {
          .wrapper-list-thumb {
            .list-thumb-content {
              padding: 19px 12px 7px !important;
            }
          }
        }
      }
    }
  }

  .staff-list {
    .list-thumb-content {
      .staffContactInfo {
        margin-bottom: 0;
      }
    }
  }

  .fliter-outer {
    // display: inline-block;
    width: 100%;

    .mat-form-field {
      width: 100%;
    }

    .fliter-by-out {
      width: 100%;
    }

    .fliter-web-outer {
      margin-right: 0px;
      margin-bottom: 20px;
    }

    .fliter-status-outer {
      margin-right: 0px;
      margin-bottom: 20px;
    }

    .fliter-by-form {
      margin-right: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .heading-fliter {
    padding: 20px;
  }

  .room-desc {
    .place-offer {
      margin-top: 16px;
      width: 100%;
      margin-right: 20px;
    }

  }
}

@media only screen and (max-width: 1440px) {

  .reported-request-wrapper {
    .reported-table-wrapper {
      padding: 0 !important;
    }
  }
  .login-wrapper{
    padding:  0 !important;
    .login-contaier {
      padding: 30px !important;
  
      .form-info {
        .login-form{
          .mat-form-field{
            margin-bottom: 20px !important;
          }
        }
      }
  
      .logo-and-info {
        margin-bottom: 20px !important;
  
        img {
          max-width: 115px !important;
        }
      }

      .submit-btn{
        margin: 20px 0 0 0 !important;
      }
    }
  } 

  .number-verification-wrapper {
    padding: 30px !important;

    .signup-logo {
      img {
        max-width: 115px !important;
      }
    }

    .verify-email {
      margin-bottom: 20px !important;
    }

    .enetr-text {
      margin-top: 20px !important;
    }

    .otp-section {
      margin: 20px 0 20px 0 !important;
    }

    .verification-code-wrapper
    {
      margin-top: 20px !important;
      .verification-code {
        height: 30px !important;
        width: 40px !important;
      }
    } 
    .verify-button {
      margin: 0 0 20px 0;
    }
  }

  .reported-request-wrapper {
    padding: 31px 0px !important;
    background-color: transparent !important;

    .reported-table-wrapper {
      padding: 0 !important;
    }
  }
  .admin-message-container {
    .all-messages {
      .right-info.messaging-area {
        .message-user-list {
          min-width: 26rem;

          .mat-tab-group {
            .mat-tab-header {
              .mat-tab-label-container {
                .mat-tab-list {
                  .mat-tab-labels {
                    .mat-tab-label {
                      min-width: auto;

                      .mat-tab-label-content {
                        font-size: 12px;
                        font-weight: 800;
                      }
                    }
                  }
                }
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  .mat-list {
                    .mat-list-item {
                      padding: 10px;

                      .mat-list-item-content {
                        .icon {
                          width: 30px;
                          min-width: 30px;
                          height: 30px;
                        }

                        .message_is {
                          .combination {
                            p {
                              font-size: 12px;

                              strong {
                                font-size: 12px;
                              }
                            }
                          }

                          .date {
                            font-size: 13px;
                            margin-top: 5px;
                          }

                          p {
                            font-size: 13px;
                          }
                        }
                      }

                      &.internal-chat-list {
                        .info {
                          .combination {
                            font-size: 12px;
                          }

                          .date {
                            font-size: 12px;
                            margin-top: 5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }

        .chat-box-area {
          .chat-header {
            flex-wrap: wrap;

            .icon {
              width: 30px;
              min-width: 30px;
              height: 30px;
            }

            .staff-image {
              margin-right: 10px;
            }

            .info {
              order: 3;
              font-size: 12px !important;
              margin-top: 5px;
              padding: 0;

              .staff-name {
                font-size: 14px;
                margin: 0;
              }

              .date-info {
                font-size: 12px;
              }

              .info-text {
                font-size: 12px;


              }

              // .service-info-internal {
              //   margin-top: 5px;
              // }

              .assigned-chips {
                .assignedStaffChip {
                  margin: 5px 0;

                  .internal-chip-wrapper {
                    margin: 10px 0 0 0;
                    gap: 5px;

                    .internal-chip {
                      min-height: 23px;
                    }
                  }
                }
              }
            }

            .right-info-status {
              order: 2;
              display: flex;
              align-items: center;
              justify-content: space-between;

              button {
                padding: 0px 15px;
                height: 21px;
                font-size: 12px;
              }

              .view-order-btn {
                margin-top: 0;
                padding: 0 5px;
                margin-left: 10px;
                font-size: 12px !important;
                height: 21px;
              }
            }
          }

          .chat-middle-area {
            .chatmsg {
              margin: 20px 0;

              .msg-info {
                span {
                  padding: 10px 15px;
                  font-size: 12px;
                }

                .chat-pdf {
                  font-size: 12px;

                  .pdf-image {
                    height: 25px !important;
                    width: 20px !important;
                    margin-right: 5px;
                  }
                }
              }

              &:nth-child(1) {
                margin: 0;
              }
            }
          }

          .chat-footer {
            .message-input {
              padding: 0;

              textarea {
                padding: 2px 10px;
                max-height: fit-content;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }

  .fliter-outer {
    flex-wrap: wrap;
    justify-content: space-between;

  }

  .guest-checkin-wrapper {
    .filter-date {
      flex-direction: column;
      align-items: flex-start !important;
    }

    .filter-search {
      .fliter-outer {
        flex-direction: column;
      }
    }
  }

  .checkin-wrapper {
    padding: 20px 30px;

    .booking-text {
      padding: 8px 32px 20px;
    }

    .booking-details {
      gap: 10px 0;
    }

    .booking-dates {
      padding: 5px 32px;
      gap: 0;
    }

    .add-text-wrapper {
      margin-top: 20px;
    }
  }

  .room-list-view .content-wrapper .button-section {
    gap: 10px;
    flex-wrap: wrap;
  }

  .profile-detail-section {
    margin: 20px 0 !important;
  }

  .custom-container-message {
    margin-bottom: 30px !important;
  }

  .make-booking {
    padding: 0 !important;
  }

  .main_header .custom-nav-wrapper .custom_nav ul li a {
    font-size: 12px !important;
  }

  .page-header-section {
    align-items: baseline;
  }

  .room-detail-guest {
    .room-desc {
      padding: 35px 10px !important;
    }
  }

  .free-breakfast {
    width: 100%;
    justify-content: space-between;

    button {
      min-width: 100% !important;
      padding: 0;
    }
  }

  .room-prize {
    padding: 18px;
  }

  .book-now {
    width: 100%;

    button {
      min-width: 100% !important;
    }
  }
}

@media only screen and (max-width: 1260px) {

  

  .heading-fliter {
    .filter-search {
      flex-wrap: wrap;
      gap: 10px;
    }
  }


  .radio-custom {
    flex-wrap: wrap;
    gap: 10px;

    .mat-radio-button {
      width: 100%;

      &.mr-2 {
        margin-right: 0 !important;
      }
    }
  }

  .chat-box-area .chat-header .info .info-text {
    font-size: 12px;

    strong {
      font-size: 12px;
    }
  }


  .manage-order-text-wrapper {
    padding-bottom: 6px !important;
  }

  mat-chip.mat-chip.mat-focus-indicator.assigned-chip {
    font-size: 12px !important;
    padding: 2px 4px !important;
  }

  .room-details .book-now-section .booking-info {
    padding: 28px 20px !important;

    .booking-dates {
      .col-6 {
        padding-right: 0px !important;
      }
    }

  }


  .confirm-booking {
    .trip-section {
      flex-wrap: wrap;
    }

    .right-section {
      margin-top: 20px;
      max-width: none;
      width: 100%;
    }
  }



}

@media only screen and (max-width: 1200px) {

  .bd-top-block ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px 0;
  }

  .bd-top-block ul li {
    width: 50%;

    .guest-checkout-info {
      margin-bottom: 0 !important;
    }

    .mt-5 {
      margin-top: 0 !important;
    }
  }


  .manage-restaurant-guest-wrapper .restaurant-guest-history-wrapper .guest-order-history-wrapper .mat-tab-body-wrapper .previous-tab-header-wrapper .restaurant-calender {
    width: 36%;
  }

  .manage-restaurant-guest-wrapper {
    width: 700px !important;
  }


  body .admin-gallery-cth #hotel-gallery .content-wrapper .main-content-wrapper .gallery-item-list {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }


}

@media only screen and (max-width: 1099px) {

  .checkin-booking-wrapper .block-container .personal-information .personal-info-wrapper {
    flex-wrap: wrap;
    gap: 20px;
  }

  .room-list-view .content-wrapper .restock-warning {
    top: 120px;
  }


  .manage-restaurant-guest-wrapper .restaurant-guest-history-wrapper .guest-order-history-wrapper .mat-tab-body-wrapper .restro-guest-accordion .restro-guest-expansion .restro-guest-expansion-header .mat-content .mat-expansion-panel-header-title {
    margin-right: 16px;
  }

  .manage-restaurant-guest-wrapper .restaurant-guest-history-wrapper .guest-order-history-wrapper .mat-tab-body-wrapper .previous-tab-header-wrapper .restaurant-calender {
    width: 50%;
  }

  .manage-restro-toggle {
    margin-left: 0 !important;
    height: auto !important;

    .mat-slide-toggle-label {
      .mat-slide-toggle-bar {
        width: 38px !important;
        height: 19px !important;
        margin-right: 0 !important;
      }

      .mat-slide-toggle-thumb-container {
        height: auto !important;
        width: auto !important;
        top: 2px !important;
        left: 2px !important;

        .mat-slide-toggle-thumb {
          width: 15px !important;
          height: 15px !important;
        }
      }
    }

    &.mat-slide-toggle.mat-checked:not(.mat-disabled) {
      .mat-slide-toggle-thumb-container {
        transform: translate3d(18px, 0, 0) !important;
      }
    }
  }

  .manage-restaurant-guest-wrapper {
    width: 600px !important;
  }

  .manage-set-section {
    width: 100%;
  }

  .page-title {
    font-size: 18px !important;
  }

  .tiles-section-Web {
    display: none !important;
  }

  .tiles-section-mobile {
    display: block !important;
    margin-top: 0 !important;
  }

  .tiles-wrapper {
    background: inherit !important;
  }

  .order-content {
    padding: 20px 0 10px 0 !important;
  }

  .order-header {
    display: none !important;
  }

  .tiles-section-mobile {
    .manage-order-tabs-wrapper {

      .mat-tab-header {
        border-bottom: 0;

        .mat-tab-header-pagination {
          display: none !important;
        }

        .mat-tab-label-container {
          justify-content: center !important;

          .mat-tab-list {
            .mat-tab-labels {
              border-right: 0;
              border-radius: 10px;
              background: $white;

              .mat-tab-label {
                width: 100% !important;
                padding: 15px 19px !important;
                border-right: 2px solid #EEF2FE;
                position: relative;
                height: fit-content !important;

                .mat-ripple {
                  transform: none
                }

                .mat-tab-label-content {
                  font-size: 12px !important;
                  font-family: "Nunito";
                  text-transform: capitalize;
                  color: #000;
                  font-weight: 700;
                }



                &.mat-tab-label-active {
                  background: #008BEC;
                  border-radius: 10px;

                  .mat-tab-label-content {
                    color: white;
                  }
                }

              }

            }

            .mat-ink-bar {
              display: none;
            }
          }
        }
      }
    }
  }

  .restaurant-selection-orders {
    height: 33px;
  }

  .order-search {
    min-width: auto !important;
  }

  .manage-dropdown-headline {
    order: 1;
    height: 33px !important;
    width: calc(100% - 105px);

    .mat-form-field {
      width: 100%;
    }
  }

  .order-searchbar-wrapper {
    margin-left: 10px !important;
    order: 3;
    height: 33px !important;
    width: 100%;

    input {
      width: 100%;
      height: 33px;

    }
  }

  .filter-button-wrapper {
    order: 2 !important;
    margin-left: 10px;

    button {
      height: 33px !important;
    }
  }



  body .admin-gallery-cth #hotel-gallery .content-wrapper .main-content-wrapper .gallery-item-list {
    max-width: 50%;
    flex: 0 0 50%;
  }

  body .left-menu-wrapper {
    width: 220px;

    .admin-left-menu {
      width: 100% !important;

      li {
        a {
          line-height: 24px !important;
          font-size: 13px !important;
          padding: 20px 15px 20px 15px !important;

          i {
            margin-right: 10px !important;
            font-size: 20px;
            position: relative;
            top: 3px;
          }
        }
      }

      li.active a:after {
        width: 3px !important;
      }
    }
  }

  .main-content-area {
    width: calc(100% - 240px);
  }

  body .btn {
    font-size: 14px;
    padding: 5px 25px;

    i {
      position: relative;
      top: 0px;
    }
  }

  body .page-header-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:after {
      display: none;
    }

    .lang-drop-menu {
      margin-top: 0px !important;
    }

    .page-title {
      font-size: 22px;
    }
  }

  .manage-restro-header-wrapper {
    flex-wrap: nowrap !important;
  }

  .staff-list .list-thumb-content .staffImgInfo {
    flex-wrap: wrap !important;
  }
}

@media only screen and (max-width: 991px) {

  .filter-search {
    flex-wrap: wrap;

    .filter-btn-wrapper {
      margin-top: 10px;
      gap: 5px;
    }
  }

  .manage-restaurant-guest-wrapper {
    width: 565px !important;
  }

  .backRestaurant {
    font-size: 16px;
  }

  .dropdown .mat-form-field-infix .mat-select-trigger,
  .dropdown .mat-form-field-infix .mat-input-element {
    padding: 5px;
    height: 31px;
  }



  // Rooms
  .room-manage-page .home-page {
    width: 100%;
  }

  .available-rooms {
    .images-section {
      max-height: 320px;
    }

    .room-desc {
      padding: 20px;
      border-bottom: 1px solid #eeeeee;
    }

    .room-prize {
      padding: 0 20px;
      border-left: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .cancellation-button {
        justify-content: flex-start;
        margin-top: 10px;
      }

      .rooms-prize-deatil {
        align-items: flex-start;

        .amount {
          font-size: 22px;
        }


      }
    }

    .room-prize-buttons {
      align-items: flex-end;
      flex-direction: row;

      .free-breakfast {

        button {
          min-width: 216px;
          height: 36px;
          margin-top: 0px;
          // margin-right: 20px;
        }
      }

    }


  }

  .room-desc {
    .hotel-offers {
      .place-offer {
        width: 50%;
        margin-right: 0px;

      }
    }


  }

  // Billing-history
  .billing-pay-methods textarea {
    min-height: unset;
    height: 180px;
  }

  .bill-history-popup {
    flex-direction: column;

    .billing-pay-methods {
      max-width: unset;
      width: 100%;

    }

    .payment-option {
      max-width: unset;
      width: 100%;

      .payment-optionssss {
        margin-bottom: 0 !important;

      }

    }

  }

  .Login-popup {
    .left-contents {
      width: 100%;
      margin: 0 auto;
      padding: 39px 50px;

      .top-div {
        display: block;

        mat-icon {
          display: inline-flex;
        }
      }

      .poppup-logo {
        display: block;
      }

      p {
        text-align: center;
      }

      .login-text {
        display: block;
      }

      // .checkbox{
      //   display: none;
      // }
      .social-media {
        display: block;
      }

      .logged-in {
        display: block;
      }
    }

    .right-contents {
      display: none;
    }
  }

  .search-btn {

    margin-top: 5px;

  }

  // login Signup popup
}

@media only screen and (max-width: 1050px) {
  .admin-message-container {
    height: 100%;
  }
}

// 899
@media only screen and (max-width: 899px) {

  .room-list-view .content-wrapper .restock-warning {
    top: 180px;
  }

  .chat-box-area .chat-header .icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }

  .chat-box-area .chat-header {
    align-items: center;
    flex-wrap: wrap;

    .info {
      order: 3;
      margin-top: 20px;
    }

    .right-info-status {
      order: 2;
      display: flex;
      align-items: center;

      .view-order-btn {
        padding: 3px 8px;
        margin: 0 0 0 10px
      }
    }
  }

  .accordian-heading {
    font-size: 14px !important;
  }

  .manage-restaurant-guest-wrapper .restaurant-guest-history-wrapper .guest-order-history-wrapper .mat-tab-body-wrapper {
    .previous-tab-header-wrapper {
      padding: 8px 14px !important;

      .tab-text {
        font-size: 14px !important;
      }

      .restaurant-calender {
        .date-input {
          .calnedar-input-wrapper {
            .input-calendar {
              padding: 6px 8px !important;
            }

            .input-calendar-icon {
              top: 10% !important;
            }
          }
        }
      }
    }

    .restro-guest-assignee-wrapper {
      padding: 8px 20px !important;

      .restro-guest-assignee {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 10px !important;

        .restro-guest {
          width: 100%;
          flex-direction: row !important;
          align-items: center !important;
          justify-content: space-between !important;

          .nunito {
            font-size: 14px !important;
          }
        }
      }

      .restro-guest-accordion {
        .restro-guest-expansion {
          .restro-guest-expansion-header {
            .restro-guest-expansion-title {
              margin-right: 0 !important;

              .view-mode {
                font-size: 16px !important;
                font-weight: 600 !important;
                color: #001847 !important;
              }
            }

            .mat-expansion-panel-header-description {
              .restro-guest {
                width: 100%;
                align-items: start !important;

                .nunito {
                  font-size: 14px !important;
                }
              }
            }
          }

          .mat-expansion-panel-content {
            .guest-restro-label {
              margin-bottom: 6px !important;
            }

            .inventory-table-wrapper {
              .inventory-table {
                table {
                  border-collapse: separate !important;
                  border-spacing: 0 6px !important;
                  background: inherit !important;

                  tbody {
                    tr {
                      height: 27px !important;
                      background: white !important;

                      td {
                        &:nth-child(2) {
                          padding-right: 20px !important;
                          text-align: right !important;
                        }
                      }
                    }

                  }

                  tfoot {
                    .mat-footer-row {
                      height: 27px !important;
                      background: white !important;

                      td {
                        &:nth-child(2) {
                          padding-right: 20px !important;
                          text-align: right !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  .restaurant-guest-history-wrapper {
    .billing-amount {
      padding: 20px 12px;

      .subtotal {
        font-size: 14px !important;

        &:nth-last-child(1) {
          font-size: 18px !important;
        }
      }


    }

    .printing-button {
      margin-top: 14px !important;
      flex-direction: column;
      align-items: flex-end !important;

      p {
        font-size: 14px !important;
        margin-bottom: 24px !important;
        width: 100%;
        text-align: left;
      }

    }
  }

  .manage-restaurant-guest-wrapper .restaurant-guest-header {
    padding: 30px 25px 15px 30px;
  }

  .manage-restaurant-guest-wrapper .restaurant-guest-history-wrapper {
    padding: 15px 24px !important;

    .guest-order-history-wrapper {
      .mat-tab-header {
        margin-bottom: 15px !important;

        .mat-tab-labels {
          .mat-tab-label {
            padding: 10px !important;
            min-width: 115px !important;

            .mat-tab-label-content {
              color: #001847;
              font-size: 14px !important;
              font-weight: 600 !important;
            }
          }
        }
      }


    }
  }

  .inventory-table-wrapper .inventory-table table thead tr th {
    font-size: 14px !important;

  }

  .manage-restaurant-guest-wrapper .restaurant-guest-history-wrapper .guest-order-history-wrapper .mat-tab-body-wrapper .inventory-table-wrapper .inventory-table table {
    thead {
      tr {
        height: 34px !important;
        border-radius: 4px;
        background: #E6E8ED !important;
      }
    }

    tbody {
      tr {
        height: 38px !important;
      }
    }
  }

  .manage-restaurant-guest-wrapper .restaurant-guest-header .restaurant-guest-detail-wrapper .restaurant-guest-detail {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0;
  }

  .guest-phone-number {
    margin-top: 18px;
    font-size: 14px !important;
  }

  .restuarant-assignee-details-wrapper {
    gap: 6px !important;
    margin-top: 6px;

    .asigned-detail {
      font-size: 14px !important;
      width: 60%;
      display: flex;
      align-items: center;

      strong {
        margin-left: 5px !important;
      }

      &:nth-child(2),
      &:nth-child(4) {
        width: 35% !important;
      }
    }
  }



  .order-tracking-wrapper {
    box-shadow: none !important;
  }

  .guide-detail-box {
    border: 0;
    padding: 0;

    .row {
      margin: 0;
    }

    .col-md-12 {
      padding: 0;

      .row {
        margin: 0 -20px;
      }
    }

    .ch-guide-list {
      flex: 0 0 100%;
      max-width: 100%;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 0;
      padding: 25px;

      &:nth-child(2n) {
        border-left: 0;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .room-list-view .content-wrapper .button-section .btn {
    font-size: 12px;
    padding: 2px 6px;

    i {
      margin-right: 4px;
      font-size: 16px;
    }
  }


  body .left-menu-wrapper .admin-left-menu li a {
    font-size: 0px !important;
    padding: 20px 0px 20px 15px !important;
  }

  body .left-menu-wrapper {
    width: 50px !important;
  }

  .main-content-area {
    width: calc(100% - 65px);
    padding: 0px 15px 0px 0px;
  }

  .menu_wrapper {

    // display: none !important;
    //     position: absolute;
    //     top: 1px;
    //     left: 16px;
    //     right: 16px;
    //     background: #fff;
    //     border-top: 2px solid #000;
    //     padding: 10px;
    // height: 100% !important;
    ul {
      li {
        width: 100% !important;

        a {
          line-height: 44px !important;
          padding: 0 20px !important;
        }

        &.lang-drop {
          width: 50% !important;
        }
      }
    }

    a.btn {
      padding: 12px 60px;
    }
  }

  .custom_nav {
    flex: inherit !important;
    max-width: inherit !important;
  }

  .responsive_menu {
    display: block;
    position: absolute;
    right: 12px;
    top: -47px;

    i {
      font-size: 33px;
      cursor: pointer;
      color: #000;
    }
  }

  .main_header {
    .logo {
      padding: 20px 0;
    }
  }

  .menu_wrapper.active-open {
    display: block !important;
    height: 100% !important;
  }

  body {
    padding: 0;
  }

  #hero-banner {
    h1 {
      font-size: 50px !important;
    }

    h4 {
      font-size: 34px !important;
    }
  }

  .section-header {
    h2 {
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 0;
    }

    .btn {
      padding: 9px 25px;
      font-size: 14px;
    }
  }

  #about-info,
  #hero-banner {
    padding: 60px 0 !important;
  }

  #about-info {
    .content-wrapper {
      button {
        padding: 10px 50px !important;
      }
    }
  }

  footer {
    position: relative;

    .footer-wrapper {
      text-align: center;

      .footer-logo {
        float: none !important;
        display: inline-block;
        margin-bottom: 20px;
      }

      .address-phone {
        span {
          float: left;
          width: 100%;
          text-align: center;
          margin-bottom: 10px;

          &.spacer-span {
            display: none;
          }
        }
      }
    }
  }

  #hotel-guests {
    .guest-item-list {
      .user-info {
        max-width: 100% !important;
        margin: 30px auto 40px !important;

        .image-wrapper {
          width: 60px !important;
          height: 60px !important;
        }

        .name-info {
          margin-left: 20px !important;
        }
      }

      &:last-child {
        .user-info {
          margin: 30px auto 0 !important;
        }
      }
    }
  }

  .content-section-outer {
    padding: 40px 0;
  }

  body .menu-item-table .mat-form-field-infix {
    width: 200px;
  }

  body .responsive-table {
    overflow: auto;

    table {
      th {
        white-space: nowrap;
      }
    }
  }

  body .lang-drop-menu {
    font-size: 14px !important;
    padding: 0px !important;
    border: 0px !important;
    margin: 0px 0px 10px 0px !important;
  }


}

@media only screen and (min-width: 991px) {



  .contact-left {
    position: relative;

    &:after {
      height: 100%;
      width: 1px;
      content: '';
      background-color: #c8c8c8;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      background-color: #f9b420;
      width: 3px;
      height: 192px;
      content: '';
      position: absolute;
      left: 0;
      top: 25%;
    }
  }
}

@media only screen and (max-width:767px) {
  .checkin-wrapper {
    padding: 10px;

    .booking-text {
      padding: 5px 10px;
    }

    .booking-dates {
      padding: 5px 10px;
    }

    .booking-details {
      padding: 5px 10px;

    }
  }

  .main-outer {
    width: 100%;
    margin-left: 0;
  }

  .new-header {
    width: 100%;
    margin-left: 0;
  }

  .new-nav {
    width: 100% !important;
    position: relative !important;
    top: 0 !important;
  }

  .new-header {
    width: 100% !important;
    margin-left: 0 !important;
  }


  .main-outer {
    width: 100% !important;
    margin-left: 0 !important;
  }


  .manage-restro-header-wrapper {
    position: relative;
  }


  .manage-restro-toggle {
    position: absolute !important;
    right: 0 !important;
    top: 20px;
  }

  .manage-restro-searchbar {
    order: 1;
    margin-left: 0 !important;
    height: 38px !important;

    .order-search {
      height: 100% !important;
    }
  }

  .manage-restro-toggle {
    order: 3;
    margin-left: 10px !important;
  }

  .manage-restro-filter {
    order: 2;
  }

  .manage-restro-filter {
    border-radius: 4px;
    border: 1px solid #DDD;
    outline: none;
    padding: 9px;
    color: #888888;
    height: 38px;
    min-width: 38px;
    background-color: #fff;
    cursor: pointer;

    button {
      display: none;
    }

    .mat-filter-btn-icon {
      width: auto;
      height: auto;
      left: 9px !important;
    }
  }

  .manage-restaurant-table-web {
    display: none !important;
  }

  .manage-restaurant-table-mobile {
    display: block !important;

    .mat-table {
      thead {
        tr {
          height: 54px !important;

          th {
            &:nth-child(1) {
              min-width: 130px;
            }
          }
        }
      }

      tbody {
        .example-element-row {
          height: 46px !important;
        }
      }
    }
  }

  .manage-restaurant-guest-wrapper {
    width: 500px !important;
  }

  .billing-history-guest {
    display: block !important;
  }

  i.remove-img {
    top: 0;
    left: 82%;
    z-index: 9999999;
  }

  .clip-art {
    display: block !important;
  }

  .cart-checkin-cancel-responsive {
    display: none;
  }

  .checkin-restaurant-Logo {
    margin-bottom: 59px !important;
  }


  .cart-flex {
    flex-wrap: wrap;
  }

  .restaurant-payment-option {
    width: 100% !important;
    max-width: unset !important;
  }

  .booking-table-admin tr td {
    padding: 13px !important;

    &:nth-child(1) {
      padding-left: 0 0 0 13px !important;
    }

    &:nth-child(2) {
      padding-left: 13px !important;
    }
  }

  body .slideshow-container.slideshow-container-fs[_ngcontent-c20] {
    max-height: 450px;
    left: 20px !important;
    top: 20px !important;
    right: 20px !important;
  }

  .room-listing-page .page-header-section,
  .gallery-header {
    flex-direction: inherit !important;
  }

  body .page-header-section {
    align-items: start;
    flex-direction: column;
  }

  body .page-header-section .page-title {
    line-height: normal;
    margin: 0px 0 10px;
  }

  body .menu-item-table .mat-form-field-infix {
    width: 150px;
  }

  body .block-container {
    padding: 15px;
    margin-bottom: 20px;
  }

  body .mat-tab-body .block-container {
    padding-top: 30px;
  }

  body .tabing-section .block-container .row:last-child .mat-form-field {
    margin-bottom: 25px !important;
  }

  body .form-wrapper .mat-form-field {
    margin-bottom: 25px;
  }

  body .color-box {
    display: block;
    margin: 0px 0px 15px 0px;
    float: left;
  }

  body .form-wrapper .checkin-checkout-wrapper {
    clear: both;
  }

  body .form-wrapper .checkin-checkout-wrapper .mat-form-field {
    margin-bottom: 25px;
  }

  body .add-image .mat-dialog-container .modal-header {
    position: relative;
  }

  body .add-image .mat-dialog-container .modal-header button {
    position: absolute;
    top: 12px;
    right: 6px;
  }

  body .gallery-item-list .wrapper-list-thumb img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }

  body .gallery-item-list .wrapper-list-thumb .depart-icon img {
    max-width: 50px;
  }

  body .content-section-outer .content-wrapper .main-content-wrapper .wrapper-list-thumb .list-thumb-content {
    padding: 20px;
  }

  body .form-wrapper.add-department-form .mat-form-field {
    margin-bottom: 15px;
  }

  body .form-wrapper.add-department-form .mat-form-field .btn.btn-link {
    margin-bottom: 25px;
    padding-left: 0;
  }

  // .available-rooms
  .available-rooms {
    .room-img-left {
      display: none;
    }

    .room-img-right {
      display: none !important;
    }

    .room-desc {
      border-bottom: none;

      .entire-appartment {
        border-right: none;
      }

      .about-room-img {
        display: block;
      }

      .hotel-offers {
        flex-wrap: nowrap;
      }

      .about-room {
        margin-left: 15px;

      }
    }

    .room-prize {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 10px;

      .end-section {
        flex-direction: row !important;
        flex-wrap: wrap;

        .rooms-prize-deatil {
          align-items: flex-start;
          // margin-top: 20px;

          .cancellation {
            margin-top: 0px;

          }

          .amount {
            font-size: 35px !important;
          }
        }

        .room-prize-buttons {
          // margin-top: 46px;
          flex-direction: column;

          .free-breakfast button {
            // margin-top: 28px;
          }

        }

      }

    }
  }

  .room-details {
    .right-img {
      width: 100%;

    }

    .left-img {
      display: none !important;
    }
  }


  //  ------- confirm-booking-------
  .confirm-booking {
    .trip-section {
      .right-section {
        max-width: none;
        width: 100%;
      }
    }
  }

  // login-popup
  .Login-popup {
    .left-contents {
      width: 100%;
      margin: 0 auto;
      padding: 39px 50px;

      .top-div {
        display: block;

        mat-icon {
          display: inline-flex;
        }
      }

      .poppup-logo {
        display: block;
      }

      p {
        text-align: center;
      }

      .login-text {
        display: block;
      }

      // .checkbox{
      //   display: none;
      // }
      .social-media {
        display: block;
      }

      .login-account {
        display: block;
      }
    }

    .right-contents {
      display: none;
    }
  }

  .view-list {
    margin-left: 15px;
  }


  .booking-expansion-table {
    display: none;
  }

  .booking-mobile-design {
    display: block !important;
    margin-top: 15px;

    mat-card-content {
      mat-list {
        mat-list-item {
          padding: 10px 5px !important;
          height: fit-content !important;

          .mat-list-item-content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .card-heading {
              color: $black;
            }

            .paid {
              color: #49CA6D;
              display: flex;
              align-items: center;

              &::before {
                content: "";
                height: 9px;
                display: inline-block;
                width: 9px;
                margin-right: 5px;
                border-radius: 50%;
                overflow: hidden;
                background: #49CA6D;
              }
            }

            .unpaid {
              color: red;
              display: flex;
              align-items: center;

              &::before {
                content: "";
                height: 9px;
                display: inline-block;
                width: 9px;
                margin-right: 5px;
                border-radius: 50%;
                overflow: hidden;
                background: red;
              }
            }


            .details-button {
              border-radius: 4px;
              border: 1px solid #9E9E9E;
              padding: 0 5px;
              color: black;
              font-family: "Nunito";
              max-height: 27px;
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width:700px) {

  .billing-history-guest-table {
    display: none !important;
  }

  .booking-mobile-design {
    display: block !important;
  }

  .verification-wrapper {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .complete-profile-form-wrapper .verification-wrapper .verification-text {
    font-size: 14px;
  }

  .complete-profile-form-wrapper .country-code-wrapper {
    .country-code-dropdown {
      width: 30%;
    }

    .phone-number {
      width: 70%;
    }
  }


  .order-tracking-wrapper .order-tracking-progressbar .tracking-order-details .info-wrapper {
    width: 100% !important;
  }

  .order-tracking-wrapper .order-tracking-progressbar .stepper-wrapper {
    padding: 10px;
  }

  .order-tracking-wrapper .order-tracking-progressbar .mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-label .grey {
    width: 24px !important;
    height: 24px !important;
  }

  .order-stepper-icon {
    width: 24px !important;
    height: 24px !important;
  }


  .activation-content {
    padding: 20px;
    margin-top: 10px !important;

    h1 {
      font-size: 30px !important;
    }

    .activation-text {
      font-size: 16px;
    }
  }

  .user-sidenav {
    .sidenav-logo-wrapper {
      .logo {
        a {
          display: block;
        }
      }

      // a{
      //   display: none;
      // }
    }

    .custom-nav-wrapper {

      .checkin-lang-wrapper {
        .lang-drop {
          display: none;
        }
      }
    }

    .user-profile-desc {
      display: none;
    }
  }

  // .user-sidenav .custom-nav-wrapper .custom_nav ul li span .menu-item-text-color {
  //   padding: 0;
  // }
}

@media only screen and (max-width: 601px) {

  .chat-box-area {
    .chat-header {
      .info {
        padding: 0;

        .align-items-center {
          align-items: baseline !important;
        }
      }
    }
  }

  .manage-restro-searchbar {
    width: 90%;
    margin-top: 0 !important;
  }

  .manage-restaurant-guest-wrapper {
    width: 100% !important;
  }

  .view-order-wrapper .view-order-tabs-wrapper .view-order-tab-wrapper .mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label {
    font-weight: 600 !important;
  }

  .order-details-guest-link {
    justify-content: space-between !important;
  }

  .order-details-guest-wrapper .guest-amount-detail {
    justify-content: space-between !important;
  }


  .view-order-wrapper .view-order-tabs-wrapper .view-order-tab-wrapper .mat-tab-header {
    .mat-tab-header-pagination {
      display: none;
    }
  }

  .order-details-guest-wrapper {
    padding: 10px !important;
  }

  .view-order-wrapper .view-order-tabs-wrapper .view-order-date {
    position: unset !important;
  }

  .order-details-guest-wrapper .order-details-guest-link .order-details-guest {
    padding: 0 !important;
  }



  .order-tracking-wrapper .order-tracking-progressbar .mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-label .order-stepper-title {
    display: none !important;
  }

  .manage-set-section {
    flex-wrap: wrap !important;

    .order-searchbar-wrapper {
      margin-left: 0 !important;
      margin-top: 10px;
    }
  }

  .manage-restro-header {
    flex-wrap: nowrap !important;
  }



  .thank-you-page .back-service-btn {
    width: 100%;
  }

  // Rooms
  .available-rooms {
    .room-prize {
      .cancellation-button {
        justify-content: center;
      }

      .rooms-prize-deatil {
        align-items: flex-start;
      }
    }

    .room-prize-buttons {
      align-items: center;
      flex-direction: column;

      .free-breakfast {
        button {
          min-width: 216px;
          height: 36px;
          margin-right: 0px;
          margin-top: 0px;
        }
      }

    }

  }

  .room-desc {
    .hotel-offers {
      flex-wrap: wrap;

      .place-offer {
        width: 100%;
        margin-right: 0px;

      }
    }


  }

  //  ------- confirm-booking-------
  .confirm-booking {
    .trip-section {
      .right-section {
        max-width: none;
        width: 100%;
      }

    }

  }



}

@media only screen and (max-width:520px) {

  .main_header .sidenav-logo-wrapper .logo {
    justify-content: left !important;

    #sidenav-logo {
      height: 40px;
      width: 75%;
    }
  }

  .fliter-by-form {
    flex-wrap: wrap;
  }


  .mat-list.list-style-1 .info .date {
    font-size: 12px;
  }

  .chat-box-area .chat-header .info {
    strong {
      font-size: 12px !important;
    }
  }

  .manage-restaurant-guest-wrapper .restaurant-guest-history-wrapper .guest-order-history-wrapper .mat-tab-body-wrapper .previous-tab-header-wrapper .restaurant-calender {
    width: 54%;
  }

  .complete-profile-form-wrapper .country-code-wrapper {
    .country-code-dropdown {
      padding: 10px;
      width: 48%;
      margin-right: 5px;
    }
  }

  .edit-profile-btn-wrapper .edit-btns {
    padding: 10px;
  }

  .complete-profile-form-wrapper .visibility-icon-wrapper .visibility-icon {
    top: 7px;
    right: 10px;
  }

  .complete-profile-form-wrapper .edit-profile-inputs {
    padding: 10px;
  }

  .thank-you-page-cart {
    padding: 10px;
  }

  .guest-cart-payment {
    padding: 10px;

    .order-id-cart {
      font-size: 14px;
    }

  }

  .ch-guide-list.block-container .ch-guide-action-ic {
    width: 40px;

    i {
      font-size: 20px;
    }
  }

  .ch-guide-list.block-container {
    min-height: inherit;
  }

  .ch-guide-list.block-container .guideContent {
    h3 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }

  .ch-guide-list.block-container:hover .ch-guide-app-ic {
    width: 40px;

    i {
      font-size: 20px;
    }
  }

  .modal-header {
    .lang-drop-menu {
      width: 100%;
      margin: 0 !important;
    }
  }

  .ch-guide-list.block-container {
    padding: 10px;
  }

  body .ch-guide-listing .ch-guide-list {
    padding-right: 40px;
  }

  body .ch-guide-listing .ch-guide-list .ch-guide-action-ic {
    width: 35px;
    height: 48px;
  }

  body .ch-guide-listing .ch-guide-list:hover .ch-guide-action-ic {
    right: 8px;
  }

  body .ch-guide-listing .ch-guide-list h3 {
    font-size: 16px;
  }

  body .ch-guide-listing .ch-guide-list p {
    font-size: 14px;
  }

  body .page-header-section .button-wrapper {
    width: 100%;
  }

  body .form-footer .button-wrapper .btn {
    margin: 0px 0px 10px 0px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  body .page-header-section .button-wrapper .btn {
    margin: 0px 0px 10px 0px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 0px !important;
  }

  body .admin-gallery-cth #hotel-gallery .content-wrapper .main-content-wrapper .gallery-item-list {
    max-width: 100%;
    flex: 0 0 100%;
  }

  body .image-large-view slideshow .slideshow-container .arrow-container i {
    height: 20px !important;
    width: 20px !important;
  }

  body .slideshow-container.slideshow-container-fs[_ngcontent-c20] {
    max-height: 300px;
    left: 20px !important;
    top: 20px !important;
    right: 20px !important;
  }

  body .admin-header .logo img {
    width: 120px;
  }

  body .notifications.mobile-bar {
    margin: 0px 5px !important;
  }

  body .profile-lang-section a {
    margin-left: 0px !important;
  }

  body .page-header-section .page-title {
    font-size: 20px;
  }

  body .pref-header .btn {
    width: 100%;
    display: flex;
    margin: 0px;
    align-items: center;
    justify-content: center;
  }

  body .pref-header .btn i {
    position: relative;
    top: 0px;
  }

  body .mat-checkbox-layout .mat-checkbox-label {
    font-size: 14px;
  }

  body #hero-banner h1 {
    font-size: 40px !important;
  }

  body #hero-banner h4 {
    font-size: 30px !important;
  }

  body #about-info h2 {
    font-size: 30px;
  }

  body .contact-info h2 {
    font-size: 30px !important;
  }

  body .main_header .logo {
    padding: 4px 0;
  }

  body .main_header .custom_nav nav .menu-item-text-color {
    color: #000 !important;
  }

  .block-container.half-left,
  .block-container.half-right {
    width: 100%;

    .toggle-label {
      width: 76%;
      float: left;
    }
  }

  .inner-list-wrapper .amn-repat-list {
    width: 100%;
  }

  // header {
  //   padding: 0 10px !important;
  // }

  .textarea-field-cs textarea {
    height: 90px;
  }

  #mat-dialog-3,
  #mat-dialog-2 {
    .modal-body-content {
      padding: 20px 20px 0 !important;
    }
  }

  .block-container .block-title.infront-title span {
    margin-left: 0;
    line-height: 18px;
    font-size: 13px;
    margin-top: 6px;
  }

  .room-prize-buttons {
    margin-top: 0 !important;

  }

  // Login-popup
  .Login-popup {
    .left-contents {
      .poppup-logo {
        .logo {
          margin-bottom: 10px;
        }
      }
    }
  }

  .about-room {
    span {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .free-breakfast {
    .mat-button {
      min-width: 184px !important;

      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .book-now {
    button {
      min-width: 184px !important;
    }
  }

}

@media only screen and (max-width:426px) {



  .order-tracking-wrapper .order-tracking-progressbar .mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header {
    justify-content: center !important;
  }

  .tiles-section-mobile .manage-order-tabs-wrapper .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
    padding: 10px 0 !important;
  }

  .tiles-section-mobile .manage-order-tabs-wrapper .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label .mat-tab-label-content {
    font-size: 10px !important;
  }

  .tiles-section-mobile .manage-order-tabs-wrapper .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label:nth-child(1)::before {
    top: 2px;
    right: 4px;
    height: 9px;
    width: 9px;
    padding: 4px;
  }

  .booking-info-wrapper .booking-info mat-accordion mat-expansion-panel .mat-expansion-panel-body .booking-mobile-design {
    padding: 0;
  }

  .visit-again {
    padding: 10px;

    p {
      font-size: 13px;
    }
  }

  .activation-content {
    padding: 20px;
    margin-top: 10px !important;

    .email-icon {
      margin-bottom: 10px !important;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    h1 {
      font-size: 25px !important;
      margin-bottom: 10px !important;
    }

    .activation-text {
      font-size: 14px !important;
    }

    .activation-resend {
      font-size: 15px !important;
    }

    a {
      font-size: 17px !important;
    }
  }

  .Login-popup .left-contents .top-div mat-icon {
    top: -12px;
    right: -9px;
    width: fit-content;
    height: fit-content;
    background: transparent;
    font-size: 24px;
  }

  .Login-popup .left-contents {
    padding: 15px;
  }

  .user-upper-header {
    padding: 15px;
  }

  .user-sidenav {
    width: 260px;
  }

  .user-sidenav .sidenav-logo-wrapper {
    padding: 20px 23px 20px 10px;
  }

  .user-sidenav .custom-nav-wrapper .custom_nav ul li {
    padding: 15px 10px 15px 15px;
  }

  .search-btn {
    padding: 0 20px;

    button {
      margin-left: 0px !important;
    }
  }

  .content-section-outer {
    .content-wrapper-mobile {
      padding: 0px !important;

      .main-content-wrapper {
        .mobile-checkin-checkout {
          width: 100% !important;
          margin: 15px 0 0 0;
        }
      }
    }
  }




  .available-rooms {
    display: block !important;
    margin-bottom: 20px;

    .row {
      width: 100%;
      margin: 0;

      .col-lg-8 {
        padding: 0;

        .row {
          width: 100%;

          .col-lg-7 {
            padding: 0;

            .room-desc {
              padding: 0;


              .room-bio {
                justify-content: space-between;
                padding: 15px 20px 0 20px;

                .about-room {
                  text-align: center;

                  .review {
                    flex-wrap: wrap;
                    margin-top: 10px;
                    justify-content: center;

                    .star {
                      padding: 0;
                    }
                  }

                  .executive-button {
                    button {
                      max-width: fit-content;
                    }
                  }
                }
              }

              .mobile-place-offers {
                padding: 0 20px !important;
                font-size: 16px;

              }

              .hotel-offers {
                padding: 0 20px;

                .place-offer {
                  margin-top: 0px;

                  ul {
                    li {
                      margin-top: 10px !important;
                    }
                  }
                }
              }
            }
          }

          .col-lg-5 {
            padding: 0;

            .room-prize {
              padding: 0 20px;

              .cancellation-button {
                margin-top: 15px;
              }

              .end-section {
                margin: 15px 0;

                .rooms-prize-deatil {
                  .amount {
                    font-size: 25px !important;
                  }
                }

                .room-prize-buttons {
                  .free-breakfast {
                    button {
                      padding: 0 5px;
                      display: block !important;
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }



}

@media only screen and (max-width:410px) {
  .restro-toggle-text {
    display: none !important;
  }
}

@media only screen and (max-width:400px) {

  .send-action {
    padding-right: 0;
    padding-left: 10px;
  }

  .uploadAttachment {
    margin-right: 10px;
  }

  .chat-box-area .chat-footer .row .message-input {
    padding: 10px;
  }

  .message-user-list .mat-list-item .icon {
    margin: 0 10px 0 0;
    width: 25px;
    min-width: 25px;
    height: 25px;
  }

  .status-orange,
  .status-text-primary,
  .status-text-green,
  .status-text-red {
    padding: 0 15px !important;
  }

  .send-icon {
    padding: 0;
  }

  .admin-message-container {
    padding: 0;

    .all-messages {
      width: 100%;
      max-width: 100%;

      .messaging-area {
        .message-user-list {
          .mat-tab-group {
            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  .list-inner-section {
                    .mat-list {
                      .mat-list-item {
                        padding: 10px;

                        .mat-list-item-content {
                          justify-content: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  .all-messages .mat-tab-labels {
    .mat-tab-label {
      padding: 0 5px;

      .mat-tab-label-content {
        font-size: 12px;
      }
    }
  }


  .order-tracking-wrapper .order-tracking-progressbar .tracking-order-details-table-wrapper .order-tracking-restaurantName {
    font-size: 18px;
  }

  .order-tracking-wrapper .order-tracking-progressbar .mat-stepper-horizontal .mat-stepper-horizontal-line {
    min-width: 20px !important;
  }

  .order-details-wrapper {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .guest-checkout-info {
    margin-bottom: 0px !important;
    padding: 0px !important;
  }

  .mobile-list-guestinfo {
    margin-top: 10px !important;
  }

  .bg-outer {
    padding: 10px 5px;
  }

  .billing-amount {
    padding: 10px;

    button {
      margin: 0;
    }
  }

  .booking-mobile-design mat-list-item.mat-list-item.mat-focus-indicator {
    height: fit-content;
  }

  .checkin-guest-wrapper {
    .checkin-page-content {
      padding: 10px 15px;
    }
  }

  .checkin-page-content {
    padding: 20px 47px;
  }

  .checkin-restaurant-Logo {
    margin-bottom: 15px !important;
  }

  .checkin-guest-wrapper .checkin-page-content .checkin-welcoming {
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 13px;
    }
  }

  .guest-checkin-id {
    label {
      font-size: 13px !important;
    }
  }

  .checkin-guest-wrapper .checkin-page-content .guest-checkin-id input {
    padding: 10px;
  }


  .checkin-guest-wrapper .clip-art {
    margin-bottom: 15px;
  }

  .checkin-guest-wrapper .checkin-page-content .checkin-welcoming {
    margin-bottom: 15px !important;
  }

  .guest-details {
    padding: 0;
  }

  .checkin-guest-wrapper .clip-art svg {
    max-width: 300px;

  }

  .message-input {
    .emoji-mart {
      z-index: 99;
      position: absolute;
      right: -22px;
      width: 300px !important;
    }
  }
}


@media only screen and (max-width:375px) {

  .padding20 {
    padding: 10px !important;
  }

  .manage-restro-heading-text {
    font-size: 17px !important;
  }

  .booking-info-wrapper {
    padding: 10px 5px;
  }

  .booking-mobile-design mat-card-content mat-list mat-list-item .mat-list-item-content {
    padding: 0 !important;

    .details-button {
      max-height: fit-content;
    }
  }

  .booking-info-wrapper .booking-info mat-accordion mat-expansion-panel .mat-expansion-panel-body {
    padding: 0 5px 17px;
  }


  .booking-method-wrapper .booking-pay-methods {
    padding: 0;
  }

  .internal-booking-table-wrapper {
    padding: 0 !important;
  }

  .cart-back-btn {
    padding: 10px;
    font-size: 16px;
  }

  .final-payment-button {
    width: 90%;
    margin: auto;
    display: block;
  }
}

// <------Customize_is Css------->
@media only screen and (max-width:1050px) {

  // <-------Dashboard Responsive-------->
  .calender-with-title {
    @include flex-wrap(wrap);
  }

  .search-filter-title {
    @include flex-wrap(wrap);
  }

  .search-filter-title .search-right {
    @include flex-wrap(wrap);
  }

  .add-button-wrapper {
    text-align: center;
  }

  .add-button-wrapper .btn {
    margin: 19px 0 15px 0 !important;
  }

  .search-filter-title .search-right .search-input {
    margin-bottom: 15px;
  }

  .search-filter-title .search-right .filter-btn {
    margin-left: 0;
  }

  // <-------\\Dashboard Responsive-------->

  // <-------Messaging Responsive-------->

  .right-info.messaging-area {
    flex-wrap: wrap-reverse;
  }

  .chat-box-area .chat-footer .row {
    flex-wrap: nowrap;
    justify-content: center;
  }


  .chat-box-area .chat-footer .row .message-input textarea {
    width: auto !important;
  }

  .mat-list.list-style-1.style-3 .icon {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }

  .mat-list.list-style-1 .info p {
    font-size: 0.7rem;
  }

  .mat-list.list-style-1 .info h4 {
    font-size: 0.8rem !important;
    padding-right: 20px;
    word-break: break-word;
  }

  .mat-list.list-style-1 .right-info-status .date {
    font-size: 0.7rem;
  }

  .status-text-green {
    // height: 15px !important;
    // margin: 3px 0 0 !important;
    // font-size: 0.7rem !important;
  }

  .mat-list.list-style-1 .right-info-status .status {
    // height: 15px !important;
    // margin: 3px 0 0 !important;
    // font-size: 0.7rem !important;
  }

  // .status-orange {
  //   height: 15px !important;
  //   margin: 3px 0 0 !important;
  //   font-size: 0.7rem !important;
  // }
  .status-text-primary {
    // height: 15px !important;
    // margin: 3px 0 0 !important;
    // font-size: 0.7rem !important;
  }

  // -------\\Messaging Responsive-------->

  // <-------Manage Booking Details------>
  .serach-top {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .serach-top .mat-form-field {
    margin-bottom: 20px;
    width: 100%;
  }

  .serach-top .btn:not(:disabled):not(.disabled) {
    margin-bottom: 11px;
  }

  .fliter-heading {
    margin-bottom: 15px;
  }

  .fliter-outer .mat-form-field-appearance-legacy .mat-form-field-infix {
    margin: 8px 0;
  }



  .pop-top-right {
    justify-content: flex-start;
  }

  .custom-is-ch_f .mat-form-field-infix {
    width: 100px;
  }

  // <-------Manage Booking Details------>


  @media screen and (max-width: 767px) {

    .guest-checkin-table-wrapper {
      display: none;
    }

    .checkin-request-mobile {
      display: block;
    }

    .profile-detail .left-side {
      min-height: 100%;
    }

    .right-info.messaging-area .message-user-list .custom-is-ch_e {
      height: 200px !important;
    }

    .mat-list.list-style-1 .message_is .info h4 {
      font-size: 0.8rem !important;
    }
  }

}


// new header responsive

@media only screen and (max-width: 1200px) {

  .order-details-guest {
    border-right: unset !important;
  }

  .menu_wrapper {

    // display: block !important;
    // position: absolute;
    // top: 1px;
    // left: 16px;
    // right: 16px;
    // background: #fff;
    // border-top: 2px solid #000;
    // padding: 10px;
    ul {
      li {
        width: 100% !important;

        a {
          line-height: 44px !important;
          padding: 0 20px !important;
        }

        &.lang-drop {
          width: 50% !important;
        }
      }
    }

    a.btn {
      padding: 12px 60px;
    }
  }

  .custom_nav {
    flex: inherit !important;
    max-width: inherit !important;
  }

  .responsive_menu {
    display: block;
    position: absolute;
    right: 12px;
    top: -47px;

    i {
      font-size: 33px;
      cursor: pointer;
      color: #000;
    }
  }

  .main_header {
    .logo {
      padding: 20px 0;
    }
  }

  .menu_wrapper.active-open {
    display: block !important;
    height: 100% !important;
  }

  body {
    padding: 0;
  }

  #hero-banner {
    h1 {
      font-size: 50px !important;
    }

    h4 {
      font-size: 34px !important;
    }
  }

  .section-header {
    h2 {
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 0;
    }

    .btn {
      padding: 9px 25px;
      font-size: 14px;
    }
  }
}

// media query for new sidebar navigation

@media only screen and (max-width: 1380px) {

  .order-details-guest-link {
    gap: 20px;

    .order-details-guest {
      padding: 0 30px !important;
      border-right: none !important;
    }
  }


  .main-outer {
    width: calc(100% - 300px);
    margin-left: 300px;
    padding: 0 20px;
  }

  .new-nav {
    width: 300px;

    .header-contents {
      padding: 40px 0 !important;

    }


  }
}